import React from 'react'

const Promotion =()=>{
      return (
        <div style={{color:"white"}}>
        <h1> עמוד זה הינו עמוד תשלומים למגוון שרותים הניתנים במוטו וניתנים לביצוע בהזמנה ישירה</h1>
       בעמוד זה באפשרותך: <br/>
 לרכוש חבילת קידום ממומן <br/>
לרכוש חבילת שעות בגדלים שונים לפי הצורך, מומלץ להתיעץ עמנו לפני רכישת חבילת שעות<br/>
לרכוש באנרים מותאמים לצורך קידום ממומן<br/>
להעביר תשלום כללי עבור ביצוע עבודה, ולאחר שסוכם כן עמנו<br/>
<br/>
אנו שמחים לשרת אותך,<br/>
ההצלחה שלך היא הסיפוק שלנו<br/>
<br/><br/><br/>
        </div>
      );
    }
export default Promotion