import React from 'react'

const Text=()=>{
      return (
        <div style={{color:"white"}} dir="RTL">
       <h1> פיתוח אפליקציות ואתרים, קידום אורגני וממומן, מחירים: </h1>
תמחור שרותים: <br /><br />
<b >מחירי קידום אורגני:  </b> <br />
<ul>
<li> חבילה בסיסית - תשלום חודשי: 700 ₪</li>
<li>חבילה מתקדמת- תשלום חודשי: 1800 ₪</li>
<li>חבילה כוללת – תשלום חודשי: 2400 ₪</li>
<li>בנית תגים ואופטימיזציה (תשלום חד פעמי): 2200 ₪ </li>
</ul>
<br />
< a href='/Promotion-direct-order' style={{color:"#A8DDFE"}}>   לפרוט החבילות, לחץ על קישור זה </a> <br />
<a href='/seo-payment'  style={{color:"#A8DDFE"}}>   למעבר לרכישה ישרה, לחץ על קישור זה </a><br />
<br /><br />

<b >מחירי קידום ממומן:</b><br />
<ul>
<li> תוכנית בתשלום קבוע – 2200 ₪ (המחיר אינו כולל את התשלום לגוגל) < a href='/Promoting-funded-direct-order?plan=fixed' style={{color:"#A8DDFE"}}> - לרכישה לחץ כאן</a></li>
<li> תוכנית בתשלום יחסי – 790 ₪ (המחיר כולל את התשלום לגוגל) < a href='/Promoting-funded-direct-order?plan=relative' style={{color:"#A8DDFE"}}>- לרכישה לחץ כאן</a></li>
<li> תוכנית לפי שעות, המלצת מינימום - 10 שעות להקמה ואופטימיזציה < a href='/Promoting-funded-direct-order?plan=hours' style={{color:"#A8DDFE"}}>- לרכישה לחץ כאן</a></li>
</ul>
<br />
< a href='/מחירי קידום ממומן' style={{color:"#A8DDFE"}}>   לפרוט החבילות, לחץ על קישור זה </a> <br />


<br />
<b >מחירי פיתוח אתר רספונסיבי: </b><br />
<ul>
<li>חבילה בסיסית- 690 ₪ .</li>
<li>חבילה מתקדמת – מחיר 2200 ₪ .</li>
<li>חבילת הכל כלול – 6500 ₪ .</li>
<li>תכנית לפי שעות, תשלום משתנה ע"פ תמחור ותקנון חבילת השעות, מינימום 16 שעות.</li>
</ul>
<br />
< a href='/מחירי פיתוח' style={{color:"#A8DDFE"}}>   לפרוט החבילות, לחץ על קישור זה </a> <br />
< a href='/development-payment' style={{color:"#A8DDFE"}}> למעבר לרכישה ישרה, לחץ על קישור זה  </a> <br />
< a href='/Hours-package-payment' style={{color:"#A8DDFE"}}> למעבר לרכישת חבילה לפי שעות, לחץ על קישור זה  </a> <br />

<br />
<br />
פיתוח אפליקציות ופיתוח אתרים דינמיים, משתנה מאוד בהתאם לדרישות הלקוח ולכן לא תמחרנו מראש, התשלום יהיה לפי מחיר חבילת שעות או לפי תשלום גלובלי עבור כל העבודה, בהתאם למוסכם עם הלקוח<br />
<br />

          
           </div>
      );
    }
export default Text