import React from 'react'
/* ------------------------------------ */
// Navbar data section
/* ------------------------------------ */
import logo from '../../../../common/src/assets/image/appModern/logo.png';
/* ------------------------------------ */
// client data section
/* ------------------------------------ */
import client1 from '../../../../common/src/assets/image/appModern/company1.png';
import client2 from '../../../../common/src/assets/image/appModern/company2.png';
import client3 from '../../../../common/src/assets/image/appModern/company3.png';
import client4 from '../../../../common/src/assets/image/appModern/company4.png';
import client5 from '../../../../common/src/assets/image/appModern/company5.png';
/* ------------------------------------ */
// Features data section
/* ------------------------------------ */
import featureIcon1 from '../../../../common/src/assets/image/appModern/icon1.svg';
import featureIcon2 from '../../../../common/src/assets/image/appModern/icon2.svg';
import featureIcon3 from '../../../../common/src/assets/image/appModern/icon3.svg';
import featureIcon4 from '../../../../common/src/assets/image/appModern/icon4.svg';
/* ------------------------------------ */
// App slider data section
/* ------------------------------------ */
import appSlide1 from '../../../../common/src/assets/image/appModern/appSlider1.png';
import appSlide2 from '../../../../common/src/assets/image/appModern/appSlider2.png';
import appSlide3 from '../../../../common/src/assets/image/appModern/appSlider3.png';
import appIcon from '../../../../common/src/assets/image/appModern/icon1.svg';
/* ------------------------------------ */
// Design and built data section
/* ------------------------------------ */
import codingImage from '../../../../common/src/assets/image/appModern/code.png';
/* ------------------------------------ */
// Product  Slide  section
/* ------------------------------------ */
import slide1 from '../../../../common/src/assets/image/appModern/page1.png';
import slide2 from '../../../../common/src/assets/image/appModern/page2.png';
import slide3 from '../../../../common/src/assets/image/appModern/page3.png';
import slide4 from '../../../../common/src/assets/image/appModern/page4.png';
import slide5 from '../../../../common/src/assets/image/appModern/page5.png';
import slide6 from '../../../../common/src/assets/image/appModern/page6.png';
/* ------------------------------------ */
// Team Portfolio data section
/* ------------------------------------ */
import member1 from '../../../../common/src/assets/image/appModern/1.png';
import member2 from '../../../../common/src/assets/image/appModern/2.png';
import member3 from '../../../../common/src/assets/image/appModern/3.png';
import member4 from '../../../../common/src/assets/image/appModern/4.png';
import member5 from '../../../../common/src/assets/image/appModern/5.png';
import member6 from '../../../../common/src/assets/image/appModern/6.png';
import member7 from '../../../../common/src/assets/image/appModern/7.png';

/* ------------------------------------ */
// Footer data section
/* ------------------------------------ */
import chat from '../../../../common/src/assets/image/appModern/chat.svg';
import group from '../../../../common/src/assets/image/appModern/group.svg';
import heart from '../../../../common/src/assets/image/appModern/heart.svg';
import footerLogo from '../../../../common/src/assets/image/appModern/logoWhite.png';




export const navbar = {
  logo: logo,
  navMenu: [
    {
      id: 1,
      label: 'בית',
      path: '#home',
      offset: '84',
    },
    {
      id: 2,
      label: 'שירותים',
      path: '#features',
      offset: '81',
    },
    {
      id: 3,
      label: 'התמונות מדברות',
      path: '#ProductSlide',
      offset: '81',
    },
    {
      id: 4,
      label: 'על הליך הפיתוח',
      path: '#DevelopmentProcess',
      offset: '81',
    },
    // {
    //   id: 3,
    //   label: 'הוקרה',
    //   path: '#testimonial',
    //   offset: '81',
    // },
    // {
    //   id: 4,
    //   label: 'מחירים',
    //   path: '#pricing',
    //   offset: '81',
    // },
    {
      id: 5,
      label: 'קורסים והדרכות',
      path: 'OfferCourses',
      offset: '81',
    },
    {
      id: 6,
      label: 'קידום אתרים',
      path: 'Promotion',
      offset: '81',
    },
    {
      id: 3,
      label: 'צור קשר',
      path: 'ContactUs',
      offset: '81',
    }
  ],
};

/* ------------------------------------ */
// client data section
/* ------------------------------------ */

export const client = [
  {
    id: 1,
    image: client1,
    title: 'מורשת הגליל',
    link:"http://www.moreshetgalil.co.il/BRPortal/br/P100.jsp",
  },
  {
    id: 2,
    image: client2,
    title: 'דוגולי',
    link:"https://dugoly.com/",
  },
  {
    id: 3,
    image: client3,
    title: 'בלוגי קליק',
    link:"https://blogy.click/",
  },
  {
    id: 4,
    image: client4,
    title: 'direct done',
    link:"https://directdone.com/",
  },
  {
    id: 5,
    image: client5,
    title: 'get-credit',
    link:"https://getcredit.link/",
  },
];

/* ------------------------------------ */
// Features data section
/* ------------------------------------ */

export const features = {
  slogan: 'תכונות עיקריות',
  title: 'למה דווקא אנחנו?',
  items: [
    {
      id: 1,
      color: '#F55767',
      icon: featureIcon1,
      title: 'פיתוח תוכנה',
      description:
        'פיתוח תוכנה מקצועי בטכנולוגיה מתקדמת: react, javascript, java,  c#, node-js, .net, firebase ועוד .',
    },
    {
      id: 2,
      color: '#ff4742',
      icon: featureIcon2,
      title: 'ניסיון בפיתוח',
      description:
        'ניסיון רחב בפיתוח אפליקציות מורכבות לניידים ולווב (web) .',
    },
    {
      id: 3,
      color: '#fb5781',
      icon: featureIcon3,
      title: 'מחירים הוגנים',
      description:
        'תמחור הוגן בהתאם למימדי האפליקציה ולכלים הנדרשים.',
    },
    {
      id: 4,
      color: '#f18e47',
      icon: featureIcon4,
      title: 'שרות לקוחות',
      description:
        'אנחנו משתדלים לתת שרות מקצועי ואדיב, שביעות הרצון שלך היא ההצלחה שלנו. ',
    },
  ],
};

/* ------------------------------------ */
// App slider data section
/* ------------------------------------ */

export const appSlider = {
  carousel: [
    {
      id: 1,
      image: appSlide1,
      title: 'App Slide 1',
    },
    {
      id: 2,
      image: appSlide2,
      title: 'App Slide 1',
    },
    {
      id: 3,
      image: appSlide3,
      title: 'App Slide 1',
    },
  ],
  title: 'Smart Jackpots that you may love this anytime & anywhere',
  description:
    "The rise of mobile devices transforms the way we consume information entirely and the world's most elevant channels such as Facebook.",
  features: [
    {
      id: 1,
      icon: appIcon,
      title: 'Easy Invoicing',
      description: 'Surprice your clients with professional looking invoices.',
    },
    {
      id: 2,
      icon: appIcon,
      title: 'UX Planning',
      description:
        'UI/UX Design by following and maintaining the latest trends .',
    },
    {
      id: 3,
      icon: appIcon,
      title: 'Customer Support',
      description: 'A Dedicated support team will be always ready for you.',
    },
  ],
};

/* ------------------------------------ */
// Design and built data section
/* ------------------------------------ */

export const designAndBuilt = {
  image: codingImage,
  slogan: 'הקמת אתר',
  title: 'הקמת אתר מידע אינטרנטי',
  description:
    'לצורך הקמת אתר מידע בסיסי אין צורך במתכנת- ישנם ממשקי ניהול מצויינים המכילים תבניות יפות של אתרים וניתן לבנות בעזרתם אתר יפה, בשעות בודדות בלבד,..אנחנו נותנים תמיכה מלאה בממשק של אתר מערכות-  שהינו בבעלותינו..http://www.companyslogan.net/ '.split('..').map((item, i) => {return <p key={i}>{item}</p>}),
};

/* ------------------------------------ */
// Product  Slide  section
/* ------------------------------------ */

export const productData = {
  slogan: 'תצוגת מוצרים',
  title: 'התמונות מדברות בעד עצמן...',
  carousel: [
    {
      id: 1,
      thumb_url: slide1,
      link: '#1',
      title: 'slide 1',
    },
    {
      id: 2,
      thumb_url: slide2,
      link: '#1',
      title: 'slide 2',
    },
    {
      id: 3,
      thumb_url: slide3,
      link: '#1',
      title: 'slide 3',
    },

    {
      id: 4,
      thumb_url: slide4,
      link: '#1',
      title: 'slide 4',
    },

    {
      id: 5,
      thumb_url: slide5,
      link: '#1',
      title: 'slide 5',
    },
    {
      id: 6,
      thumb_url: slide6,
      link: '#1',
      title: 'slide 6',
    },
  ],
};

/* ------------------------------------ */
// Pricing policy data section
/* ------------------------------------ */
export const pricing = {
  slogan: 'PRICING PLAN',
  title: 'Choose your pricing policy',
  monthly: [
    {
      id: 1,
      title: 'Business Class',
      description: 'For Small teams or office',
      suggested: false,
      price: 0,
      features: [
        {
          id: 1,
          text: 'Drag & Drop Builder',
        },
        {
          id: 2,
          text: "1,000's of Templates",
        },
        {
          id: 3,
          text: 'Blog Support Tools',
        },
        {
          id: 4,
          text: 'eCommerce Store ',
        },
      ],
    },
    {
      id: 2,
      title: 'Pro Master',
      description: 'For Best opportunities',
      suggested: true,
      price: 99,
      trail: 14,
      trailLink: '#',
      features: [
        {
          id: 1,
          text: 'Drag & Drop Builder',
        },
        {
          id: 2,
          text: "1,000's of Templates",
        },
        {
          id: 3,
          text: 'Blog Support Tools',
        },
        {
          id: 4,
          text: 'eCommerce Store ',
        },
      ],
    },
  ],
  annualy: [
    {
      id: 1,
      title: 'Pro Master',
      description: 'For Small teams or office',
      suggested: true,
      price: 999,
      trail: 14,
      trailLink: '#',
      features: [
        {
          id: 1,
          text: 'Drag & Drop Builder',
        },
        {
          id: 2,
          text: "1,000's of Templates",
        },
        {
          id: 3,
          text: 'Blog Support Tools',
        },
        {
          id: 4,
          text: 'eCommerce Store ',
        },
      ],
    },
    {
      id: 2,
      title: 'Enterprise',
      description: 'For Best opportunities',
      suggested: false,
      price: 1299,
      trail: 30,
      trailLink: '#',
      features: [
        {
          id: 1,
          text: 'Drag & Drop Builder',
        },
        {
          id: 2,
          text: "1,000's of Templates",
        },
        {
          id: 3,
          text: 'Blog Support Tools',
        },
        {
          id: 4,
          text: 'eCommerce Store ',
        },
      ],
    },
  ],
};

/* ------------------------------------ */
// Team Portfolio data section
/* ------------------------------------ */

export const teamportfolio = {
  title: 'Meet our awesome team members, work behind the sense',
  description:
    'Lorem ipsum dolor sit amet consectetur adipisicing elit sed eiusmod tempor incididunt labore dolore features that Lorem ipsum dolor sit amet consectetur.',

  teammember: [
    {
      id: 1,
      img: member1,
      text: 'Berlin Corleone',
    },
    {
      id: 2,
      img: member2,
      text: 'Jona White',
    },
    {
      id: 3,
      img: member3,
      text: 'Michael Price',
    },
    {
      id: 4,
      img: member4,
      text: 'Gullyboy Rana',
    },
    {
      id: 5,
      img: member5,
      text: 'Miss Clair',
    },
    {
      id: 6,
      img: member6,
      text: 'Bucky Ali',
    },
    {
      id: 7,
      img: member7,
      text: 'Arthus Doe',
    },
  ],
};

/* ------------------------------------ */
// Testimonial data section
/* ------------------------------------ */
export const testimonial = {
  slogan: 'TESTIMONIAL',
  title: 'Meet Client Satisfaction by using product',
  reviews: [
    {
      id: 1,
      title: 'Modern look & trending design',
      description:
        'Get working experience to work with this amazing team & in future want to work together for bright future projects and also make deposit to freelancer.',
      avatar:
        'https://pbs.twimg.com/profile_images/974736784906248192/gPZwCbdS.jpg',
      name: 'Jon Doe',
      designation: 'CEO of RedQ Inc.',
      review: 4,
    },
    {
      id: 2,
      title: 'User friendly & Customizable',
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit sed eiusmod tempor incididunt labore dolore features Lorem ipsum dolor sit amet consectetur adipisicing.',
      avatar: 'https://randomuser.me/api/portraits/women/44.jpg',
      name: 'Jeny Doe',
      designation: 'Co Founder of RedQ Inc.',
      review: 5,
    },
    {
      id: 3,
      title: 'User friendly & Customizable',
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit sed eiusmod tempor incididunt labore dolore features Lorem ipsum dolor sit amet consectetur adipisicing.',
      avatar:
        'https://tinyfac.es/data/avatars/475605E3-69C5-4D2B-8727-61B7BB8C4699-500w.jpeg',
      name: 'Jon Doe',
      designation: 'Co Founder of RedQ Inc.',
      review: 5,
    },
  ],
};

/* ------------------------------------ */
// Footer data section
/* ------------------------------------ */

export const footer = {
  widgets: [
    {
      id: 1,
      icon: group,
      title: 'הצטרף לקהילה שלנו',
      description:
        'מעוניין להשקיע בסטארט-אפ? מפתח, משקיע או יזם - זה המקום שכולם מתחברים יחד',
    },
    {
      id: 2,
      icon: chat,
      title: 'שמור על קשר',
      description:
        "ניתן לפנות אלינו בכל עת, מחכים לך  24-6 ..בטלפון: 0512-635325 ..  או באימייל:  ..	customerservice@motto.co.il ..xxx@motto.co.il".split('..').map((item, i) => {return <p key={i}>{item}</p>}),
    },
    {
      id: 3,
      icon: heart,
      title: 'נהנת מהשרות והמוצרים?',
      description:
        'נהנת מהשרות והמוצרים - שתף אותנו! \n באימייל: 	customerservice@motto.co.il',
    },
  ],
  logo: footerLogo,
  menu: [
    {
      id: 1,
      text: 'הסכם סודיות',
      link: '/ConfidentialityAgreement',
    },
    {
      id: 2,
      text: 'גיוס משקיעים',
      link: '/InvestorRecruitment',
    },
    {
      id: 3,
      text: 'הצטרף אלינו לפייסבוק',
      link: 'https://www.facebook.com/motto.co.il',
    },
    {
      id: 4,
      text: 'שותף עסקי - קבוצה עסקית לגיוס שותפים',
      link: 'https://www.facebook.com/groups/2854414384826651',
    },
    {
      id: 5,
      text: 'קבוצה ציבורית לקידום אתרים',
      link: 'https://www.facebook.com/groups/186467586559070',
    },
    {
      id: 6,
      text: 'שוקאתר',
      link: '/shukatar',
    },
    {
      id: 7,
      text: 'תיקאתר',
      link: '/tikatar',
    },
    {
      id: 8,
      text: 'SEO Dictionary',
      link: '/Dictionaries/SEO/Keys',
    },
    {
      id: 8,
      text: 'מילון מושגים בתכנות',
      link: '/Dictionaries/programming/Keys',
    },
    {
      id: 8,
      text: 'נספחים',
      link: '/Appendices/Keys',
    },
    {
      id: 8,
      text: 'ממיר טקסט ל html',
      link: '/text-to-html',
    },
    {
      id: 8,
      text: 'תשלומים ישירים',
      link: '/Direct_payments',
    },
    {
      id: 8,
      text: 'ניהול מיקור חוץ',
      link: '/Outsourcing-Manager',
    },
    {
      id: 8,
      text: 'מעגלי חשיבה ופיתוח',
      link: '/סיעור מוחות',
    },
    {
      id: 8,
      text: 'בנית תוספים לכרום',
      link: '/תוסף לגוגל כרום',
    },
    {
      id: 8,
      text: 'שאלות בפיתוח',
      link: '/Dictionaries/q&a/programming/Keys',
    },
    {
      id: 8,
      text: 'מחירים',
      link: '/מחירים',
    },
  ],
};
