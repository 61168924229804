import React ,{useState}from 'react';
import Text from '../../../../common/src/components/Text';
import Image from '../../../../common/src/components/Image';
import Container from '../../../../common/src/components/UI/Container';
import BannerWrapper, {
  BannerContent,
  BannerImage,
  CustomerWrapper,
  ImageWrapper,
} from './banner.style';
import { client } from '../../../../common/src/data/AppModern';
import circleBorder from '../../../../common/src/assets/image/appModern/shape.svg';
import TextM from './text'
import Form from './form'

const Banner = () => {

 return (
    <BannerWrapper id="home" dir="rtl" >
      <Container >
        <BannerContent >

        <TextM />

         </BannerContent>
        <BannerImage>
          
          <Form />

        </BannerImage>
      </Container>


      <CustomerWrapper>
        <Text content="מהגלריה שלנו:" />
        <ImageWrapper>
         {client.map(item => (
            <a href={item.link} target="blank">
            <Image
              key={`client-key${item.id}`}
              src={item.image}
              alt={item.title}
            /></a>
          ))}
           </ImageWrapper>
      </CustomerWrapper>
      
      
      <img
        className="bannerBottomShape"
        src={circleBorder}
        alt="Bottom Circle"
      />
    </BannerWrapper>
  );
};

export default Banner;
