import React from 'react'
import Button from '../../../../../common/src/components/Button';


const Text=()=>{
      return (
        <div style={{color:"white"}} dir="RTL">
       
<h1>בנית אתר מידע רספונסיבי, מחירים</h1><br/>
<b>חבילה בסיסית- 690 ₪</b> <br/>
כולל:<br/>
עד שלושה עמודי תוכן<br/>
תשלומים נוספים: <br />
איחסון- תשלום חודשי 74 ₪ לחודש<br/>
כל עמוד נוסף 230 ₪<br/>
על הלקוח לספק את התכנים (המלל לאתר) ואת התמונות הרלוונטיות<br/>
כל הקודים נכתבים בשפות תכנות מודרניות <br/>
הקוד בבעלות מוטו<br/>
<br/>
<b>חבילה מתקדמת – מחיר 2200 ₪</b><br/>
כולל:<br/>
עד עשרה עמודי תוכן<br/>
איחסון- ללא תשלום חודשי (בהתחייבות לשנתיים)<br/>
על הלקוח לספק את התכנים (המלל לאתר) ואת התמונות הרלוונטיות<br/>
כל הקודים נכתבים בשפות תכנות מודרניות <br/>
הקוד בבעלות הלקוח<br/>
<br/><br/>
<b>חבילת הכל כלול – 6500 ₪ </b><br/>
כולל:<br/>
1.	עד עשרים עמודי תוכן<br/>
2.	איחסון- ללא תשלום חודשי (בהתחייבות לשנתיים)<br/>
3.	דומיין יחודי (בהתחייבות לשנתיים)<br/>
4.	רכישת תמונות בהתאם לצורך (לפי שיקול דעת החברה)<br/>
5.	בניית icon יחודי ללשונית(בהתאם לצורך)<br/>
6.	כל הקודים נכתבים בשפות תכנות מודרניות <br/>
7.	הקוד בבעלות הלקוח<br/>
8.	חבילת קידום אורגני – <br/>
בניית תגים לקידום<br/>
בנית קישורים חיצוניים<br/>
אופטימיזציה לאתר<br/>
9.	אימייל על שם החברה (בהתחייבות לשנתיים)<br/>
10.	טופסי הזמנה ישירות מהאתר, כולל דיווח הזמנה לאימייל (עד 5000 לידים, בהתחייבות לשנתיים)<br/>
11.	כולל גביית אשראי להזמנות ישירות דרך האתר (עד 3 טפסים, בהתאם לתמחור של חברת האשראי ו\או חברת הגביה המקוונת)<br/>
<br/>
<Button title="הזמנת שרותי פיתוח עבור אתר סטטי רספונסיבי, רכישה ישירה"  onClick={()=>{window.location.href="https://motto.co.il//development-payment" }} style={{width:"100%"}}/><br /><br />
<br/>
<b>תכנית לפי שעות</b><br/>
   תשלום משתנה ע"פ תמחור ותקנון חבילת השעות מינימום 16 שעות<br/>
<br/>
<Button title="הזמנת חבילת שעות לפיתוח וקידום, רכישה ישירה"  onClick={()=>{window.location.href="https://motto.co.il/Hours-package-payment" }} style={{width:"100%"}}/><br /><br />

<br/>

בנית אתר דינמי, ופיתוח אפליקציות בתאמה אישית ללקוח




          
           </div>
      );
    }
export default Text