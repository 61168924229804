import React from 'react'

const keysObject={
        chromeStorage_localStorage:{name:"איך אני רואה את ה  chrome.storage או localStorage?",
text:(<div style={{color:"white"}}> 
       <p style={{textAlign: 'right'}}>
       <h1>איך אני רואה את ה  chrome.storage או localStorage?</h1><br />
       זה שתי שאלות שונות <br />
את ה  localStorage אפשר לראות במערך הכלים של המפתחים (developer tools), <br />
לחץ על  f12 ואח"כ אל application  &gt;&gt;&gt; local storage<br />
<br />
את ה chrome.storage עד כמה שידוע לי אי אפשר לראות ב הכלים של המפתחים (developer tools), <br />
אפשר מהקוד להעתיק אותו ל console<br />
chrome.storage.local.get(function(result){'{'}console.log(result){'}'})<br />


<p>&nbsp;<span style={{fontFamily: 'Arial, sans-serif'}}>הבנתי שיש תוסף לכרום שמציג בצורה נוחה את ה </span>chrome.storage&nbsp;</p> <p><a href="https://chrome.google.com/webstore/detail/storage-area-explorer/ocfjjjjhkpapocigimmppepjgfdecjkb" target="_self">https://chrome.google.com/webstore/detail/storage-area-explorer/ocfjjjjhkpapocigimmppepjgfdecjkb</a>&nbsp;</p> <p><span style={{fontFamily: 'Arial, sans-serif'}}>אבל אני עדיין לא בדקתי אותו בעצמי</span>&nbsp;</p> <p><span style={{fontFamily: 'Arial, sans-serif'}}>להרחבה ראה:</span>&nbsp;</p> <p><a href="https://stackoverflow.com/questions/11922964/how-do-i-view-the-storage-of-a-chrome-extension-ive-installed" target="_self">https://stackoverflow.com/questions/11922964/how-do-i-view-the-storage-of-a-chrome-extension-ive-installed</a>&nbsp;&nbsp;</p>
      


       </p>
         </div>)
}
,
MIT_BSD:{name:"מזה רשיון MIT  ורשיון BSD ?",
text:(<div style={{color:"white"}}> 
       <p style={{textAlign: 'right'}}>
      <h1>מזה רשיון MIT  ורשיון BSD ?</h1><br />
      שניהם רשיונות המשמשים לקוד המופץ לשימוש הציבור ושניהם מתירנים כלומר שמאפשרים להשתמש בקוד שלהם בצורה מאוד פתוחה כולל אפשרויות לשימוש לצורך מסחרי, עם הגבלות בסיסיות בלבד<br />
התחייבות צרוף התנאים ובעלי הזכויות לכל שימוש בקוד<br />
הסרת כל טענה וזכות תביעה לנזק הנגרם מן הקוד<br />
לפרטים נוספים:<br />
<a href="https://he.wikipedia.org/wiki/%D7%A8%D7%99%D7%A9%D7%99%D7%95%D7%9F_MIT" target="_self">https://he.wikipedia.org/wiki/%D7%A8%D7%99%D7%A9%D7%99%D7%95%D7%9F_MIT</a>
<a href="https://he.wikipedia.org/wiki/%D7%A8%D7%99%D7%A9%D7%99%D7%95%D7%9F_BSD" target="_self"><span style={{fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}>https://he.wikipedia.org/wiki/%D7%A8%D7%99%D7%A9%D7%99%D7%95%D7%9F_BSD</span></a>


<br />
הייעוץ אינו משפטי, יש לפנות לגורם מקצועי!
       </p>
         </div>)
}

,
bitcoin_code:{name:"היכן ניתן לקבל את הקוד של ביטקוין (bitcoin code)?",
text:(<div style={{color:"white"}}> 
       <p style={{textAlign: 'right'}}>
      <h1>היכן ניתן לקבל את הקוד של ביטקוין (bitcoin code)?</h1><br />
      <br />
      הקוד של הביטקוין הינו קוד פתוח, כלומר חופשי ונגיש לכל, <br />
כל הרעיון של מטבע מבוזר הוא שהנתונים נשמרים באופן חופשי ופתוח במרכז מוקדים ולכן מחוייב שגם הקוד הוא חופשי ונגיש לכל מי שרוצה להריץ אותו<br />
(גם אני באופן אישי הורדתי את הקוד למחשב שלי כולל את שרשרת הבלוקים, מה שהצריך כמה מאות ג'יגה)<br />
הקוד שמור ב github– <br />
<a style={{color:"#A8DDFE"}} href="https://github.com/bitcoin/bitcoin" target="blank">https://github.com/bitcoin/bitcoin  </a> <br /><br />

ניתן להוריד את כל core  (ליבה ) של ה ביטקוין ב- <br />
<a style={{color:"#A8DDFE"}} href="https://bitcoincore.org/en/download/" target="blank">https://bitcoincore.org/en/download/  </a> <br />
לאחר ההתקנה, תוכלו להוריד משם את כל שרשרת הבלוקים, וכן ליצר ארנק דיגיטלי<br /><br />

אתר ביטקוין  בעברית-<br />
<a style={{color:"#A8DDFE"}} href="https://bitcoin.org/he/" target="blank">https://bitcoin.org/he/ </a>  <br /><br />

קישורים נוספים:<br />
זירת מסחר למטבעות דיגיטליים:<br />
<a style={{color:"#A8DDFE"}} href="https://www.blockchain.com/" target="blank">https://www.blockchain.com/  </a> <br />


       </p>
         </div>)
}

}

export default keysObject