import React from 'react'

const Text=()=>{
      return (
        <div style={{color:"white"}}>
       
       <div dir="RTL">
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>הסכם התקשרות לסוכן\איש מכירות במוטו</span></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>בין: ___________________,ת"ז:____________________, להלן הסוכן</span></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>ובין: ___________________,ת"ז:____________________, להלן הבעלים</span></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>&nbsp;</span></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><strong><u><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>הבהרה</span></u></strong><strong><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>: לצורך ביצוע מטרת ההתקשרות יוצג הסוכן לעיתים בתור שותף ו\או בעלים ו\או מנהל פרוייקט ו\או מנהל מכירות ו\או כל דרך יצוג אחרת בעל פה או בכתב באתר החברה או בכל מקום אחר ולכן הוסכם מראש כי:</span></strong></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><strong><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>לא תיהיה לסוכן שום זכות ו\או בעלות חלקית או מלאה בכל הנכסים הדיגיטלים של הבעלים ובכלל זה אתרי האינטרנט ו\או הדומיינים ו\או האפליקציות ו\או הספריות או כל נכס דיגיטלי אחר, שפותח או שיפותח ע"י הבעלים או מי מטעמו, ביחד עם הסוכן או בנפרד</span></strong></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><strong><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>וכן לא תיהיה לסוכן שום זכות ו\או בעלות חלקית או מלאה בכל הנכסים הדיגיטלים של הבעלים ובכלל זה אתרי האינטרנט ו\או הדומיינים ו\או האפליקציות ו\או הספריות או כל נכס דיגיטלי אחר, הרשום על שם הבעלים ו\או נקנה על ידו או על ידי מי מטעמו</span></strong></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><strong><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>בכלל זה:</span></strong></p>
          <ol style={{listStyleType: 'decimal'}}>
            <li><strong><span style={{lineHeight: '115%', fontFamily: '"Arial","sans-serif"', fontSize: '10.0pt'}}>כל נכס דיגיטלי המיוחס לבעלים ו\או למוטו ו\או לדוגולי ו\או לכל שם ו\או יצוג ו\או מותג אחר המיוחס עם הבעלים</span></strong></li>
            <li><strong><span style={{lineHeight: '115%', fontFamily: '"Arial","sans-serif"', fontSize: '10.0pt'}}>כל נכס עיסקי המיוחס לבעלים ו\או למוטו&nbsp;</span></strong></li>
            <li><strong><span style={{lineHeight: '115%', fontFamily: '"Arial","sans-serif"', fontSize: '10.0pt'}}>המוניטין הקיים או שיתפתח במהלך תקופת עבודתו של הסוכן ו\או לפניה ו\או לאחריה</span></strong></li>
            <li><strong><span style={{lineHeight: '115%', fontFamily: '"Arial","sans-serif"', fontSize: '10.0pt'}}>קליינטורה של הבעלים, כולל שמות הלקוחות ופרטיהם, גם אם גויסו ע"י הסוכן</span></strong></li>
          </ol>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><strong><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>ומוסכם בזאת כי כל הנ"ל והמיוחס עימהם הינם רכושו של הבעלים ולא תהיה קבילה כל תענה של שותפות או</span></strong><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>&nbsp;<strong>קבלת זכויות כל שהם &nbsp;ו\או בעלות כל שהיא בנכסים הנ"ל גם במקום שהוסכם לכך במפורש בע"פ או בכתב אא"כ הוסכם כך במפורש במסמך זכויות רשמי ובחתימת שני הצדדים ובמעמד גורם משפטי מוסך ומוסכם</strong></span></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><strong><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>&nbsp;</span></strong></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><strong><u><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>סיבת ההתקשרות</span></u></strong></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>הסוכן יעבוד עבור הבעלים לצורך גיוס לקוחות עבור השרותים הניתנים ע"י הבעלים וינהל את הקשרים עם לקוחות שגוייסו על ידו:<br />&nbsp;יציג לפניהם את השרותים, <br />&nbsp;יעודד אותם לרכוש את השרותים הנדרשים להם <br />&nbsp;יקבל מהם את החומרים הנדרשים עבור ביצוע העסקה (ביצוע התשלום)<br />&nbsp;יקבל מהם את החומרים הנדרשים עבור מימוש השרותים (מידע, תמונות, מלל וכדומה)</span></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>&nbsp;</span></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><strong><u><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>חובות</span></u></strong></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>הסוכן מתחייב:</span></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>לעשות את המאמצים להוביל את הלקוחות לביצוע העסקה באופן הטוב ביותר עבור הבעלים ועבור הלקוחות</span></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>לייצג את הבעלים והעסק בצורה מכובדת, מקצועית ועניינית</span></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>לא להעביר את נתוני הלקוחות לשום גורם אחר שלא בהסכמה מפורשת של הבעלים ובכתב</span></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>לא לעודד ו\או לגרום במישרים או בעקיפין ללקוחות ללכת לשום מתחרה או ספק שרותים אחר&nbsp;</span></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>&nbsp;</span></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><strong><u><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>שכר</span></u></strong></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>הסוכן יקבל תגמול עבור עבודתו כפי שיוסכם בין הצדדים בכתב</span></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><strong><u><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>הגבלת זכויות</span></u></strong></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>נתוני לקוחות הינם בבעלות הבעלים בלבד והסוכן מתחייב להמציא אותם לבעלים בכל שלב שידרש לכך</span></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>נכסים שיבנו בשותפות בין הבעלים לסוכן או ע"י הסוכן עצמו לצורך ביצוע עבודתו ו\או בזמן עבודתו הינם רכושו של הבעלים והסוכן מתחייב להמציא אותם לבעלים ו\או להעביר את נתונים וההרשאות והרישום שלהם לבעלים בכל עת שידרש לכך</span></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>בכלל זה גם אם הסוכן היה היוזם ו\או הוגה הרעיון ו\או המבצע של הנכס ו\אן פיתוחו</span></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>אין באמור כדי לגרוע מההברה אלא להוסיף עליה</span></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><strong><u><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>יחסי עבודה</span></u></strong></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>הסוכן הינו עצמאי</span></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>השכר המוסכם הינו לאחר מע"ם, באם ידרש הסוכן לכך</span></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><strong><u><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>הפסקת עבודה</span></u></strong></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>בכל מקרה של הפסקת עבודה, מתחייב הסוכן להעביר את כל פרטי הלקוחות לבעלים</span></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>הסוכן יבצע חפיפה עם מחליפו, במקרה שידרש לכך</span></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>לקוחות שיחזרו לסוכן לאחר סיום עבודותו ו\או יגיעו עליו באמצעי הפרסום ששימש בזמן עבודתו, יפנה אותם הסוכן למחליפו (מטעם הבעלים) באם יש, ובאין – אל הבעלים עצמם.&nbsp;</span></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><strong><u><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>סעדים:</span></u></strong></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>איחור של שבועיים בתשלום השכר מזכה את הסוכן בזכות לפנות לבורר המוסכם, ובלב שהודיע על כך לבעלים לפחות שבועיים לפני פנייתו, יידע אותם על היותו מקופח בתשלום&nbsp;</span></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>אי עמידה מצד הסוכן בתנאי ההסכם מזכה את הבעלים בזכות הפיצוי לכל נזק שיגרם הן במישרין והן בעקיפין</span></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>טענת בעלות מצד הסוכן על הנכסים הדיגיטלים(כמפורט בהבהרה) מזכה את הבעלים בתשלום פיצוי של 50.000 ₪ בנוסף לכל סעד אחר ופיצוי עבור אומדן הנזק וכל פיצוי אחר המתחייב ע"פ החוק</span></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><strong><u><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>ישוב סכסוכים</span></u></strong></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"David","sans-serif"'}}>הסכמת הצדדים כי לבית הדין ארץ חמדה בירושלים עומדת הסמכות לדון בכל מחלוקת הנובעת מהסכם זה, כבורר מוסכם בהתאם לכל דין הנוהג בו, הסכם זה מהווה כתב בוררות עבורו</span></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"David","sans-serif"'}}>&nbsp;</span></p>
        </div>
        <p><span dir="RTL" style={{fontSize: '13px', lineHeight: '115%', fontFamily: '"David","sans-serif"'}}><br />&nbsp;</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span dir="LTR" style={{fontSize: '13px', lineHeight: '115%'}}>&nbsp;</span></p>
       
                  <a href="https://docs.google.com/document/d/1tbO1eAnbZ1i2IZgL8DrvhAWXWORyfbxr/edit?usp=sharing&ouid=114568757058550483410&rtpof=true&sd=true" target='_blank' style={{color:"#A8DDFE"}}>להורדת חוזה התקשרות לסוכן בפורמט DOCX לחץ כאן</a><br/><br/>
                  <a href="https://drive.google.com/file/d/1r2-5WfyPxgDliusFyXDxDSK_9ODjs0Wf/view?usp=sharing" target='_blank' style={{color:"#A8DDFE"}}>להורדת חוזה התקשרות לסוכן בפורמט PDF לחץ כאן</a><br/><br/>
               
         </div>
      );
    }
export default Text