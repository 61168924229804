import React from 'react'

const Promotion =()=>{
      return (
        <div style={{color:"white"}}>
        <h1> חבילת קידום אורגני</h1>
קידום אתרים אורגני הינו תהליך מורכב וארוך <br/>
על מנת שהאתר יקודם בגוגל עלינו לשדר לגוגל שהאתר מקצועי ובעל תוכן איכותי, בנוי נכון, מונחה ערכי–מפתח נכונים וגורם עניין.<br/>
את התהליך הזה נחלק לכמה שלבים, שלא חייבים להופיע דווקא בסדר הזה אבל כן רצוי שיחילו את כולם <br/>
שלב ראשון, יש לבנות את האתר נכון, להגדיר תגי מטא בצורה טובה להגדיר את שמות הנתיבים בהתאם לתוכן שלהם, להגדיר תוכן ומילות מפתח<br/>
שלב שני, לדאוג שבאתר יהיה תוכן רלוונטי לנושא האתר, תוכן מתחדש ותוכן מסביר, היחסות בן כמות המלל לכמות ה html  שבאתר גם משמעותית בדרוג גוגל, לכן דף נחיתה שמכיל טופס הרשמה בלבד, לא יקבל דרוג מלא בגוגל<br/>
חשוב שיהיה תוכן הסבר על המוצר או השרות, תוכן שמעניין את הגולשים מוסיף מידע ומעודד לחזור \ להשאר באתר<br/>
מכאן שקידום אתרים מלא יכלול גם כתיבת כתבות ועדכון התוכן באתר<br/>
שלב שלישי, אחת האינדיקציות המרכזיות של גוגל להגדיר רלוונטיות של אתר הינו העניין שמראים בעלי אתרים אחרים באתר, אם לאתר יש הרבה קישורים המפנים אליו, משמעות הדבר היא שהאתר כנראה רלוונטי וממילא מקבל מקום יותר מרכזי בגוגל, הדבר הוא עוד יותר משמעותי כאשר יש לאתר הפניות מאותו התחום התוכני של האתר למשל אם לאתר של בן האינסטלאטור יהיו הפניות מאתרים של אינסטלאטורים הדבר עוד יותר משמעותי בקידום.<br/>
מתוך הבנה זו, חלק משמעותי של העבודה של מקדמי אתרים הוא ליצור קישורים לאתר בכלל וקישורים רלוונטיים בפרט<br/>
עבודה זו היא הנקראת "off site" כיוון שהיא עבודת – קידום שלא נעשית על האתר אלא מחוצה לו<br/>
ישנם מקדמי אתרים רבים שמסתפקים בעבודה זו של יצירת קישורים<br/>
יש לציין שגם לעבודה זו בפני עצמה השפעה רבה וראיתי אתרים שהגיעו למקום הראשון בשם העסק שלהם ע"י יצירת קישורים בלבד , עם זאת על מנת לקדם את האתר גם במילות המפתח התחרותיות, ויצירת תנועה לאתר מומלץ לעשות עבודה מקיפה יותר<br/>
<br/>
<b>ומהקדמה זו נגזרים חבילות הקידום שלנו</b><br/><br/>
<b>חבילה בסיסית </b>
 – היא כוללת עבודה off site  בלבד ומספיקה במקרים רבים להקפיץ את האתר תחת שם העסק הייחודי שלו עד למקומות הראשונים<br/><br/>
 <b>חבילה מתקדמת </b>
 – הכוללת בנוסף לחבילה הבסיסית,גם הגדרות התגים שיפור נתיבים, העלאת תוכן מתחדש לאתר, לא כולל יצירת התוכן (התוכן יסופק ע"י בעל האתר)<br/><br/>
 <b>חבילה כוללת </b>
 – הכוללת בנוסף לחבילה המתקדמת, גם מחקר ויצירת תוכן מתחדש<br/><br/>
כל החבילות האלו דורשות זמן רב ולכן ובד"כ איננו יכולים להעריך מראש כמה זמן יקח עד שנגיע למקום ספציפי בגוגל, ולכן כל החבילות האלו הם חודשיות<br/><br/>
<b>חבילה חד פעמית לתיוג ואופטימיזציה </b>
- למי שמעדיף ללכת על החבילה הבסיסית, מומלץ לצרף עבודה חד פעמית על בנית תגי מטא, הגדרת מילות מפתח ותוכן לאתר ובניית תגים נכונה<br/>
ומכאן האפשרות לקניית חבילה חד פעמית של בניית תגים ומיקסום נתיבים<br/>
 


        </div>
      );
    }
export default Promotion