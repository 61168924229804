import React from 'react'

const Text=()=>{
      return (<div style={{color:'white'}}>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="RTL" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Arial",sans-serif'}}>זה מכתב שקיבלתי בעבר ממישהו שעושה קידום אתרים, אחרי שהקידום של החודש הראשון לא העלה מספיק</span><span dir="RTL" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Arial",sans-serif'}}>&nbsp;תוצאות, בו הוא מפרט את התוכנית של הקידום לחודש הבא</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="RTL" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Arial",sans-serif'}}>(בתרגום גוגל)</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="RTL" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>שלום אדוני</span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>,</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp;</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="RTL" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>אם נתחיל בעבודה בחודש זה עלינו לדבר על מילות מפתח כפי שסיכמנו בפעם האחרונה למילות מפתח חדשות, אז קודם כל אנא ספק מילות מפתח לעבודה בחודש הבא</span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>,</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp;</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="RTL" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>בחודש זה בוודאי נעבוד על התקדמות האתר הרבה יותר עמוק ונדאג שהאתר שלנו יתחיל את דירוג גוגל, ישפר את הביצועים, בפעם האחרונה שהתחלנו את עבודת הסחר באתר שלנו ובחודש זה נעשה עבודות טראפיק רבות ונתקן מבקרים חדשים באתר</span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>. ,</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp;</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="RTL" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>אנו נבצע שוב עבודות באתר באתר ונוודא שכל העבודה הקשורה לקידום אתרים תהיה נכונה</span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>,</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp;</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="RTL" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>אנו נעקוב אחר כל המשימות הללו באתר לקבלת תוצאות מהירות יותר</span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>,</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp;</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="RTL" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>פעילויות באתר</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp;</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="RTL" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>עבודה בשבוע הראשון</span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>: -</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp;</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>ניתוח אתרים</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>ניתוח מתחרים</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>תקן שגיאות באתר בהתאם לביקורת</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>מיטב חקר מילות המפתח, ניתוח, וגמר ואופטימיזציה</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>אופטימיזציה של כותרת ומטא תג</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>ניטור גוגל אנליטיקס</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>אופטימיזציה של תגיות תמונה</span><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp;/ Alt</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>תג תכונות כותרת קישור</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>מיטוב תג קנוני</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>כלי מנהלי האתרים של גוגל</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>אופטימיזציה</span><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp;Robot.txt</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>כותרת</span><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp;Tagots.txt&nbsp;</span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>מיקום יצירה / ניתוח</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>סמיכות</span><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp;SEO</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>מבנה קישורים</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>צפיפות מילות מפתח של</span><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp;SEO</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>תיקון קישורים שבורים</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>יצירת</span><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp;XML Sitemap&nbsp;</span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>ליצירת אופטימיזציה</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>פתח את פרוטוקול הגרף</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>בדיקת כרטיסי טוויטר</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>פרופיל גוגל פלוס</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>העתק בדיקת תוכן</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>לפני</span><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp;SEO&nbsp;</span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>ואחרי ציון</span><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp;SEO</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>אייקון</span><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp;Favicon</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>דוח דירוג שבועי</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp;</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>=================</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>בעוד חודש שלם אעשה עבודות אלה בעבודות שלי מחוץ לדף להשגת סחר וקידום מכירות לאתר שלנו</span><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>.</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp;</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>תגובה בבלוג</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>קידום בלוגים</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>הגשת מנועי חיפוש</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>הגשה מסווגת</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>סימניות חברתיות</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>הגשות מדריך</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>הגשת מאמר</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>אינטרנט 2.0</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>שיתוף תמונות</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>כתיבה והפצה של הודעה לעיתונות</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>רישום עסקים מקומיים</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>הגשת</span><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp;Pdf</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>הגשת מסמך</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}></span><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>קישורים נכנסים / רשות דומיינים ורשות עמודים איכותיים</span><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>.</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp;</span></div>
        <div dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Times New Roman",serif'}}>תודה</span><span dir="LTR" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>,</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp;</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif', textAlign: 'right'}}><span dir="RTL" style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Arial",sans-serif'}}>המלל המקורי:</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp;</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>Hello Sir,</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp;</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>If we start this Month work then we need to talk about keywords as we agreed last time for new keywords, So first of all please provide keywords for next Month Work,</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp;</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>In this month we will surely work on website progress much deeper and make sure our website will start Google rank, Improve performance, Last time we started our website traffics work and in this month we will do much traffics works and correct new visitors on the website,</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp;</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>We will do again complete on-page work on the website and make sure all SEO related work will be correct,</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp;</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>We will follow all these tasks again on the website for faster results,</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp;</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>On-page activities</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp;</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>First-week work: -&nbsp;</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp;</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Website Analysis</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Competitor Analysis</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Fix website errors according to audit</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Best Keyword Research, Analysis, &amp; Finalization &amp; Optimization</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Title &amp; Meta Tag Optimization</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Google Analytics Monitoring</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Image/Alt tags optimization</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Link Title Attributes Tag</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Canonical Tag Optimization</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Google Webmaster Tools</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Optimization Robot.txt</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Heading Tagots.txt Creation/Analysis Placement</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;SEO Proximity</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Link Structure</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;SEO Keyword Density</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Broken Links Fix</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Google Xml Sitemap Creation &amp; optimization</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Open Graph protocol Check</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Twitter Card Check</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Google plus Profile&nbsp;</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Copy Content Check</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Before SEO and After SEO Score&nbsp;</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Favicon Icon</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Weekly Ranking Report</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp;</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>================</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>In a complete month, I will do these works in my off-page work for getting traffics and promotions for our website.</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp;</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>Blog commenting&nbsp;</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>Blog Promotion</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>Search Engine Submission</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>Classified submission</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>Social Bookmarking&nbsp;</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>Directory Submissions&nbsp;</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>Article Submission</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>Web 2.0&nbsp;</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>Image Sharing</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>Press Release Writing &amp; Distribution</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>Local Business Listing</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>Pdf Submission</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>Document submission</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>High-Quality Backlinks / Domain Authority and Page Authority.</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>&nbsp;</span></div>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', lineHeight: '115%', fontSize: 15, fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: 20, lineHeight: '115%', fontFamily: '"Georgia",serif'}}>Thanks,</span></div>
      </div>
      
        
        


      );
    }
export default Text