import React from 'react'


export default ()=>{
    return(
       
                <div>
                    <br/> <br/> <br/>
                  {/* <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'center', fontSize: '16px', fontFamily: '"Times New Roman",serif', marginRight: '-2.55pt'}}><strong><u><span style={{fontFamily: '"David",sans-serif'}}><span style={{textDecoration: 'none'}}>&nbsp;</span></span></u></strong></p> */}
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'center', fontSize: '16px', fontFamily: '"Times New Roman",serif', marginRight: '18.0pt'}}><strong><u><span style={{fontFamily: '"David",sans-serif'}}>הסכם סודיות לפיתוח תוכנה\אפליקציה</span></u></strong></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif'}}><span style={{fontFamily: '"David",sans-serif'}}>&nbsp;</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif', marginRight: '18.0pt'}}><span style={{fontFamily: '"David",sans-serif'}}>שנערך ונחתם בתאריך:__________________</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif'}}><span style={{fontFamily: '"David",sans-serif'}}>&nbsp;</span></p>
                  <p dir="RTL" style={{marginTop: '0cm', marginRight: '72.0pt', marginBottom: '.0001pt', marginLeft: '0cm', textAlign: 'right', textIndent: '0cm', fontSize: '17px', fontFamily: '"Times New Roman",serif'}}><span style={{fontSize: '16px', fontFamily: '"David",sans-serif'}}>בין:_____________________________ &nbsp; &nbsp;ת"ז: _____________________________</span></p>
                  <p dir="RTL" style={{marginTop: '0cm', marginRight: '72.0pt', marginBottom: '.0001pt', marginLeft: '0cm', textAlign: 'right', textIndent: '0cm', fontSize: '17px', fontFamily: '"Times New Roman",serif'}}><span style={{fontSize: '16px', fontFamily: '"David",sans-serif'}}>להלן הממציא</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif', marginRight: '54.0pt'}}><span style={{fontFamily: '"David",sans-serif'}}>&nbsp;</span></p>
                  <p dir="RTL" style={{marginTop: '0cm', marginRight: '72.0pt', marginBottom: '.0001pt', marginLeft: '0cm', textAlign: 'right', textIndent: '0cm', fontSize: '17px', fontFamily: '"Times New Roman",serif'}}><span style={{fontSize: '16px', fontFamily: '"David",sans-serif'}}>ובין:_____________________________ &nbsp; &nbsp;ת"ז: _____________________________</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif', marginRight: '72.0pt'}}><span style={{fontFamily: '"David",sans-serif'}}>להלן המפתח</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif'}}><span style={{fontFamily: '"David",sans-serif'}}>&nbsp;</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif'}}><span style={{fontFamily: '"David",sans-serif'}}>&nbsp;</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif', marginRight: '18.0pt'}}><span style={{fontFamily: '"David",sans-serif'}}>הואיל:-</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif'}}><span style={{fontFamily: '"David",sans-serif'}}>&nbsp;</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif', marginRight: '36.0pt'}}><span style={{fontFamily: '"David",sans-serif'}}>והממציא &nbsp;הינו הבעלים הבלעדיים של כל הזכויות בעניין <br /> <br /> ______________________________________________________________ <br /> <br /> תעוד:<br /> <br /> ______________________________________________________________<br /> <br /> (להלן- המידע).<br />&nbsp;</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif', marginRight: '36.0pt'}}><span style={{fontFamily: '"David",sans-serif'}}>והממציא מעוניין לפתח תוכנה \ אפליקציה (להלן התוכנה) ע"י המפתח או מי מטעמו</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif', marginRight: '36.0pt'}}><span style={{fontFamily: '"David",sans-serif'}}>ובמהלך האפיון ייחשף בפני המפתח מידע סודי ייחודי שבבעלות הממציא</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif'}}><span style={{fontFamily: '"David",sans-serif'}}>&nbsp;</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif', marginRight: '18.0pt', lineHeight: '150%'}}><span style={{fontFamily: '"David",sans-serif'}}>לפיכך הוסכם בן הצדדים כי:</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif', marginRight: '36.0pt', lineHeight: '150%'}}><span style={{fontFamily: '"David",sans-serif'}}>1. המפתח מודע &nbsp;ומסכים כי המידע הינו נחלתו ורכושו של הממציא</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif', marginRight: '36.0pt', lineHeight: '150%'}}><span style={{fontFamily: '"David",sans-serif'}}>2. המפתח לא יעשה שימוש מסחרי כל שהוא במידע שהתקבל מן הממציא אלא לצורכי הפיתוח של התכנה</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif', marginRight: '36.0pt', lineHeight: '150%'}}><span style={{fontFamily: '"David",sans-serif'}}>3. המפתח לא יפתח תוכנה אחרת כל שהיא &nbsp;על פי המידע שיתקבל מהממציא</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif', marginRight: '36.0pt', lineHeight: '150%'}}><span style={{fontFamily: '"David",sans-serif'}}>4. המפתח מתחייב שלא לפרסם ו\או להעביר &nbsp;ו\או לחשוף &nbsp;את המידע בכל צורה שהיא אלא לצורך בדיקת אפשרות &nbsp;שת"פ &nbsp;או לצורך פיתוח התוכנה</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif', marginRight: '36.0pt', lineHeight: '150%'}}><span style={{fontFamily: '"David",sans-serif'}}>5. החלטת אחד הצדדים על הפסקת שיתוך הפעולה, אינה מקנה למפתח כל זכות במידע, והוא נותר נחלת הממציא</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif', marginRight: '36.0pt', lineHeight: '150%'}}><span style={{fontFamily: '"David",sans-serif'}}>6. פיתוח התוכנה ע"י המפתח אינה מקנה לו זכויות בה והם (מלא הזכויות בתוכנה) יהיו של הממציא, אא"כ הוסכם ע"י הצדדים אחרת ובכתב.</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif', marginRight: '36.0pt', lineHeight: '150%'}}><span style={{fontFamily: '"David",sans-serif'}}>7. הכנסת משקיעים ו\או שותפים נוספים תהיה החלטתו של הממציא.</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif', marginRight: '36.0pt', lineHeight: '150%'}}><span style={{fontFamily: '"David",sans-serif'}}>8. אין באמור לעייל כדי ליצור יחסי שותפות בן הצדדים&nbsp;</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif', marginRight: '18.0pt', lineHeight: '150%'}}><span style={{fontFamily: '"David",sans-serif'}}>&nbsp;</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif', lineHeight: '150%'}}><span style={{fontFamily: '"David",sans-serif'}}>&nbsp;</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif', lineHeight: '150%'}}><span style={{fontFamily: '"David",sans-serif'}}>&nbsp;</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif', marginRight: '18.0pt', lineHeight: '150%'}}><span style={{fontFamily: '"David",sans-serif'}}>הסרת אחריות:</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif', marginRight: '36.0pt', lineHeight: '150%'}}><span style={{fontFamily: '"David",sans-serif'}}>1. המפתח אינו מחויב בסודיות על כל מידע שהינו נחלת הכלל ו\או כל מידע שאינו פיתוחו הייחודי של הממציא</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif', marginRight: '36.0pt', lineHeight: '150%'}}><span style={{fontFamily: '"David",sans-serif'}}>2. לאחר העלאת התוכנה לחנויות או לגורמי השיווק הרלוונטיים, יחשב המידע כנחלת הכלל לעניין האמור בסעיף הקודם.</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif', marginRight: '36.0pt', lineHeight: '150%'}}><span style={{fontFamily: '"David",sans-serif'}}>3. אין באמור לעייל בכדי למנוע מהמפתח לפתח ו\או להשתתף בפרויקטים אחרים ו\או של יזמים אחרים, בתחומים הנושקים ו\או הזהים לתחום המידע ובלבד שלא יעשה כל שימוש במידע שהועבר מן הממציא אא"כ קיבל את אישור הממציא לכך ובכתב</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif', marginRight: '36.0pt', lineHeight: '150%'}}><span style={{fontFamily: '"David",sans-serif'}}>4. אין המפתח אחראי על פרסום מידע שיעשה ע"י כל גורם אחר</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif', lineHeight: '150%'}}><span style={{fontFamily: '"David",sans-serif'}}>&nbsp;</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif', lineHeight: '150%'}}><span style={{fontFamily: '"David",sans-serif'}}>&nbsp;</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif'}}><span style={{fontFamily: '"David",sans-serif'}}>&nbsp;</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif', marginRight: '18.0pt'}}><span style={{fontFamily: '"David",sans-serif'}}>תוקף חובת שמירת הסודיות הינה ל_________שנים מיום חתימתו</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif'}}><span style={{fontFamily: '"David",sans-serif'}}>&nbsp;</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif', marginRight: '18.0pt'}}><span style={{fontFamily: '"David",sans-serif'}}>ועל כל זה באנו על החתום:</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif'}}><span style={{fontFamily: '"David",sans-serif'}}>&nbsp;</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif'}}><span style={{fontFamily: '"David",sans-serif'}}>&nbsp;</span></p>
                  <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '16px', fontFamily: '"Times New Roman",serif', marginRight: '18.0pt'}}><span style={{fontFamily: '"David",sans-serif'}}>הממציא:___________________________ המפתח:___________________________</span></p>
                  <br/> <br/>
                  <a href="https://drive.google.com/file/d/1HymLnI_tQIsua_6IcV-YZ1Dqqd621xZT/view?usp=sharing" target='_blank'>להורדת חוזה הסודיות לפיתוח אפליקציה בפורמט DOCX לחץ כאן</a><br/><br/>
                  <a href="https://drive.google.com/file/d/1RiXZJfA6KTI2YXhC9ttnAg_pThcR2Fkp/view?usp=sharing" target='_blank'>להורדת חוזה הסודיות לפיתוח אפליקציה בפורמט PDF לחץ כאן</a><br/><br/>
                </div>
             
            
    )
}