import React ,{useState}from 'react';
import Fade from 'react-reveal/Fade';
import Text from '../../../../common/src/components/Text';
import Image from '../../../../common/src/components/Image';
import Button from '../../../../common/src/components/Button';
import Container from '../../../../common/src/components/UI/Container';
import BannerWrapper, {
  BannerContent,
  BannerImage,
  CustomerWrapper,
  ImageWrapper,
} from './banner.style';
import { client } from '../../../../common/src/data/AppModern';
import circleBorder from '../../../../common/src/assets/image/appModern/shape.svg';
import axios from 'axios'
import Promotion from './Promotion'





const Banner = () => {
  // For a promotion - help us
  const path=window.location.href
  const sender=path.split('senderId=')[1]


  const [Name,setName]=useState("")
const [Email,setEmail]=useState("")
const [Phone, setPhone]=useState("")
const [Message, setMessage]=useState("")

const[OpenForm,setOpenForm]=useState(0)
// 0- מצב בסיסי טופס פרטים פתוח
// 1- הטופס נשלח בהצלחה
// 2- בעיה במשלוח הטופס
// 3- טופס רישום 
// 4- השארת פרטי תשלום

// const creditUrl="https://meshulam.co.il/s/8a6f5a53-c224-553c-14f7-5f099166b331" 

const handleChange=(e)=>{

  switch(e.target.name){
case ("name"):
setName(e.target.value)  

break

case ("email"):
setEmail(e.target.value)  

break

case ("phone"):
setPhone(e.target.value)  

break

case ("message"):
setMessage(e.target.value)  

break




  }
}
let bgcolor,
 formTitleText="מעגלי חשיבה ופיתוח- טופס התעניינות", 
 formTitleMesage=<div>יוצרים את העתיד <br/></div>, 
 paymentNotice,
 formButtonText="שלח",
 subjectForEmail="הודעה חדשה באתר מוטו בנושא טופס מעגלי חשיבה ופיתוח  "
const form=( <center><form dir="rtl" style={{
  color:"white",
  backgroundColor: bgcolor,
  maxWidth: '500px',
}}>
   <fieldset>
   <legend style={{color:"white"}}>{formTitleText}</legend>
 
 
    השם שלך:<br/>
    <input  type="text" name="name"  value={Name} onChange={handleChange}/>
<br/>
    <br/>האימייל שלך:<br/>
    <input  type="text" name="email"  value={Email} onChange={handleChange} />
<br/>
    <br/> הטלפון שלך:<br/>
    <input  type="text" name="phone"  value={Phone} onChange={handleChange} />

    <br/>
    {paymentNotice}
<br/>
<center>
<Button  title={formButtonText}  onClick={()=>{
if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Email)))
alert("האימייל לא תקין- נא הכנס אימייל תקין!")
else
{
  
  axios({
method:"post",
url:" https://us-central1-sender-4330e.cloudfunctions.net/app/send_email_client",
data:{to:"omershiran@gmail.com",from:"omershiran@gmail.com",subject:subjectForEmail, text:(Name+"  "+Email+"  "+Phone+"  "+Message+"  sender:"+getPhoneFromString(sender))}
}).then((v)=>{
  googleAds()
  if(v.data===1001){setOpenForm(OpenForm+1)}
else{
  
  setOpenForm(2)}
}).
  catch(e=>{ 
    
    setOpenForm(2)})
    setOpenForm(5)
}}}/></center>
</fieldset>
</form>

</center>)

const googleAds=()=>{
  window.gtag('event', 'purchase', {
    send_to: 'AW-1053360236/Nd2-CIid17wCEOyApPYD',
    value: 0,
    currency: 'BRL',
  });
}

const AfterProperDelivery=(<h2 style={{color:"white"}}>ההודעה נשלחה בהצלחה</h2>)
const AfterProperRegistration=(<h2 style={{color:"white"}}>הבקשה לרישום התקבלה, היא תושלם עם התשלום,<br/> אנו ניצור עימך קשר בהקדם </h2>)
const AfterUnProperDelivery=(<h2 style={{color:"white"}}>תקלה במשלוח ההודעה, ההודעה לא נשלחה,<br/> אנא נסה שנית במועד מאוחר יותר</h2>)
const DirectMessageAfterSending=(<h2 style={{color:"white"}}>ההודעה נשלחת אנא המתן...</h2>)

let formContent
switch(OpenForm){
  case (0):
    formContent=form
  break;
  case (1):
    formContent=AfterProperDelivery
  break;
  case (2):
    formContent=AfterUnProperDelivery
  break;
  case (3):
    formContent=form
  break;
  case (4):
    // window.location.href =creditUrl
  break;
  case (5):
    formContent=DirectMessageAfterSending
  break;
}

const formCourse=(<Fade up delay={100}>
  <h2 style={{color:"white"}}>מעוניין להצטרף למעגלי חשיבה ופיתוח? &nbsp; &nbsp; &nbsp;<br/>

  </h2>
  <h1 style={{color:"white"}}>{formTitleMesage}</h1>

{formContent}
<center>
</center>
 </Fade>)
 const DescriptionCourse=(<div> <Fade up delay={100}>
  <h2 style={{color:"white"}}>בית תוכנה:</h2>
  <h1>מוטו ישראל</h1>
  
  <div style={{clear: "both", dir:"ltr"}}>
</div>
<br/>

</Fade>
<Fade up delay={200}>
  <Promotion/>

</Fade></div>
)
const getPhoneFromString=(phone)=>{
  if(!phone)return("no sender!")
  let phoneString= phone.replace(/-/g, '*')
  phoneString=phoneString.replace(/r/g, '1')
  phoneString=phoneString.replace(/t/g, '2')
  phoneString=phoneString.replace(/y/g, '3')
  phoneString=phoneString.replace(/u/g, '4')
  phoneString=phoneString.replace(/h/g, '5')
  phoneString=phoneString.replace(/g/g, '6')
  phoneString=phoneString.replace(/f/g, '7')
  phoneString=phoneString.replace(/d/g, '8')
  phoneString=phoneString.replace(/s/g, '9')
  phoneString=phoneString.replace(/a/g, '0')
   return(phoneString)
 }

  return (
    <BannerWrapper id="home" dir="rtl" >
      <Container >

      <BannerImage>
          {formCourse}
        </BannerImage>

        <BannerContent >
         {DescriptionCourse}
        </BannerContent>
       
      </Container>


      <CustomerWrapper>
        <Text content="מהגלריה שלנו:" />
        <ImageWrapper>
         {client.map(item => (
            <a href={item.link} target="blank">
            <Image
              key={`client-key${item.id}`}
              src={item.image}
              alt={item.title}
            /></a>
          ))}
           </ImageWrapper>
      </CustomerWrapper>
      
      
      <img
        className="bannerBottomShape"
        src={circleBorder}
        alt="Bottom Circle"
      />
    </BannerWrapper>
  );
};

export default Banner;
