import React from 'react'

const ServiceOffer=()=>{

return(
    <div style={{background: '#E067FB'}}>
      <br/> <br/> <br/>
        <div style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '9.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '120%', fontSize: '12px', fontFamily: '"Tahoma",sans-serif', color: '#404040', border: 'none', borderLeft: 'double #1F4E79 6.75pt', padding: '0cm 4.0pt 0cm 0cm'}}>
          <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', lineHeight: '21.0pt', border: 'none', padding: '0cm', fontSize: '12px', fontFamily: '"Tahoma",sans-serif', color: '#1F4E79'}}><span dir="LTR" style={{fontSize: '25px'}}>motto<br />&nbsp;</span><span dir="LTR" style={{fontSize: '25px'}}>הצעת שירותים</span></p>
        </div>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '9.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '120%', fontSize: '12px', fontFamily: '"Tahoma",sans-serif', color: '#404040'}}><span dir="LTR">&nbsp;</span></p>
        <h1 dir="RTL" style={{marginTop: '30.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', textAlign: 'right', fontSize: '19px', fontFamily: '"Tahoma",sans-serif', color: '#1F4E79'}}><span dir="LTR">קצת עלינו</span></h1>
        <p dir="RTL" style={{margin: '0cm', marginBottom: '.0001pt', textAlign: 'right', fontSize: '12px', fontFamily: '"Tahoma",sans-serif', color: '#404040', marginTop: '30.0pt', background: '#DEEAF6'}}><span dir="LTR">אנו, במוטו ישראל, &nbsp;מתמחים בפיתוח אפליקציות, אתרים ומערכות מחשב, בטכנולוגיה מתקדמת מהשורה הראשונה<br /> עם נסיון עשיר של שנים של פיתוח<br />&nbsp;ובמגוון כלים ותכנולוגיות</span></p>
        <h1 dir="RTL" style={{marginTop: '30.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', textAlign: 'right', fontSize: '19px', fontFamily: '"Tahoma",sans-serif', color: '#1F4E79'}}><span dir="LTR">קצת עלי</span></h1>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '9.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '120%', fontSize: '12px', fontFamily: '"Tahoma",sans-serif', color: '#404040', background: '#DEEAF6'}}><strong><span dir="LTR">עמר שירן</span></strong><span dir="LTR"><br /> מנכ"ל מוטו ישראל<br /> עוסק בפיתוח בשפות מתקדמות<br />&nbsp;</span><span dir="LTR">react, NodeJS, JavaScript ,java, c# &nbsp; ועוד<br /> <strong>ומנהל באופן אישי את כל הפרויקטים שמתקבלים אצלינו</strong></span></p>
        <h1 dir="RTL" style={{marginTop: '30.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', textAlign: 'right', fontSize: '19px', fontFamily: '"Tahoma",sans-serif', color: '#1F4E79'}}><span dir="LTR">...לעניין</span></h1>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '9.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '120%', fontSize: '12px', fontFamily: '"Tahoma",sans-serif', color: '#404040', background: '#DEEAF6'}}><span dir="LTR" style={{fontSize: '13px', lineHeight: '120%'}}>ראיתי את הדרישה שלכם בזירה העסקית<br/><span dir="LTR" style={{fontSize: '13px', lineHeight: '120%'}}>ואנחנו נשמח לספק לכם את השירותים באופן הטוב והמקצועי ביותר</span><br/><span dir="LTR" style={{fontSize: '13px', lineHeight: '120%'}}>יש לנו ניסיון רחב ב</span><br/><span dir="LTR" style={{fontSize: '13px', lineHeight: '120%'}}>NodeJS, GitHub &nbsp;ועוד</span><br/><span dir="LTR" style={{fontSize: '13px', lineHeight: '120%'}}>וכן ניסיון ב- &nbsp;<span style={{color: '#212121'}}>Mongodb \&nbsp;</span></span><span dir="LTR" style={{fontSize: '13px', lineHeight: '120%', color: '#212121'}}>firebase DB &nbsp;&nbsp;</span><br/><span dir="LTR" style={{fontSize: '13px', lineHeight: '120%'}}>פיתוח צד שרת וצד לקוח</span><br/><span dir="LTR" style={{fontSize: '13px', lineHeight: '120%'}}>ועוד</span></span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '9.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '120%', fontSize: '12px', fontFamily: '"Tahoma",sans-serif', color: '#404040'}}><span dir="LTR">&nbsp;</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '9.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '120%', fontSize: '12px', fontFamily: '"Tahoma",sans-serif', color: '#404040', background: '#FCE6E1'}}><span dir="LTR">נשמח לעבוד עבורכם בפרוייקטים (כפרילנסרים) בצורה המקצועית ביותר</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '9.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '120%', fontSize: '12px', fontFamily: '"Tahoma",sans-serif', color: '#404040', background: '#FCE6E1'}}><span dir="LTR">להמשך ההתקשרות אנא צרו עימי קשר ב</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '9.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '120%', fontSize: '12px', fontFamily: '"Tahoma",sans-serif', color: '#404040', background: '#FCE6E1'}}><span dir="LTR">אימייל – <a href="mailto:omer@motto.co.il">omer@motto.co.il</a></span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '9.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '120%', fontSize: '12px', fontFamily: '"Tahoma",sans-serif', color: '#404040', background: '#FCE6E1'}}><span dir="LTR">ובנייד- 0512635325</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '9.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '120%', fontSize: '12px', fontFamily: '"Tahoma",sans-serif', color: '#404040'}}><span dir="LTR">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></p>
      </div>
)
}

export default ServiceOffer