import React from 'react';
import Popup from 'reactjs-popup';
import '../../../../../../node_modules/reactjs-popup'

// import 'reactjs-popup/dist/index.css';
import CreditCard from './CreditCard'
 
export default () => (<div><br/><br/><br/><br/><br/><br/>
  <Popup trigger={<button> Trigger</button>} position="right center">
    <div><CreditCard/></div>
  </Popup></div>
);