import React from 'react'
import Blog from 'dugoly-blog'

const Text=()=>{
      return (
        <div style={{backgroundColor:"#FBE3DE"}}>
        <Blog ownerUid="-MO0rfu7iS6SO7iFQS2x" bCode="motto" BlogTitle="הבלוג של עמר" dir="rtl" withRouter={{RelativePath:"/blog"}}/>
        </div>
        );
    }
export default Text