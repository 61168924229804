import React, { Component, useEffect, useState} from 'react';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';


const TextEditords  =({EditordText, setEditordText})=>{
 
  const onEditorStateChange = (editorState) => {console.log("editorState:  ",editorState)
    // this.setState({
    //   editorState,
    setEditordText(editorState)
    // })
  }

  return (
      <div>
        <div style={{ backgroundColor: "white", minHeight: "350px",boxSizing:"border-box", }}>
          <Editor
            editorStyle={{
              minHeight:"350px",
              boxSizing:"border-box",
            }}
            editorState={EditordText}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={onEditorStateChange}
            toolbar={{
              inline: { inDropdown: true },
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
            }}
          />
        </div>
      </div>
    );
  }


function TextFiled({ EditordText, setEditordText}) {
  
  

  return (
    <div>
        
        <p style={{textAlign: 'right'}}><span style={{fontSize: '30px', fontFamily: 'Georgia'}}>ממיר טקסט ל html</span></p> <p style={{textAlign: 'right'}}><span style={{fontSize: '30px', fontFamily: 'Georgia'}}>הכנס את הטקסט בתיבה הזו👇, את ה html תקבל בתיבה שמתחתיה</span></p>
      
    <div 
    style={{
      boxSizing:"border-box",
            padding:"0px",
    }}>
      <div className="textinput" style={{
        maxWidth: "100%",
        minWidth: "60%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        boxSizing:"border-box",
        alignItems: "flex-start",
        backgroundColor: "transparent",
        padding: "20px 10px 20px 10px"
      }}>
        
        <div style={{ width: "100%", maxWidth: "900px" ,boxSizing:"border-box",}}>
          <TextEditords  EditordText={EditordText} setEditordText={setEditordText}/><br/>
          <p style={{textAlign: 'right'}}><span style={{fontSize: '30px', fontFamily: 'Georgia'}}>את ה html תקבל בתיבה הזו👇 </span></p>
           <p dir="ltr"style={{
                      backgroundColor: 'white',
                      width: '100%',
                      height: '100%'}}>
                        {draftToHtml(convertToRaw(EditordText.getCurrentContent()))}
          </p>
          
        </div>
      </div>
    </div>
    </div>
  );
}


function AddPost({setComponent, owner, postData, ownerUid, bCode, dir, updateData}) {

  const [EditordText, setEditordText]= useState(EditorState.createEmpty())



  
  // const contentBlock = htmlToDraft(postData.text);
  //   if (contentBlock) {
  //     const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  //     const editorState = EditorState.createWithContent(contentState);
  // setEditordText(editorState)
  //   }



  return (
    <div 
    
    style={{
      width: document.activeElement.clientWidth<=240 ? "96%" : "100%" ,
      margin:"auto",
      boxSizing:"border-box",
      padding:"0px",
  }}>
      <div className="postaddform" style={{
        display: "flex",
        flexDirection: "row",
        boxSizing:"border-box",
        justifyContent: "space-around",
        alignItems: "flex-start",
        backgroundColor: "#f6f6f6",
        padding: "24px 0px 0px 0px",
        flexWrap: "wrap"
      }}>
        <TextFiled  EditordText={EditordText} setEditordText={setEditordText}/>
        <div>
      </div>
      </div>
    </div>
  );
}

export default AddPost;
