import React from 'react'

const Promotion =()=>{
      return (
        <div>
          
          <p style={{textAlign: 'right'}}>
            <h1>הרחבות ותוספים לכרום</h1><br />
            ניתן לקבל תמיכה על התוסף this is it <br />
            <b>ניתן להזמין שרותי פיתוח לתוסף יחודי שלך בטופס המצורף </b><br />
            לקבלת תמיכה, נא ליצור קשר דרך הטופס המצורף<br />
<b> הסבר על התוסף </b><br />
התוסף הינו תוסף לאתר החיפוש של גוגל  (google search)<br />
         התוסף מפנה באופן ישיר את המשתמש לאתר יעד במקרה שברור ממלת החיפוש שהוא מחפש את אותו האתר המסויים <br />
         או במקרה והמשתמש בחר להגדיר את האתר הנידון כאתר היעד של החיפוש הנוכחי<br />
         <br />
         לפיתוח תוסף משלך ניתן ליצור קשר דרך טופס זה<br /><br /><br />

         הסבר מפורט כולל הוראות שימוש<br /><br />
         this is it הוא הרחבה לכרום המשמשת  לחיפוש גוגל <br />
         <br />
פעילות התוסף: התוסף מפנה את המשתמש לאתר הרלוונטי ללא צורך במעבר דרך דף החיפוש<br />
אופן הביצוע: המילות מפתח בהם כוונת המשתמש ברורה, כלומר במילות חיפוש המשמשות את בתור שם מובהק לחברה או שרות או אתר , ללא תחרות על מילת מפתח זו, המשתמש יופנה באופן אוטומטי לאתר הרלוונטי<br />
באפשרות המשתמש לבחור עבור כל מילת חיפוש מסויימת בה לא יופנה לאתר הרלוונטי אלא יועבר לדף החיפוש<br />
כיצד?- מיד עם עליית הדף הרלוונטי, יופיע כפתור בצד ימין למעלה "no, this is not" לחיצה עליו תוביל להגדיר את הדף ההוא כדף לא מחוייב עבור מילת החיפוש הנוכחית עבור אותו המשתמש<br />
כמו כן, יש אפשרות לכל משתמש להגדיר לעצמו מילות חיפוש שיפנו אותו לאתר הרלוונטי עבורו באופן ישיר<br />
כיצד? לאחר ביצוע חיפוש גוגל ומעבר לאתר הנבחר, יופיע כפתור בצד ימין למעלה "זה זה" - לחיצה עליו תגדיר את הדף כדף היעד עבור אותה מילת חיפוש עבור אותו המשתמש<br />
<br />
          </p>

      
          
        </div>
      );
    }
export default Promotion