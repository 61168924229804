import React from 'react'

const Promotion =()=>{
      return (
        <div>
          
          <p style={{textAlign: 'right'}}><span style={{fontSize: '36px'}}>עזור לנו, נעזור לך!</span></p>
 <p><span style={{fontSize: '24px'}}>אנחנו צריכים את עזרתך במציאת לקוחות חדשים</span></p>
 <p><span style={{fontSize: '36px'}}>על כל לקוח חדש - תקבל 50 ש"ח</span></p>
 <p><span style={{fontSize: '30px'}}>איך זה עובד?</span></p>
 <p><span style={{fontSize: '30px'}}>אתה מכניס את מספר הטלפון שלך ומקבל קישור יחודי שלך</span></p>
 <p><span style={{fontSize: '30px'}}>מפיץ את הקישור בכל מקום שיש בו אנשים פוטנציאלים, ואם אחד מהם יהיה לקוח, לך יש 50 ש"ח, עמלת תודה מאיתנו!</span></p>
 <p><span style={{fontSize: '30px'}}>יש לך חבר עם אתר, שלח לו את הקישור<br/> חבר שלך הפיץ את הקישור שלך? -אתה מקבל את העמלות!</span></p>
 <p><span style={{fontSize: '30px'}}>פרסם בפייסבוק, באימייל ובכל מקום שיכול למשוך לקוחות</span></p>
 <p><span style={{fontSize: '30px'}}><strong>הבאת יותר מ 10 לקוחות? - יש לך עוד 100 ש"ח בונוס!</strong></span></p>
 <p /> <p><span style={{fontSize: '12px'}}>תנאי המבצע: <br />
 אנחנו מזהים את הכניסות ע"פ הקישור ששלחנו לך, רק אנשים שנכנסו דרך הקישור וקנו דרך קישור זה יזכו אותך בעמלת- תודה, לכן הקפד לשלוח לחברים דווקא את הקישור הזה!<br />
 המזהה שלך הוא מספר הטלפון, חשוב מאוד לשים לב שכתבת אותו נכון<br />
 רק לקוחות שרכשו חבילת קידום מזכים אותך בעמלה<br />
 רק לקוחות שזוהו ע"י המערכת, שנכנסו דרך הקישור שלך - נחשבים לקוחות מזכים<br />
 יש להעתיק את הקישור המתקבל בצורתו המדוייקת<br/>
 המבצע לחודשים יוני יולי אוגוסט 21 בלבד <br/>
 בכל דיון יהיה הגורם המוסכם- ביה"ד של כולל ארץ חמדה<br />
 </span></p>
 <p /> <p /> <p />
      
          
        </div>
      );
    }
export default Promotion