import React from 'react'

const Text=()=>{
      return (
        <div style={{color:"white"}}>
       
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>מזה&nbsp;</span><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>תיקאתר</span><span style={{fontFamily: '"Arial",sans-serif'}}>? זה לא כמו שוקאתר? מה ההבדל בינהם?</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>את שוקאתר כולנו מכירים! – זה הסביבה לקניית אתרים!</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>אבל מה זה&nbsp;</span><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>תיק-אתר</span><span style={{fontFamily: '"Arial",sans-serif'}}>?</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>גם&nbsp;</span><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>תיק-אתר&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>זה&nbsp;</span><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>סביבה למסחר באתרים&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>פעילים אבל לא קנייה של אתרים אלא אופציות לפי אחוזים באתרים קיימים שמיועדים להגיע לזירות המסחר באתרים, כלומר ל-שוקאתר.</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>לפני שהאתר יגיע לבשלות מספקת כדי שיוכל להימכר כאתר פעיל, עליו לעבור תהליך ארוך של פיתוח וקידום.</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>בשלב זה לעיתים, יחפש בעל האתר שותף&nbsp;</span><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>להשקיע בפיתוח האתר וקידומו</span><span style={{fontFamily: '"Arial",sans-serif'}}>, השותף מקבל אחוזים באתר וכמובן, בהתאם,&nbsp;</span><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>אחוזים ברווחים בזמן המכירה.</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><u><span style={{fontFamily: '"Arial",sans-serif'}}>למשקיע רציני שמעוניין להשקיע בחוכמה למזער סיכונים ולהגדיל את אחוזי ההצלחה, ניתן לבנות תיק שמורכב ממספר אתרים</span></u></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span dir="LTR">&nbsp;</span></p> 
           </div>
      );
    }
export default Text