import React from 'react'

const keysObject={
  keywords:{name:"מילות מפתח",
text:(<div style={{color:"white"}}>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><strong><u><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>מילות מפתח (Keywords)</span></u></strong></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>אלו מילים המהוות אינדיקציה לעיקרי&nbsp;</span><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>התוכן באתר&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>או מילים שמשקפות את התכנים המהותיים באתר.</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>למשל בחנות המוכרת נעליים בה ניתן לשלם גם במזומן וגם באשראי, תשמש המילה "נעליים" או "נעל" כמילת מפתח אבל לא המילה "מזומן" כיוון שאינה משקפת את התכנים המהותיים לאתר.&nbsp;</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>במילות מפתח יכללו גם מילים המובילות אל התכנים העיקריים באתר או למשל המילה&nbsp;</span><span dir="LTR">nike&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;(שהיא שם של חברה ליצור נעליים) תשמש במקרים רבים גם כמילת מפתח לחנות לממכר נעליים, כיוון שלרוב, גולשים המחפשים מילה זו הם לקוחות פוטנציאלים לחנות הנעליים.</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>למילת מפתח משמעות הן ביחס&nbsp;</span><span style={{fontSize: '16px', fontFamily: '"Arial",sans-serif'}}>לקידום האורגני&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>של האתר (</span><span dir="LTR" style={{fontSize: '16px'}}>SEO</span><span style={{fontFamily: '"Arial",sans-serif'}}>), <strong><u>בנייה נכונה של מילות המפתח באתר תקדם את האתר בערכים הנכונים והרלוונטיים עבורו</u></strong>.</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>והן ביחס&nbsp;</span><span style={{fontSize: '16px', fontFamily: '"Arial",sans-serif'}}>לקידום ממומן&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>(</span><span dir="LTR">ADS</span><span style={{fontFamily: '"Arial",sans-serif'}}>), המונחה באופן ישיר על ידי הגדרת&nbsp;</span><span style={{fontSize: '16px', fontFamily: '"Arial",sans-serif'}}>מילות מפתח</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>כל קידום מקצועי מתחיל מניתוח מילות המפתח הרלוונטיות - ראה עוד <a href="https://motto.co.il/Dictionaries/SEO/Values/KeywordAnalysis" style={{color:"#A8DDFE"}}>ניתוח מילות מפתח</a>.</span></p>
</div>)
},
    KeywordAnalysis:{
      name:"ניתוח מילות מפתח",
    text:(<div>
    <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><strong><u><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>ניתוח מילות מפתח</span></u></strong><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
    <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>לפני שמתחילים לקדם אתר בגוגל מומלץ מאוד לעשות&nbsp;</span><span style={{fontSize: '16px', fontFamily: '"Arial",sans-serif'}}>ניתוח מילות מפתח</span><span style={{fontFamily: '"Arial",sans-serif'}}>.</span></p>
    <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>משמעות הדבר לנתח את הערך של המילה: את התנועה שלה (</span><span style={{fontSize: '16px', fontFamily: '"Arial",sans-serif'}}>ווליום</span><span style={{fontFamily: '"Arial",sans-serif'}}>) את רמת הקושי (</span><span dir="LTR" style={{fontFamily: '"Arial",sans-serif'}}>Keyword Difficulty</span><span style={{fontFamily: '"Arial",sans-serif'}}>) להתקדם במילה זו ואת הרלוונטיות שלה למוצר אותו אנו מקדמים.</span></p>
    <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><strong><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>למה הניתוח משמעותי כל כך?</span></strong></p>
    <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>כי ללא ניתוח מקצועי ומקיף, אנחנו יכולים לעשות הרבה עבודה על מילים שלא יובילו אותנו ללקוחות הרלוונטיים מחד או שנעשה עבודה יקרה, ארוכה ומיותרת למרות שיכלנו להגיע ללקוחות בצורה הרבה יותר זולה ומהירה.</span></p>
    <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>נסתכל על הדוגמאות הבאות:</span></p>
    <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>אם למילה מסוימת או לביטוי מסוים, אין תנועה, לא יעזור לנו לקדם אתה כיוון שהיא לא תביא לנו תועלת, למשל אם אנו רוצים לקדם את האתר של המכולת ברחוב הממציא, הרי כל מי שיחפש את הביטוי "לקנות לחם ברחוב הממציא" – יהיה מאוד רלוונטי עבורו לראות את האתר של המכולת, אז למה שלא נקדם את הביטוי הזה?</span></p>
    <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>כי אף אחד כמעט לא כותב בגוגל "לקנות לחם ברחוב הממציא" – בשביל אחד שאולי יכתוב מתי שהוא את הביטוי הזה, נשקיע חצי שנה עבודה?</span></p>
    <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>דוגמה נוספת – אם אנחנו מקדמים אתר שמוכר ציוד לתינוקות ואנחנו רוצים לקדם את המוצר "כיסא בטיחות", האם ננסה לקדם אותו תחת המילה "כיסא" או "מושב" (מה שעקרונית נכון, כיסא בטיחות הוא סוג של כיסא!)<br /> הרבה מאוד אנשים מחפשים ביטויים אלו אבל רובם לא מחפשים כיסא בטיחות אלא סתם כיסא רגיל, כיסא משרדי, מושב לאופניים, מושב לגור בו או מושב זקנים ועוד<br /> יתכן מאוד שיהיה עדיף לקדם את הביטוי "כיסא בטיחות" או "מושב בטיחות" שמתייחס לחיפושים הרלוונטיים ביותר עבורנו.<br />&nbsp;דוגמה זו רלוונטית שבעתיים כשמדובר על קידום ממומן, אבל לאו דווקא.</span></p>
    <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>(הערה: הדוגמה האחרונה להמחשה בלבד ומשתנה בין כל ביטוי לביטוי, ואינה תמיד מדד מוחלט, למשל הביטוי קפה הוא בעל תנועה רבה יותר לעין ערוך מהביטוי "קפה שחור" (יותר מ פי 20) מחד ומאידך הקושי בקידומו נמוך מהקושי בקידום לביטוי קפה שחור, יתכן ובמקרה זה כן נרצה להשקיע יותר מאמצים בביטוי קפה גם כשאנו באים למכור קפה שחור מכיוון שחלק גדול מלקוחותינו מחפשים ערך זה, ובמיוחד כשמדובר על קידום אורגני)</span></p>
    <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>לכן בשלב הראשון, כשאנו בונים תוכנית לקידום, הן אם זה&nbsp;</span><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>קידום אורגני&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>(</span><span dir="LTR" style={{fontSize: '19px'}}>SEO</span><span style={{fontFamily: '"Arial",sans-serif'}}>) והן אם זה&nbsp;</span><span style={{fontSize: '16px', fontFamily: '"Arial",sans-serif'}}>קידום ממומן</span><span style={{fontFamily: '"Arial",sans-serif'}}>, אנחנו צריכים <strong>לנתח את מטרות הקידום ואת כלל המילים הרלוונטיות</strong>, לבדוק את התנועה של כל מילה ואת הקושי של הקידום עבור מילת מפתח זו</span></p>
    <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>רק לאחר ניתוח מקצועי, נוכל לבנות <strong>מאגר של מילות מפתח&nbsp;</strong>שהינו גם רלוונטי, גם אפקטיבי וגם משתלם ביחס ליעדי הפרוייקט, התקציב והזמן שהוגדר לו.</span></p>
    <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><strong><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>איך עושים זאת?</span></strong></p>
    <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>מבחינה מעשית אנחנו נתחיל (כמובן לאחר שלמדנו והכרנו את המוצר או העסק) בבניית מאגר של מילות מפתח שרלוונטיות לתחום, (מומלץ לפחות 70) נבדוק את הרלוונטיות של כל אחת ואת השווי הכלכלי שלה לבעל העסק ומשם נעבור לבדיקה המקצועית אותה נבצע בעזרת כלי מדידה להערכת מילות המפתח, כאשר בשלב הראשון מקובל להתחיל עם 10 מילים נבחרות לקידום</span></p>
    <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>אחד הכלים החזקים ביותר לתחום זה הוא האתר- &nbsp;</span><strong><span dir="LTR" style={{fontSize: '16px'}}>&nbsp;</span></strong><strong><span dir="LTR" style={{fontSize: '16px'}}>semrush</span></strong><span style={{fontSize: '16px', fontFamily: '"Arial",sans-serif'}}>&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>שנותן לנו כלים רבים לקידום אתרים מקצועי.</span></p>
    <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>בכתובת:&nbsp;</span></p>
    <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><a href="https://www.semrush.com/analytics/keywordoverview/?q=%D7%91%D7%99%D7%AA+%D7%AA%D7%95%D7%9B%D7%A0%D7%94&db=il" style={{color:"#A8DDFE"}} target='_blank'><span dir="LTR">https://www.semrush.com/analytics/keywordoverview/?q=%D7%91%D7%99%D7%AA+%D7%AA%D7%95%D7%9B%D7%A0%D7%94&amp;db=il</span></a></p>
    <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>נוכל למצא כלי למדידת מילות מפתח שמספק לנו פרמטרים רבים על המילה הרלוונטית ועוד.</span></p>
    <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
    <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>הדברים האמורים כאן נכונים <strong><u>הן לקידום אורגני והן לקידום ממומן</u></strong>, כל אחד לפי ההתאמה הרלוונטית לגביו.</span></p>
  </div>
)
},
WebsiteOrWebpage:{
    name:"קידום אתר או קידום עמוד",
    text:(<div style={{color:"white"}}>
    <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>קידום אתר או קידום עמוד</span></p>
    <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span dir="LTR">Web-page &nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;או&nbsp;</span><span dir="LTR">&nbsp;web-site</span></p>
    <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>כשאנו באים לקדם אתר יש להבחין בן&nbsp;</span><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>קידום לאתר&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>ל</span><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>קידום לדף מסויים</span><span style={{fontFamily: '"Arial",sans-serif'}}>,</span></p>
    <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>המדרג של גוגל אומנם כולל מדרג לאתר אבל ה"אנד פוינט"&nbsp;</span><span dir="LTR">end point)&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>) של ההקידום הוא הדף הספציפי שכן גוגל מציג את&nbsp;</span><span style={{fontSize: '16px', fontFamily: '"Arial",sans-serif'}}>התוצאות לפי רלוונטיות&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>ומדרג (סמכות, authority )  של הדף (להוציא מקרים מסויימים, עליהם נדבר בהמשך)</span></p>
    <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>למשל אם נעשה&nbsp;</span><span style={{fontSize: '16px', fontFamily: '"Arial",sans-serif'}}>חיפוש בגוגל</span><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;תחת המילה "אינסטלטור" – במקום הראשון (נכון להיום) יופיע&nbsp;</span><span style={{fontSize: '16px', fontFamily: '"Arial",sans-serif'}}>האתר&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>"מדרג", אע"פ שהאתר מדרג בפני עצמו אינו מיועד, בדווקא, לאינסטלטורים&nbsp;</span><span style={{fontSize: '16px', fontFamily: '"Arial",sans-serif'}}>והרלוונטית של כלל האתר</span><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;לתחום זה הינה נמוכה, אבל הדפים הרלוונטיים לתחום האינסטלטורים מקודמים בצורה מסיבית במילת מפתח זו מחד והם רלוונטיים מאוד למחפשים תחום זה.</span></p>
    <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>יתרה מזאת, גם במקום השני בדפי&nbsp;</span><span style={{fontSize: '16px', fontFamily: '"Arial",sans-serif'}}>החיפוש של גוגל</span><span style={{fontFamily: '"Arial",sans-serif'}}>, מופיע ה</span><span style={{fontSize: '16px', fontFamily: '"Arial",sans-serif'}}>אתר</span><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;של מדרג, כאילו הם שני דפים מתחרים,&nbsp;</span></p>
    <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>במקום הראשון הופיע הדף של &nbsp;אינסטלטורים בתל-אביב ובמקום השני מופיע הדף הכללי של חיפוש אינסטלטורים במדרג</span></p>
    <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
    <img src="http://www.easydone.online/uploads/fls/00fc047a-02cf-4be2-b427-b53090d2d31d.png" width="100%"/>
    <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
    <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><strong><span style={{fontFamily: '"Arial",sans-serif'}}>המשמעות הראשונה של זה היא שנתן לקדם דפים שונים תחת מילות מפתח שונות ותחת תחומי רלוונטיות שונה</span></strong><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;מחד ומאידך כשאנו באים לקדם אתר, &nbsp;לפני שאנו מתחילים לקדם אנחנו צריכים לנתח את מטרות הקידום, את הדפים השונים באתר ואת הרלוונטיות של כל דף בהתאם למטרות שהצבנו לקידום, בהתאם לכך אנחנו <strong>נתמקד בעיקר בדפים הרלוונטיים- נעשה את האופטימיזציה של הדפים הרלוונטיים, ואת הקישורים המתאימים</strong>.</span></p>
    <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>לדוגמה- אתר המשרת חנות לממכר אופניים, מכיל דף רלוונטי למבצע חד פעמי למשך שבועיים, לדגם מסויים שנשאר במלאי, ועד גמר המלאי-<br />&nbsp;אם &nbsp;נבצע את הקידום לדף זה, המשמעות היא שגם (ובעיקר) לאחר סיום המבצע, לקוחות המחפשים את החנות שלנו יגיעו לדף זה, מה שיכול להיות לפעמים מבוזבז ואף מזיק, מאידך,&nbsp;</span><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>אם נכוון את הקידום האורגני של האתר לדף&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>המכירות הכללי או אפילו לדף מבצעים כללי המפנה ליתר הדפים או המשתנה בהתאם למבצעים הרלוונטים&nbsp;</span><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>ננצל את קידומו של הדף&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>גם לאחר סיום המבצע!</span></p>
  </div>)
},

url:{name:"כתובת אתר – URL",
text:(<div style={{color:"white"}}>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><strong><u><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>כתובת אתר –&nbsp;</span></u></strong><strong><u><span dir="LTR" style={{fontSize: '19px'}}>URL</span></u></strong></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>כל מי שעוסק בקידום אתרים (</span><span dir="LTR">SEO</span><span style={{fontFamily: '"Arial",sans-serif'}}>) צריך להכיר בצורה טובה את המבנה של&nbsp;</span><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>כתובת האתר</span><span style={{fontFamily: '"Arial",sans-serif'}}>, ה&nbsp;</span><span dir="LTR" style={{fontSize: '19px'}}>URL</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>אפשר לחלק כל כתובת אתר ל 5 מרכיבים:</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>הפרוטוקול</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>סב-דומיין</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>דומיין</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span dir="LTR" style={{fontSize: '19px'}}>TLD</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span dir="LTR" style={{fontSize: '19px'}}>slug</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>ניקח לדוגמה את הכתובת&nbsp;</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span dir="LTR">https://www.motto.co.il/OfferCourses</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span dir="LTR">HTTP &nbsp;&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;או&nbsp;</span><span dir="LTR">HTTPS&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;זה ה</span><span style={{fontSize: '16px', fontFamily: '"Arial",sans-serif'}}>פרוטוקול&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>(רק ש&nbsp;</span><span dir="LTR">HTTPS</span><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;זה&nbsp;</span><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>לאתרים מאובטחים&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>הכוללים&nbsp;</span><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>הרשאת&nbsp;</span><span dir="LTR" style={{fontSize: '19px'}}>SSH</span><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>כלומר&nbsp;</span><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>הצפנת הנתונים&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>מקצה לקצה, ו&nbsp;</span><span dir="LTR">HTTP</span><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;זה לאתרים לא מאובטחים)</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>לאחר הפרוטוקול יופיעו נקודותיים ושני קווים נטויים&nbsp;</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>לאחר מכאן – יופיע ה "</span><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>תחום משנה</span><span style={{fontFamily: '"Arial",sans-serif'}}>" (</span><span dir="LTR">sub domain</span><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>, תת תחום, סב דומיין או תת דומיין</span><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;– כולם הם אותה המשמעות)<br />&nbsp;ברוב המקרים ה-</span><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;תחום משנה</span><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;הוא&nbsp;</span><span dir="LTR">WWW</span><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;אבל זה אינו מחוייב, באתרים גדולים נשתמש בו לפעמים לחלק בן וורסיות של האתר בשפות שונות ועוד</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>אבל ל-</span><span style={{fontFamily: '"Arial",sans-serif'}}>תחום משנה יש משמעות אחרת מאשר עוד דף, מנועי החיפוש רואים את הדפים הנמצאים תחת תחום משנה שונה,&nbsp;</span><span style={{fontSize: '16px', fontFamily: '"Arial",sans-serif'}}>כאתרים נפרדים &nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>או כ "ישות נפרדת"&nbsp;</span><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>מהאתר הראשי.</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>אחר-כך, כמובן ה&nbsp;</span><span style={{fontSize: '16px', fontFamily: '"Arial",sans-serif'}}>דומיין&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>עצמו (במקרה שלנו זה&nbsp;</span><span dir="LTR">motto</span><span style={{fontFamily: '"Arial",sans-serif'}}>) שהוא בעצם שם התחום, והוא משמעותי הם מבחינת "יחסי הציבור" של האתר והן&nbsp;</span><span style={{fontSize: '16px', fontFamily: '"Arial",sans-serif'}}>למנועי חיפוש&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>שונים, ולכן מומלץ לבחור דומיין שכולל את&nbsp;</span><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}><a href="https://motto.co.il/Dictionaries/SEO/Values/keywords" style={{color:"#A8DDFE"}}>מילות המפתח</a>&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>החשובות לאתר.</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>אחר כך – "</span><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>הרמה הממונה</span><span style={{fontFamily: '"Arial",sans-serif'}}>" או&nbsp;</span><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>הרמה העליונה&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>של הדומיין (</span><span dir="LTR" style={{fontSize: '19px'}}>TLD- top level domain</span><span style={{fontFamily: '"Arial",sans-serif'}}>)<br /> והם מתחמים אזורים או תחומי תוכן<br />&nbsp;</span><span dir="LTR">com, net</span><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;ועוד<br />&nbsp;</span><span dir="LTR">co.il</span><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;– מגדיר אתר ישראלי<br />&nbsp;</span><span dir="LTR">org.il</span><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;– מיועד לארגונים ישראלים<br />&nbsp;</span><span dir="LTR">gov.il</span><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;מיועד לאתרי הממשל בארץ ועוד</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>דף הבית&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>של&nbsp;</span><span style={{fontSize: '16px', fontFamily: '"Arial",sans-serif'}}>האתר</span><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;יסתיים בדרך כלל &nbsp;בשלב זה</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>אבל כל דפי המשנה של האתר צריכים נתיב המוביל למיקום שלהם או לתקייה בו הם נמצאים, ולצורך כך יופיע ה-&nbsp;</span><span dir="LTR">slug</span><span style={{fontFamily: '"Arial",sans-serif'}}>,&nbsp;</span><span style={{fontSize: '16px', fontFamily: '"Arial",sans-serif'}}>הנתיב של הדף&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>הרלוונטי מלווה בקו נטוי (ברמה אחת או במספר רמות) המצביע על ה"מסלול" אל הדף הרלוונטי</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: '16px', fontFamily: '"Arial",sans-serif'}}>לנתיב הדף&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>או לשם הדף בנתיב יש משמעות רבה הן מבחינת מנועי החיפוש והן ככרטיס הביקור של הדף</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>ולכן:<br />&nbsp;מומלץ לכלול&nbsp;</span><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>מילות מפתח רלוונטיות&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>לדף, מופרדות במקוו למשל:</span></p>
  <p style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'left', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}>https://www.motto.co.il/offer-courses</p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>(ולא כמו בדוגמה לעייל)<br />&nbsp;עדיף לא לחזור על מילה פעמיים וכן להמנע משימוש במילות קישור (כגון גם\או\אולי וכו') בתוך ה&nbsp;</span><span dir="LTR" style={{fontSize: '19px'}}>URL</span><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
</div>)},

redirect:{name:"הפניה מחדש (Redirect)",
text:(<div style={{color:"white"}}>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><strong><u><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>הפניה מחדש (</span></u></strong><strong><u><span dir="LTR" style={{fontSize: '19px'}}>Redirect</span></u></strong><strong><u><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>)</span></u></strong></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>במקרים מסויימים נרצה לעשות סדר חדש באתר לשנות דפים או את המבנה הכללי ולעביר דפים לנתיב אחר באתר שלנו.</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>ביטול דף נוכחי ויצירת כתובת חדשה יכולה להיות בעל משמעות כפולה:</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>מבחינת&nbsp;</span><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>מנועי החיפוש&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>– גוגל מדרג את הדף הספציפי (ראה ערך "</span><span style={{fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'white'}}>&nbsp;</span><span style={{fontSize: '15px', fontFamily: '"Arial",sans-serif'}}><a href="https://motto.co.il/Dictionaries/SEO/Values/WebsiteOrWebpage" style={{color:"#A8DDFE"}}>קידום אתר או קידום עמוד</a></span><span style={{fontFamily: '"Arial",sans-serif'}}>") ולכן העברת התוכן לכתובת, לנתיב אחר תגרום לנו לאבד את המדרג (ה</span><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>סמכות&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>של הדף,&nbsp;</span><span dir="LTR" style={{fontSize: '19px'}}>authority</span><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;) שכבר "רכשנו" ונאלץ להתחיל לקדם אותו מחדש.<br />&nbsp;מאידך, כל הקישורים לדף לא יפנו אותנו למקום הנכון אלא לדף 404 וממילא לא יסייעו לנו לקידום הדף אלא להפך.</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>כמו כן כל ה</span><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>גולשים</span><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;שיגיעו לאתר שלנו דרך קישורים אלו, יופנו לדף 404, דבר שיתן להם את התחושה שהאתר שלנו שבור ולא מקצועי.</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>לכן במקרים כאלו, בהם אנו נאלצים לשנות כתובת (נתיב) של דף חשוב להקפיד לעשות לכתובת הישנה "</span><span style={{fontSize: '16px', fontFamily: '"Arial",sans-serif'}}>הפניה מחדש</span><span style={{fontFamily: '"Arial",sans-serif'}}>" אל הכתובת החדשה</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>במקרה זה, מי שיזין את הנתיב (ה</span><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>&nbsp;</span><span dir="LTR" style={{fontSize: '19px'}}>URL</span><span style={{fontFamily: '"Arial",sans-serif'}}>) הישן יופנה אוטומטית&nbsp;</span><span style={{fontSize: '16px', fontFamily: '"Arial",sans-serif'}}>לכתובת החדשה</span><span style={{fontFamily: '"Arial",sans-serif'}}>.</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>זה מועיל הן מצד ה</span><span style={{fontSize: '16px', fontFamily: '"Arial",sans-serif'}}>משתמשים</span><span style={{fontFamily: '"Arial",sans-serif'}}>, להם נשארת&nbsp;</span><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>חווית משתמש</span><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;טובה וחלקה</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>והן מצד&nbsp;</span><span style={{fontSize: '16px', fontFamily: '"Arial",sans-serif'}}>מנועי החיפוש</span><span style={{fontFamily: '"Arial",sans-serif'}}>, שמזהים את הדך החדש כ "ממלא המקום" של הדף הישן וממילא כל המדרג (</span><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>הסמכות,&nbsp;</span><span dir="LTR" style={{fontSize: '19px'}}>authority</span><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>) של הדף יועבר לדף החדש ובזה הוא ישמור על מקומו במנועי החיפוש.</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>על מנת לבצע&nbsp;</span><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>הפניה מחדש (</span><span dir="LTR" style={{fontSize: '19px'}}>re-direct</span><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>)&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>אנחנו צריכים שלושה נתונים:<br />&nbsp;1. את ה&nbsp;</span><span dir="LTR" style={{fontSize: '19px'}}>url</span><span dir="LTR">&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;הישן , אותו אנו מעוניינים להפנות מחדש.<br /> 2. את סוג ההפניה.<br />&nbsp;3. את ה&nbsp;</span><span dir="LTR" style={{fontSize: '19px'}}>url</span><span dir="LTR">&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;החדש, אליו אנו רוצים להפנות את הגולשים.</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>ישנם כמה סוגי הפניות, הנפוצות ביותר הם 301 שמשמעותה הפניה קבועה ו – 302 שמשמעותה הפניה זמנית, אם אנחנו רוצים לשדרג את הדף ולצורך זה אנחנו מפנים זמנית את הגולשים לדף אחר עד לאחר הטיפול בו.</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>בדרך כלל אנחנו נשתמש&nbsp;</span><span style={{fontSize: '16px', fontFamily: '"Arial",sans-serif'}}>בהפניה קבועה</span><span style={{fontFamily: '"Arial",sans-serif'}}>, 301</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span dir="LTR">&nbsp;</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>ניתן לבצע באתר אחד מספר רב של הפניות מחדש למספר רב של דפים, הדבר לא מפריע לגוגל מחד ומאידך שומר על הזרימה החלקה של האתר.</span></p>
  <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>אבל יש להימנע מהפניה מרובת שלבים כלומר להפנות לכתובת, וממנה לכתובת אחרת וכו', הדבר מאט את&nbsp;</span><span style={{fontSize: '16px', fontFamily: '"Arial",sans-serif'}}>קצב הטעינה&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>של הדפים ויתכן ואחר כמה הפניות, גוגל יפסיק לקשר לדף הרצוי.</span></p>
</div>)},

toxic_links:{name:"קישורים רעילים (Toxic links)",
text:(<div style={{color:"white"}}>
<div>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>קישור רעיל –</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>קישור מאתר בעל ערך נמוך מאוד או בעל תוכן שלילי, או קישור הבנוי כקישור פקטיבי.</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>קישור כזה יכול במקרים רבים להוריד את דרוג האתר (במקום להעלות אותו) ולגרום לנסיגה במנועי החיפוש.</span></p>
      </div>
</div>)},

canonical_tag:{name:"תג קנוני (canonical tag)",
text:(<div style={{color:"white"}}>
<p>&nbsp;<span style={{fontFamily: 'Arial, sans-serif'}}>תג קנוני (</span>canonical tag<span style={{fontFamily: 'Arial, sans-serif'}}>) – הוא תג המלמד את מנועי החיפוש להגדיר כתובת עיקרית לתוכן משוכפל.</span>&nbsp;</p> <p><span style={{fontFamily: 'Arial, sans-serif'}}>אם יש לנו שני דפים עם תכנים דומים, מנועי החיפוש יכולים לראות בזה העתקה של תוכן ולהוריד את הדירוג של הדף או לא לזהות מהי הכתובת העיקרית של התוכן, וממילא "לחלק" את הפוטנציאל של הדף.</span>&nbsp;</p> 
<p><span style={{fontFamily: 'Arial, sans-serif'}}>בשביל למנוע תופעה זו מוסיפים תג קנוני </span>&nbsp;</p> <p><span style={{fontFamily: 'Arial, sans-serif'}}>דוגמה לתג קנוני –</span>&nbsp;</p> <p style={{textAlign: 'left'}}>&nbsp;&lt;link rel="canonical" href="https://motto.co.il/Promotion" / &gt;&nbsp;</p> <p><span style={{fontFamily: 'Arial, sans-serif'}}>את התג יש להוסיף ב </span> head <span style={{fontFamily: 'Arial, sans-serif'}}>של דף ה </span>html&nbsp;</p> <p style={{textAlign: 'right'}}>&nbsp;&nbsp;&nbsp;</p>
</div>)}





}

export default keysObject