import React, {useState} from 'react'
import Fade from 'react-reveal/Fade';
import Button from '../../../../common/src/components/Button';
import axios from 'axios'
import { ComponentMultiplier } from 'dugoly-nc-library'

const Form=()=>{
    const [Name,setName]=useState("")
    const [Email,setEmail]=useState("")
    const [Phone, setPhone]=useState("")
    // const [Message, setMessage]=useState("")
    const [Terms,setTerms]= useState(false)
    const [SiteName,setSiteName]=useState("")
    const [Content,setContent]=useState("")
    const [PhoneOnSite, setPhoneOnSite]=useState("")
    const [ EmailOnSite, setEmailOnSite]=useState("")
    const [ NumSubpages, setNumSubpages]=useState(1)

    const[OpenForm,setOpenForm]=useState(0)

    const handleChange=(e)=>{
     
    
      switch(e.target.name){
    case ("name"):
    setName(e.target.value)  
    
    break
    
    case ("email"):
    setEmail(e.target.value)  
    
    break
    
    case ("phone"):
    setPhone(e.target.value)  
    
    break
    
    case ("terms"):
    setTerms(e.target.checked)  
    // console.log("Terms:  ",Terms)
    break
    
    case ("site_name"):
    setSiteName(e.target.value)  
    
    break
    
    case ("content"):
    setContent(e.target.value)  
    
    break
    
    case ("phone_on_site"):
      setPhoneOnSite(e.target.value)  
      
      break

      case ("email_on_site"):
      setEmailOnSite(e.target.value)  
      
      break

      case ("num_subpages"):
        setNumSubpages(e.target.value)  
        
        break
      }
    }
     
    const form1=( <form dir="rtl" style={{
      color:"white",
      maxWidth: '500px',
    }}>
       <fieldset>
       <legend style={{color:"white"}}>אתר רספונסיבי לעסק- מילוי פרטים אישיים</legend>
       <center>
    
        שם מלא: <br/>
        <input  type="text" name="name"  value={Name} onChange={handleChange}/>
    <br/>
        <br/>אימייל:<br/>
        <input  type="text" name="email"  value={Email} onChange={handleChange} />
    <br/>
        <br/> טלפון:<br/>
        <input  type="text" name="phone"  value={Phone} onChange={handleChange} />
    
        {/* <h2>במה נוכל לעזור?</h2>
        <textarea 
       rows="4" cols="30"
        name="message"  value={Message} onChange={handleChange}
        /> */}
        <br/>
        <br/>
        <label>
        
          <input
            name="terms"
            type="checkbox"
          
            onChange={handleChange} 
            />אני מסכים לתנאי המבצע&nbsp;&nbsp; </label>
            <a href="/website-without-obligation/operation-terms" style={{color:"#A8DDFE"}}>לקריאת תנאי המבצע לחץ כאן </a>
        
    <br/>
    <Button  title="המשך לשלב הבא"  onClick={()=>{
    if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Email)))
    alert("האימייל לא תקין- נא הכנס אימייל תקין!")
    else
    {
      if(!Terms){alert("יש לאשר את תנאי המבצע")}
      else{
        window.scrollTo({
          top: 0,
          behavior: "smooth"
      });
      axios({
    method:"post",
    url:" https://us-central1-sender-4330e.cloudfunctions.net/app/send_email_client",
    data:{to:"omershiran@gmail.com",from:"omershiran@gmail.com",subject:"הודעה חדשה באתר מוטו בנושא אתר ללא תשלום טופס 1:  ",text:(Name+"  "+Email+"  "+Phone)}
    }).then((v)=>{
      
      if(v.data===1001){setOpenForm(4)}
    else{
      
      setOpenForm(2)}
    }).
      catch(e=>{ 
        
        setOpenForm(2)})
        setOpenForm(3)
    }}}}/></center>
    </fieldset>
    </form>)
    const form2=( <form dir="rtl" style={{
      color:"white",
      maxWidth: '500px',
    }}>
       <fieldset>
       <legend style={{color:"white"}}>אתר רספונסיבי לעסק- פרטי האתר</legend>
       <center>
    
        שם האתר (שם העסק): <br/>
        <input  type="text" name="site_name"  value={SiteName} onChange={handleChange}/>
    <br/>
        <br/>תוכן האתר (יש להכניס את כל המלל שברצונך שיופיע באתר, מומלץ להכניס הרבה מלל!, כולל הסברים על פעילות העסק, דוגמאות, ממליצים ועוד):<br/>
        <textarea 
       rows="10" cols="30"
        name="content"  value={Content} onChange={handleChange}
        />
         <br/> מספר דפי המשנה שיופיעו באתר (הכוונה לדפים נוספים, מלבד דף הבית):<br/>
        <input  type="number" name="num_subpages"  value={NumSubpages} onChange={handleChange} />
    
    <br/>
    פרטי יצירת קשר שיופיעו באתר:
        <br/> טלפון:<br/>
        <input  type="text" name="phone_on_site"  value={PhoneOnSite} onChange={handleChange} />
    
        <br/> אימייל:<br/>
        <input  type="text" name="email_on_site"  value={EmailOnSite} onChange={handleChange} />


        <br/>
    <br/>
    <Button  title="המשך לשלב הבא"  onClick={()=>{
     
    if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(EmailOnSite)))
    alert("האימייל לא תקין- נא הכנס אימייל תקין!")
    else
    {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
       const massege= "site name: "+SiteName+"\n" +"Content:  "+Content+'\n'+"NumSubpages: "+NumSubpages+"\n"+"PhoneOnSite: "+PhoneOnSite+"\n"+"EmailOnSite: "+EmailOnSite
      axios({
    method:"post",
    url:" https://us-central1-sender-4330e.cloudfunctions.net/app/send_email_client",
    data:{to:"omershiran@gmail.com",from:"omershiran@gmail.com",subject:"הודעה חדשה באתר מוטו בנושא אתר ללא תשלום טופס 2:  ",text:(massege)}
    }).then((v)=>{
      
      if(v.data===1001){setOpenForm(5)}
    else{
      
      setOpenForm(2)}
    }).
      catch(e=>{ 
        
        setOpenForm(2)})
        setOpenForm(3)
    }}}/></center>
    </fieldset>
    </form>)
    let SubpageObjArray=[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}]
    const SubpageForm=({SubpageObjArray,i})=>{
      const [SubpageTitle, setSubpageTitle]=useState("")
      const [SubpageText, setSubpageText]=useState("")
      const handleChange=(e)=>{
        switch(e.target.name){
          case("subpage_name"):
          setSubpageTitle(e.target.value)
          SubpageObjArray[i].title=e.target.value
          break

          case("subpage_text"):
          setSubpageText(e.target.value)
          SubpageObjArray[i].text=e.target.value

          break
        }
      }
      return(<form dir="rtl" style={{
        color:"black",
        maxWidth: '500px',
      }}>
         <fieldset>
         <legend style={{color:"white"}}>אתר רספונסיבי לעסק- פרטי דף משנה</legend>
         <center>
      
          שם הדף: <br/>
          <input  type="text" name="subpage_name" value={SubpageTitle} onChange={handleChange}/>
      <br/>
      <br/>תוכן הדף (יש להכניס את כל המלל שברצונך שיופיע בדף, מומלץ להכניס הרבה מלל!, כולל הסברים על פעילות העסק, דוגמאות, ממליצים ועוד):<br/>
        <textarea 
       rows="10" cols="30"
        name="subpage_text"  value={SubpageText} onChange={handleChange}
        />
         
          <br/>
          
      <br/></center>
      </fieldset>
      </form>)

    }
    const form3=( <form dir="rtl" style={{
      color:"white",
      maxWidth: '500px',
    }}>
       <fieldset>
       <legend style={{color:"white"}}>אתר רספונסיבי לעסק- פרטי דפי המשנה</legend>
       <center>
         <ComponentMultiplier 
         ComponentToMultiply={SubpageForm} 
         ComponentProps={{SubpageObjArray:SubpageObjArray}} 
         AbsoluteNumber={NumSubpages} 
         index={true} 
         generalContainerStyle={{maxWidth:"470px"}}   />
    
    <Button  title="סיום ההזמנה"  onClick={()=>{
    //  console.log("SubpageObjArray:  ",SubpageObjArray)
     let SubpageString=""
     SubpageObjArray.forEach((o)=>{SubpageString+="name: "+o.title+"  text:  "+o.text+"   \n"})
     window.scrollTo({
      top: 0,
      behavior: "smooth"
  });
      axios({
    method:"post",
    url:" https://us-central1-sender-4330e.cloudfunctions.net/app/send_email_client",
    data:{to:"omershiran@gmail.com",from:"omershiran@gmail.com",subject:"הודעה חדשה באתר מוטו בנושא אתר ללא תשלום טופס 3:  ",text:SubpageString}
    }).then((v)=>{
      
      if(v.data===1001){setOpenForm(1)}
    else{
      
      setOpenForm(2)}
    }).
      catch(e=>{ 
        
        setOpenForm(2)})
        setOpenForm(3)
    }}/></center>
    </fieldset>
    </form>)
    
    const AfterProperDelivery=(<h2 style={{color:"white"}}>ההודעה נשלחה בהצלחה</h2>)
    const AfterUnProperDelivery=(<h2 style={{color:"white"}}>תקלה במשלוח ההודעה, ההודעה לא נשלחה,<br/> אנא נסה שנית במועד מאוחר יותר</h2>)
    
    const DirectMessageAfterSending=(<h2 style={{color:"white"}}>ההודעה נשלחת אנא המתן...</h2>)
    let formContent
    // =(OpenForm===0)?form1:(OpenForm===1)?AfterProperDelivery:(OpenForm===3)?DirectMessageAfterSending:AfterUnProperDelivery
    switch(OpenForm){
      case(0):
      formContent=form1
      break
      
      // case(0):
      // formContent=form1
      // break
        
      case(1):
      formContent=AfterProperDelivery
      break
        
      case(2):
      formContent=AfterUnProperDelivery
      break
        
      case(3):
      formContent=DirectMessageAfterSending
      break
        
      case(4):
      formContent=form2
      break
        
      case(5):
      formContent=form3
      break
       
    }
    
    return(<div>
        <Fade up delay={100}>
            <h2 style={{color:"white"}}>אתר רספונסיבי לעסק?</h2>
            <h1 style={{color:"white"}}>רק תשאיר פרטים - את היתר נעשה אנחנו!</h1>
            <h2 style={{color:"white"}}>מבצע - אתר ללא התחייבות!</h2>
         
         {formContent}
         
           </Fade>
    </div>

    )
}
export default Form