import React from 'react'
import keysObject from '../../keysObject'

const Text=()=>{
      return (
        <div style={{color:"white"}}>
       <h1 style={{textAlign: 'right'}}>מילון SEO <br/> מפתח מושגים לקידום אתרים</h1>
       <h5>לחץ על המושג הרלוונטי לקבלת הסבר</h5><br/>
       {Object.keys(keysObject).map((key)=>{
  return(<div>
    <a href={"/Dictionaries/SEO/Values/"+key} style={{color:"#A8DDFE"}}><u>{keysObject[key].name}</u></a>
</div>
  )
})}

           </div>
      );
    }
export default Text