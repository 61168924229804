import React from 'react';
import { Link } from 'react-router-dom';
import figure from '../../../../common/src/assets/image/404.svg';
import  './not-found-page.scss'
import Navbar from '../../Navbar';
import Sticky from 'react-stickynode';
import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from '../../appModern.style';


const NotFoundPage = () => (
  <div>
  
      
        <AppWrapper dir="rtl">
  <div className="page-not-found">
    
    <div className="error-404-page-container">
      <div className="error-404-page-text">
        <div className="error-404-page-content">
          <div className="error-404-heading">
            אופס, הדף לא נמצא...
        </div>
          <div className="error-404-info">
          הדף שאתה מחפש לא קיים או שהוסר באופן זמני, עמך הסליחה.
        </div>
          <div className="error-404-button">
            <Link to="/" className="link_404" > חזור לדף הבית </Link>
          </div>
        </div>
      </div>
      <div className="error-404-page-image">
        <div className="error-404-image">
          <img src={figure} alt="404" />
        </div>
      </div>
    </div>
  </div >
  </AppWrapper>
  </div>
);

export default NotFoundPage;
