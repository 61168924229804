import React from 'react'


export default ()=>{
    return (
      <div style={{color:"white"}}><br/><br/><br/><br/>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><strong><span style={{fontSize: '19px', fontFamily: '"Arial",sans-serif'}}>גיוס משקיעים לאפליקציה\ לסטארטאפ</span></strong></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>הליך גיוס משקיעים הינו הליך ארוך, בשלב הראשון יש להכין את הפרוייקט \ הסטארטאפ להליך זה הן מבחינת תכנון מאזן כלכלי כלומר מה הן ההוצאות הנדרשות להצלחת הפרוייקט, מהן הרווחים הצפויים ממנו כמה כסף כבר הושקע בפרוייקט, מהם הנכסים הקיימים שלו, מה יעד הגיוס וכו' (ניתן לקבל מדריך לכתיבת תוכנית עסקית בקישור:&nbsp;</span><a href="https://www.targo-consulting.co.il/%D7%AA%D7%95%D7%9B%D7%A0%D7%99%D7%AA-%D7%A2%D7%A1%D7%A7%D7%99%D7%AA/" target="_blank"><span dir="LTR" style={{color:"#A8DDFE"}}>https://www.targo-consulting.co.il/%D7%AA%D7%95%D7%9B%D7%A0%D7%99%D7%AA-%D7%A2%D7%A1%D7%A7%D7%99%D7%AA</span><span style={{fontFamily: '"Arial",sans-serif'}}>/</span></a><span style={{fontFamily: '"Arial",sans-serif'}}>)<br />והן מבחינה שיווקית</span><span style={{fontFamily: '"Arial",sans-serif'}}>.</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>יש לציין כי לא נסקור במאמר זה את כל הדרכים הקיימות לגיוס משקיעים אלא את השיטות שאנו פועלים בהם ב- מוטו ישראל (</span><a href="https://motto.co.il/"><span dir="LTR" style={{color:"#A8DDFE"}}>https://motto.co.il</span><span style={{fontFamily: '"Arial",sans-serif'}}>/</span></a><span style={{fontFamily: '"Arial",sans-serif'}}>)</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>כשאנו באים לחפש משקיע, יש לחלק בן שני סוגי משקיעים, משקיע ישיר ומשקיע אופציוני:</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>משקיע ישיר, הינו משקיע שמקבל תמורת השקעתו חלק בבעלות על ה הסטארט-אפ או על הפרוייקט\ הנכס והוא בעל זכות ניהול שווה ליזם ביחסי השפעה בהתאם לאחוז השקעתו ו\או אחוז בעלותו בפרוייקט</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>משקיע ישיר ניתן לגייס הן ע"י היזם (חברים\ משפחה\ או ע"י שיווק הרעיון), והן ע"י מוטו, <br /> יתרונות לגיוס ע"י היזם עצמו:<br /> דרישות קדם גמישות יותר,<br />הסכמי יחסי שותפות גמישים יותר</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>המקרה הראשון והבולט ביותר בו נעדיף משקיע ישיר הוא כאשר היזם מחפש מישהו שגם יטול חלק בניהול והרצת הפרוייקט</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>יש לציין שלא כל משקיע ישיר בהכרח מעוניין לזה ומתאים לזה</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>(מי שכן מחפש משקיע- שותף, או שותף שאינו משקיע, אפשר לעשות חיפוש בגוגל "שותף טכנולוגי או עסקי" ולמצא המון גורמים בנושא)</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>משקיע אופציוני ( לא אופציונלי!)-הינו משקיע שלא מעוניין להיות חלק פעיל ביוזמה אלא רק ברווחים, בעצם המשקיע הישיר במקרה זה הוא מוטו ישראל, שנכנסים במקרה זה כחלק מהבעלים וכל הזכויות של המשקיע הינם כפי ההסכם שלו עם מוטו<br />לשיטה זו יתרונות רבים הן במחינת הגוף המגייס, הן מבחינת היזם והן מבחינת המשקיע</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>יתרונות לגוף המגייס- <br /> היכולת לגייס את המשקיעים בשלב מאוחר יותר ולאפשר תזרים מזומנים מוקדם<br /> היכולת לגבות עמלות גבוהות יותר ולנצל את העליה בשווי השוק שמגיעה לאחר שלבי הפיתוח<br />שמירה על איזון ניהולי</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>יתרונות ליזם- <br /> זמינות תקציבית גבוהה.<br /> ארגון ניהולי אחיד.<br />ללא סיכון באי מציאת משקיע</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>יתרונות למשקיע-<br /> פיקוח ניהולי של מוטו.<br />חוו"ד מקצועית לפני ההשקעה</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>בן בגיוס משקיע ישיר וכל שכן בגיוס השקעה אופציונית אנחנו דורשים לדעת שיש פוטנציאל משמעותי בהשקעה הן מבחינת רצינות היזם והן מבחינת הפוטנציאל הכלכלי שבעצם הרעיון</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>לכן אנחנו לא מתחייבים ליטול חלק בהליך גיוס המשקיעים אלא א"כ סוכם כן מראש , (ניתן לסכם בחוזה הפיתוח) ורק לאחר שלבי האפיון ובמקום שיש פוטנציאל משמעותי לפי שיקול דעתינו</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>כמו כן אנחנו ממליצים בד"כ להכניס משקיעים רק לאחר מימוש הון עצמי משמעותי (הון עצמי – הסכום שהיזם כבר השקיע בסטארט-אפ או שיש לו זמין לצורך הסטארט-אפ) הן כדי לא לאבד את מירב הזכויות במיזם והן כדי להראות רצינות היזם ובטחונו בפוטנציאל המיזם</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>אחוז הבעלות של היזם בכל אחד מהאופנים האלו משתנה כמובן בהתאם למהות הרעיון, אופי הניהול ושלב הכנסת המשקיעים.</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>עם זה, בתור קווים מנחים בלבד, נאמר כי על עצם הרעיון נותנים כ 5%, על הפיתוח והגיבוש המקצועי עוד כ 5% <br />עוד 90% מתחלקים כחצי חצי על הפיתוח הטכנולוגי מחד ועל הניהול והשיווק מאידך</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>לדוגמה יזם הגהה רעיון כולל גיבוש מקצועי, והערכה של הסכום הנדרש לביצוע היא 100000 ₪, והיזם מעוניין להשקיע 66,000 ₪ ואת יתר הסכום לגייס ע"י משקיעים</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>יחסי הבעלות במקרה זה יהיו כ 70% ליזם ו30% למשקיע</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>כאמור אלו הם קווים מנחים בלבד ואחוזי הבעלות בפועל משתנים לפי הנסיבות ולפי ההסכמים בן הצדדים</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
      </div>
    );
 
}