import React, { useState, useRef } from 'react';
import Fade from 'react-reveal/Fade';
import ScrollSpyMenu from '../../../common/src/components/ScrollSpyMenu';
import Scrollspy from 'react-scrollspy';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Icon } from 'react-icons-kit';
import { menu } from 'react-icons-kit/feather/menu';
import { x } from 'react-icons-kit/feather/x';
import { search } from 'react-icons-kit/feather/search';
import Logo from '../../../common/src/components/UIElements/Logo';
import Button from '../../../common/src/components/Button';
import Container from '../../../common/src/components/UI/Container';
import useOnClickOutside from '../../../common/src/hooks/useOnClickOutside';
import NavbarWrapper, { MenuArea, MobileMenu, Search } from './navbar.style';
import LogoImage from '../../../common/src/assets/image/appModern/logo-white.png';
import LogoImageAlt from '../../../common/src/assets/image/appModern/logo.png';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import { navbar } from '../../../common/src/data/AppModern';
import ContactUs from '../ContactUs'
import ContactUsForm from '../‏‏ContactUsFormLine'
// import Login from 'dugoly-login'
import { backgroundColor } from 'styled-system';

const Navbar = () => {
  const { navMenu } = navbar;
  const [state, setState] = useState({
    search: '',
    searchToggle: false,
    mobileMenu: false,
  });
const [ShowNavbarForm,setShowNavbarForm]=useState(false)


  const searchRef = useRef(null);
  useOnClickOutside(searchRef, () =>
    setState({ ...state, searchToggle: false })
  );

  const toggleHandler = type => {
    if (type === 'search') {
      setState({
        ...state,
        search: '',
        searchToggle: !state.searchToggle,
        mobileMenu: false,
      });
    }

    if (type === 'menu') {
      setState({
        ...state,
        mobileMenu: !state.mobileMenu,
      });
    }
  };

  const handleOnChange = event => {
    setState({
      ...state,
      search: event.target.value,
    });
  };

  // const handleSearchForm = event => {
  //   event.preventDefault();

  //   if (state.search !== '') {
  //     console.log('search data: ', state.search);

  //     setState({
  //       ...state,
  //       search: '',
  //     });
  //   } else {
  //     console.log('Please fill this field.');
  //   }
  // };

  const scrollItems = [];

  navMenu.forEach(item => {
    console.log("window.location.hostname",window.location.host+"#"+item.path.slice(1));
    const url=window.location.host+"#"+item.path.slice(1)
                // <a href={process.env.PUBLIC_URL+"/"+menu.path} onClick={handleRemoveMenu}> {menu.label}</a>
                scrollItems.push(url);
  });

  const handleRemoveMenu = () => {
    setState({
      ...state,
      mobileMenu: false,
    });
  };

  return (
    <NavbarWrapper className="navbar">
      <Container>
      {/* <div style={{width:"100px",height:"30px"}}>
        <Login  style={{width:"100px",height:"30px", fontSize:"10px"}} />
        </div> */}
        <Logo
          href="/"
          logoSrc={LogoImage}
          title="motto"
          className="main-logo"
        />
        {/* <Logo
          href="/"
          logoSrc={LogoImageAlt}
          title="motto"
          className="motto"
        /> */}
        {/* end of logo */}
       
        <MenuArea className={state.searchToggle ? 'active' : ''}>
          <ScrollSpyMenu className="menu" menuItems={navMenu} offset={-84} />
          {/* end of main menu */}

          {/* <Search className="search" ref={searchRef}>
            <form onSubmit={handleSearchForm}>
              <input
                type="text"
                value={state.search}
                placeholder="Enter your keyword"
                onChange={handleOnChange}
              />
            </form>
            <Button
              className="text"
              variant="textButton"
              icon={<Icon icon={state.searchToggle ? x : search} />}
              onClick={() => toggleHandler('search')}
            />
          </Search> */}
          {/* end of search */}

          {/* <AnchorLink href="#trail" offset={84}> */}
            {/* <Button className="trail" title="קשר ישיר" onClick={()=>{setShowNavbarForm(!ShowNavbarForm)}} /> */}
            {/* <BrowserView> */}
         {/* {!state.mobileMenu?<ContactUs message="קבל איפיון בחינם"  position="bottom center"/>:null} */}
            {/* </BrowserView> */}
          {/* </AnchorLink> */}

          <Button
            className="menubar"
            icon={
              state.mobileMenu ? (
                <Icon className="bar" icon={x} />
              ) : (
                <Fade>
                  <Icon className="close" icon={menu} />
                </Fade>
              )
            }
            color="#0F2137"
            variant="textButton"
            onClick={() => toggleHandler('menu')}
          />
          
        </MenuArea>
      </Container>

      {/* start mobile menu */}
      <MobileMenu className={`mobile-menu ${state.mobileMenu ? 'active' : ''}`}>
        <Container>
          <Scrollspy
            className="menu"
            items={scrollItems}
            offset={-84}
            currentClassName="active"
          >
            {navMenu.map((menu, index) => (
              <li key={`menu_key${index}`}>
                {/* <Link to={menu.path}>{menu.label}</Link> */}
                <a href={process.env.PUBLIC_URL+"/"+menu.path} onClick={handleRemoveMenu} offset={menu.offset}> {menu.label}</a>
                {/* <AnchorLink
                  href={process.env.PUBLIC_URL+"/"+menu.path}
                  // href={process.env.PUBLIC_URL+"/"+menu.path}
                  offset={menu.offset}
                  onClick={handleRemoveMenu}
                > */}
                  {/* {menu.label}
                </AnchorLink> */}
              </li>
            ))}
          </Scrollspy>
          {/* <Button title="Try for Free" /> */}
        </Container>
      </MobileMenu>
      {/* end of mobile menu */}
{ShowNavbarForm&&<ContactUsForm/>}
    </NavbarWrapper>

  );
};

export default Navbar;
