import React from 'react'

const Promotion =({plan})=>{
  let text
  switch (plan) {
    case "fixed":
      text=<div>
<h1>חבילה בתשלום קבוע</h1><br/>
התשלום בחבילה זו מתחלק ל 2: <br/>
תשלום על סך 2200 ש"ח עבור הקמת הקמפיין (תשלום זה יגבה על ידינו)<br/>
תשלום, לפי בחירתך עבור הפרסום עצמו (תשלום זה יגבה על ידי נותן שרותי הפרסום)<br/>
<a href="/מחירי%20קידום%20ממומן" style={{color:"#A8DDFE"}}>לפרוט פרטי החבילה וחבילות נוספות, לחץ כאן  </a>  <br/>
      </div>
      break;

    case "relative":
      text=<div>
      <h1>חבילה בתשלום יחסי</h1><br/>
      התשלום בחבילה זו כולל: <br/>
      תשלום על סך 790 ש"ח (תשלום זה יגבה על ידי נותן שרותי הפרסום)<br/>
      <a href="/מחירי%20קידום%20ממומן" style={{color:"#A8DDFE"}}>לפרוט פרטי החבילה וחבילות נוספות, לחץ כאן  </a>  <br/>
            </div>
        break;

    case "hours":
      text=<div>
      <h1>חבילה בתשלום לפי שעות</h1><br/>
התשלום בחבילה זו מתחלק ל 2: <br/>
תשלום על סך 2400 ש"ח עבור עד 10 שעות להקמת הקמפיין (תשלום זה יגבה על ידינו)<br/>
תשלום, לפי בחירתך עבור הפרסום עצמו (תשלום זה יגבה על ידי נותן שרותי הפרסום)<br/>
      <a href="/מחירי%20קידום%20ממומן" style={{color:"#A8DDFE"}}>לפרוט פרטי החבילה וחבילות נוספות, לחץ כאן  </a>  <br/>
            </div>
      break;

    default:
      break;
  }

 

      return (
        <div style={{color:"white"}}>
        <h1> חבילת קידום ממומן</h1>
        <center>
{text}
</center>
        </div>
      );
    }
export default Promotion