import React from 'react'

const Text=()=>{
      return (
        <div style={{color:"white"}} dir="RTL">
         <h1> this-is-it <br/>
הפניה ישירה בחיפוש גוגל
         </h1><br/>
         כיצד משתמשים בהרחבה?<br/>
לאחר התקנת ההרחבה, <br/>
כל פעם שאתה מבצע חיפוש של ערך שמהווה ביטוי מוסכם לאתר מסויים (על פי המוגדר בבסיס הנתונים שלנו), תופנה באופן אוטומטי לאותו האתר<br/>
למשל בחיפוש המילה "fiverr" - תועבר מידית ל - https://www.fiverr.com/<br/>
עם זה יש לך אפשרות לבטל הפניה לכל אתר שהוא, <br/>
כיצד? <br/>
לאחר ביצוע חיפוש לערך מוגדר, מיד לאחר שתועבר לאתר הרלוונטי, יופיע לך כפתור "זה לא זה" (בצד ימין למעלה), לחיצה עליו תגדיר שאינך מעונין להיות מופנה אוטומטי לאתר זה, <br/>
ולא יתבצעו יותר הפניות אוטמטיות לאתר זה<br/>
כמו כן, אתה יכול לבחור אתרים שעבורך יקבלו הפניה ישירה<br/>
למשל אתה יכול לבחור שכל פעם שתכתוב את המילה "בנק" - תופנה אוטומטי ל אתר של "bank of america"  (https://www.bankofamerica.com/)<br/>
כיצד? <br/>
לאחר כל חיפוש, ולאחר שתועבר לאתר שתבחר יופיע לך כפתור "זהו זה", לחיצה עליו, תגדיר לאתר זה הפניה ישירה עבור מילת מפתח זו ששימשה לחיפוש <br/>
(ניתן לבטל הגדרה זו בכל שלב ע"י לחיצה על הכפתור "זה לא זה")<br/>
כלומר, אם עבור חיפוש המילה "בנק" אני אבחר את הערך השלישי ולאחר כניסה לאתר אלחץ על "זהו זה", לאתר הנוכחי תוגדר הפניה ישירה עבור מילת המפתח "בנק"<br/>
<br/>
לפרטים נוספים:<br/>
<a href="https://dugoly.com/Documentation/readme/this-is-it.html" style={{color:"#A8DDFE"}}>    https://dugoly.com/Documentation/readme/this-is-it.html </a><br/>
ניתן לקבל נוהלי פרטיות - <br/>
<a href="https://dugoly.com/Documentation/readme/this-is-it-Privacy-Policy.html" style={{color:"#A8DDFE"}}>  https://dugoly.com/Documentation/readme/this-is-it-Privacy-Policy.html</a><br/>

          
           </div>
      );
    }
export default Text