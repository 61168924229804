import React ,{useState}from 'react';
import Fade from 'react-reveal/Fade';
import Text from '../../../../common/src/components/Text';
import Image from '../../../../common/src/components/Image';
import Button from '../../../../common/src/components/Button';
import BannerWrapper, {
  CustomerWrapper,
  ImageWrapper,
} from './banner.style';
import { client } from '../../../../common/src/data/AppModern';
import circleBorder from '../../../../common/src/assets/image/appModern/shape.svg';
import axios from 'axios'
import ButtonStyle from '../../../../common/src/components/Button/button.style';




const Banner = () => {
  const [Name,setName]=useState("")
const [Email,setEmail]=useState("")
const [Phone, setPhone]=useState("")
const [Message, setMessage]=useState("")

const[OpenForm,setOpenForm]=useState(0)
const [ShowForm,setShowForm]=useState(false)




const handleChange=(e)=>{
 
  switch(e.target.name){
case ("name"):
setName(e.target.value)  

break

case ("email"):
setEmail(e.target.value)  

break

case ("phone"):
setPhone(e.target.value)  

break

case ("message"):
setMessage(e.target.value)  

break




  }
}
 
const form=( <form dir="rtl" style={{
  color:"white",
  maxWidth: '500px',
}}>
   <fieldset>
   <legend style={{color:"white"}}>קורסים והתמקצעיות בפיתוח ותוכנה</legend>
   <center>

    שם מלא: <br/>
    <input  type="text" name="name"  value={Name} onChange={handleChange}/>
<br/>
    <br/>אימייל:<br/>
    <input  type="text" name="email"  value={Email} onChange={handleChange} />
<br/>
    <br/> טלפון:<br/>
    <input  type="text" name="phone"  value={Phone} onChange={handleChange} />

    <h2>במה נוכל לעזור?</h2>
    <textarea 
   rows="4" cols="30"
    name="message"  value={Message} onChange={handleChange}
    />
<br/>
<Button  title="שלח"  onClick={()=>{
if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Email)))
alert("האימייל לא תקין- נא הכנס אימייל תקין!")
else
{
  
  axios({
method:"post",
url:" https://us-central1-sender-4330e.cloudfunctions.net/app/send_email_client",
data:{to:"omershiran@gmail.com",from:"omershiran@gmail.com",subject:"הודעה חדשה באתר מוטו בנושא טופס קורסים והתמקצעיות בפיתוח ותוכנה:  ",text:(Name+"  "+Email+"  "+Phone+"  "+Message)}
}).then((v)=>{
  if(v.data===1001){setOpenForm(1)}
else{
  
  setOpenForm(2)}
}).
  catch(e=>{ 
    
    setOpenForm(2)})
    setOpenForm(3) 
}}}/></center>
</fieldset>
</form>)
const AfterProperDelivery=(<h2 style={{color:"white"}}>ההודעה נשלחה בהצלחה</h2>)
const AfterUnProperDelivery=(<h2 style={{color:"white"}}>תקלה במשלוח ההודעה, ההודעה לא נשלחה,<br/> אנא נסה שנית במועד מאוחר יותר</h2>)
const DirectMessageAfterSending=(<h2 style={{color:"white"}}>ההודעה נשלחת אנא המתן...</h2>)
let formContent=(OpenForm===0)?form:(OpenForm===1)?AfterProperDelivery:(OpenForm===3)?DirectMessageAfterSending:AfterUnProperDelivery

const FormContainer=(<div><h2 style={{color:"white"}}>קורסים והתמקצעיות בפיתוח ותוכנה</h2>
<center>
<ButtonStyle className="trail"  onClick={()=>{setShowForm(!ShowForm)}}>
 מעוניין? - תשאיר פרטים ואנחנו ניצור עימך קשר- לחץ כאן
</ButtonStyle></center>
{ShowForm&&formContent}</div>)

  return (
    <BannerWrapper id="home" dir="rtl" >
       
          <Fade up delay={100}>
            <h2 style={{color:"white"}}>בית תוכנה:</h2>
            <h1 style={{color:"white"}}>מוטו ישראל</h1>
            
            <div style={{clear: "both", dir:"ltr"}}>
    
</div>
<br/>

          </Fade>
          <Fade up delay={200}>
          <h2 style={{color:"white"}}>
            <Text dir='rtl'
              content="פתרונות מתקדמים במחשבים, פיתוח מערכות, אפליקציות לניידים ואתרים."
            /></h2>
            {FormContainer}
<h4 style={{color:"white"}}>
            קורסים והדרכות<br/><br/>
            <a href="/course200" style={{color:"#DCFFFA",   borderBottom: "5px solid #DCFFFA"}}>מבוא לתכנות</a><br/><br/>
            <div>שני מפגשים שבועיים של שעתיים למשך 12 שבועות</div>
            <div>נתחיל עם html אבל נתמקד בעיקר בשפת התכנות JavaScript (גאווה סקריפט) שהיא, היום, שפת התכנות הפופולרית ביותר -נלמד את יסודות התכנות-נכיר מושגי יסוד: משתנים, פונקציות, פרמטרים -עוד-והמון מושגים כללים בתכנות כגון Full Stack, html, Frontend, Backend, קוד פתוח, Data base, Android, Server, Server less-נכיר פורטלים, עזרים ושרותים לפיתוח תוכנה כגון: Git, Github, Firebase, aws ועוד המון כלים-ונעשה המון תרגולים</div>
  
            <br/><br/>
    
      <div>תכנות צד שרת וצד לקוח ב node js</div>
      <div>שני מפגשים שבועיים של 5 שעות  למשך 24 שבועות</div>
      <div>full stack developer in node js</div>
      <br/><br/>

      <div>react</div>
      <div>שני מפגשים שבועיים של 5 שעות  למשך 24 שבועות</div>
      <div>full stack developer in react js</div>
<br/><br/>

      <div>ממשקי ניהול - התמקדות ב 
אתר מערכות
companyslogan.net</div>
      <div>שלושה מפגשים של שעתיים</div>
      <div>הקמת אתרי אינטרנט : אתרי תוכן, חנות מקוונת תפוצת אימיילים, דומיין יחודי ועוד </div>
      <br/><br/>

      <div>איפיון פרוייקטים וביצוע במערכת<a href="https://directdone.com/" style={{color:"#A8DDFE"}}> directDone.com</a></div>
      <div>ששה מפגשים של שעתיים</div>
      <div>הקמה איפיון וביצוע פרוייקטים, מערכות מחשב אפליקציות ואתרים</div>
      <br/><br/>


      <div>ישומי מחשב</div>
      <div>שלושה עשר מפגשים של 4 שעות</div>
      <div>word, excel, pwoerpoint,gmail, companyslogan.net</div>
      <br/><br/>
      
            </h4>
          </Fade>
          
          

      <CustomerWrapper>
        <Text content="מהגלריה שלנו:" />
        <ImageWrapper>
         {client.map(item => (
            <a href={item.link} target="blank">
            <Image
              key={`client-key${item.id}`}
              src={item.image}
              alt={item.title}
            /></a>
          ))}
           </ImageWrapper>
      </CustomerWrapper>
      
      
      <img
        className="bannerBottomShape"
        src={circleBorder}
        alt="Bottom Circle"
      />
    </BannerWrapper>
  );
};

export default Banner;
