import React from 'react'

const Text=()=>{
      return (
        <div style={{color:"white"}}>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>מזה&nbsp;</span><u><span style={{fontSize: '24px', fontFamily: '"Arial",sans-serif'}}>שוקאתר</span></u><span style={{fontFamily: '"Arial",sans-serif'}}>?</span></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: '21px', fontFamily: '"Arial",sans-serif'}}>שוק-אתר&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>הינו&nbsp;</span><span style={{fontSize: '21px', fontFamily: '"Arial",sans-serif'}}>שוק למכירת אתרים&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>פעילים</span></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>אז למה&nbsp;</span><span style={{fontSize: '21px', fontFamily: '"Arial",sans-serif'}}>לקנות אתר&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>אם אפשר לבנות אותו בעצמי?</span></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>שאלה טובה!</span></p>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>יש להבחין בשתי סיבות לקנית אתרים&nbsp;</span></p>
          <ol style={{listStyleType: 'decimal'}}>
            <li><span style={{fontFamily: '"Arial",sans-serif'}}>על מנת ליצור&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif', fontSize: '14.0pt'}}>רווחים פסיביים&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>–&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>קניית אתר פעיל&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>שכבר מניב רווחים ע"פ פרסומות, תנועה או מכירות, זוהי השקעה ככל השקעה כספית</span></li><br/>
            <li><span style={{fontFamily: '"Arial",sans-serif'}}>על מנת לשרת את העסק שלי, יש לי עסק ואני רוצה&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>להגדיל את הפעילות שלי</span><span style={{fontFamily: '"Arial",sans-serif'}}>, אני רוצה אתר, אז אני יכול לבנות אותו בעצמי או לבקש ממפתח לפתח עבורי אבל זה המון התעסקות, השקעה והרבה הרבה זמן המתנה עד שהאתר שלי יתקדם למקומות ראליים בגוגל,<br />&nbsp;אז אני יכול, במקום זה, לקנות אתר שמישהו כבר עשה לו את העבודה, לחסוך לעצמי את הזמן והמאמץ (ולפעמים גם עלויות מיותרות), ולקנות משהו מוכן.</span></li>
          </ol>
          <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
        </div>
      );
    }
export default Text