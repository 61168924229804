import React from 'react'

const Text=()=>{
      return (
        <div style={{color:"white"}} dir="RTL">
    <h1 >תשלום לאיש מכירות:</h1>
מדרגות תמחור<br/>
<b>על בסיס ביצועים: </b><br/>
עמלת סוכן:<br/>
קידום אורגני – <br/>
בסיסית -70 ₪ <br/>
מתקדמת-300 ₪<br/>
כוללת-450 ₪<br/>
תגים-330 ₪<br/>
קידום ממומן- <br/>
חבילה בתשלום קבוע-340 ₪<br/>
חבילה בתשלום יחסי – 79 ₪<br/>
בנית אתר סטטי רספונסיבי-<br/>
חבילה בסיסית- 70 ₪<br/>
חבילה מתקדמת – 330 ₪<br/>
חבילה כוללת – 1000 ₪<br/>
חבילת שעות לכל התוכניות: 15% מרכישה ראשונה<br/><br/>
<b>שכר בסיס + תגמולים: </b><br/>
שכר בסיס -35 ₪ לשעה<br/>
תגמולים לפי חודש – <br/>
על כל סכום עד 10,000, אין תגמולים<br/>
על כל סכום מעל 10,000, עד 20,000, - 3 אחוז על כל סכום שמעל 10,000<br/>
על כל סכום מעל 20,000, עד 30,000, - 5 אחוז על כל סכום שמעל 20,000<br/>
על כל סכום מעל 30,000, עד 40,000, - 7 אחוז על כל סכום שמעל 30,000<br/>
על כל סכום מעל 40,000, - 10 אחוז על כל סכום שמעל 40,000<br/>
<br/>
<br/>
העמלה לסוכן, רק לאחר ביצוע התשלום בפועל<br/>
כל שת"פ עם סוכן\איש מכירות, מותנה בהסכמה לתנאי ההתקשרות<br/>


          
           </div>
      );
    }
export default Text