import React ,{useState}from 'react';
import Fade from 'react-reveal/Fade';
import Text from '../../../../common/src/components/Text';
import Image from '../../../../common/src/components/Image';
import Button from '../../../../common/src/components/Button';
import Container from '../../../../common/src/components/UI/Container';
import BannerWrapper, {
  BannerContent,
  BannerImage,
  CustomerWrapper,
  ImageWrapper,
} from './banner.style';
import { client } from '../../../../common/src/data/AppModern';
import circleBorder from '../../../../common/src/assets/image/appModern/shape.svg';
import axios from 'axios'
import NotFoundPage from './NotFoundPage'


const Banner = () => {
  
 return (
    <BannerWrapper id="home" dir="rtl" >
      <Container >
        
          

          
          
           
<NotFoundPage />
         
     
          
      </Container>


      <CustomerWrapper>
        <Text content="מהגלריה שלנו:" />
        <ImageWrapper>
         {client.map(item => (
            <a href={item.link} target="blank">
            <Image
              key={`client-key${item.id}`}
              src={item.image}
              alt={item.title}
            /></a>
          ))}
           </ImageWrapper>
      </CustomerWrapper>
      
      
      <img
        className="bannerBottomShape"
        src={circleBorder}
        alt="Bottom Circle"
      />
    </BannerWrapper>
  );
};

export default Banner;
