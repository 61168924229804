import React from 'react'
// import Head from 'next/head';
import { ThemeProvider } from 'styled-components';
import { theme } from './common/src/theme/appModern';
import { ResetCSS } from './common/src/assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from './containers/AppModern/Navbar';
import Banner from './containers/AppModern/Banner';
import AppSlider from './containers/AppModern/AppSlider';
import Features from './containers/AppModern/Features';
import DashboardFeatures from './containers/AppModern/Dashboard';
import ProductSlide from './containers/AppModern/ProductSlide';
import DesignedAndBuilt from './containers/AppModern/DesignedAndBuilt';
import PricingPolicy from './containers/AppModern/PricingPolicy';
import TeamPortfolio from './containers/AppModern/TeamPortfoilo';
import Testimonial from './containers/AppModern/Testimonial';
import Newsletter from './containers/AppModern/Newsletter';
import Footer from './containers/AppModern/Footer';
import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from './containers/AppModern/appModern.style';

import DevelopmentProcess from './containers/AppModern/DevelopmentProcess'
export default function() {
  return (
    <ThemeProvider theme={theme}>
      <>
        {/* <Head> */}
          <title>motto</title>
          <meta name="Description" content="מוטו - בית תוכנה לפיתוח אפליקציות ואתרים רספונסיביים" />
          <meta name="theme-color" content="#2563FF" />
          <meta
            name="keywords"
            content="motto,מערכות מחשב,קוד,react,java script,nodejs,server,java, c#, .net, תכנות,פיתוח, מיחשוב, סטארט-אפ,מחשבים,מערכות מתקדמות,אפליקציות, תוכנות,programing, program,שרת, ארוח אתרים,hosting, server, בניית אתר, בניית אתרים, בנייה, עיצוב, אחסון, דומיין, פיתוח אתרים, פיתוח אפליקציות, עיצוב אתר, אתר מוכן, תבניות אתר, אתר אינטרנט, אתרי אינטרנט, הקמת אתר, קידום אתרים, אתר מותאם לנייד,בית תוכנה,web,site,ווב,סייט,בנית אתר,בנית אתרים,מוטו"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Heebo:300,400,500,700&display=swap"
            rel="stylesheet"
          />
        {/* </Head> */}
        {/* end of head */}

        <ResetCSS />
        <GlobalStyle />
        {/* end of global and reset style */}

        {/* start app classic landing */}
        <AppWrapper dir="rtl">
          <Sticky top={0} innerZ={9999} activeClass="sticky-active">
            <Navbar />
          </Sticky>
          <ContentWrapper>
            <Banner />
             <Features />
            {/* <AppSlider /> */}
            {/* <DashboardFeatures /> */}
            {/* <Testimonial /> */}
            <ProductSlide />
            {/* <DesignedAndBuilt /> */}
            {/* <PricingPolicy /> */}
            {/* <TeamPortfolio />*/}
            {/* <Newsletter />  */}
           <DevelopmentProcess/>
          </ContentWrapper>
          <Footer />
        </AppWrapper>
        {/* end of app classic landing */}
      </>
    </ThemeProvider>
  );
}
