import React from 'react'

const Promotion =()=>{
      return (
        <div style={{color:"white"}}>
<h1>פרסום, תוכנית שיווקית ופרסום דיגיטלי</h1><br/>
<br/>
כדי לעשות קצת סדר בבלאגן נסביר את המושגים, <br/>
פרסום הכוונה לכל אופן בו אנחנו מביאים את המידע אודות העסק או המוצר לידיעת גורמים נוספים<br/>
בין אם זה ע"י דיווח טלפוני בקשר אישי, בין אם זה ע"י מודעת רחוב בלוח המודעות או בלוח הכניסה לבניין, פרסום ברדיו או מודעות ברשת המדיה.<br/>
אבל זה לא שיווק ולא תוכנית שיווקית<br/>
אז מה זה שיווק?<br/>
אם ננסה לנסח במשפט אחד את ההבדל בין פרסום לשיווק נגדיר שפרסום היא הבאת האובייקט לידיעת הציבור לעומת שיווק שהוא הנגשת האובייקט לתודעת הציבור<br/>
פרסום הוא לגרום לאנשים לדעת על המוצר לעומת שיווק שהוא לגרום לאנשים לרצות את המוצר.<br/>
אז מה זה תוכנית שיווקית?<br/>
תוכנית כשמה לתכנן כיצד לשווק את המוצר, תוכנית זו כוללת שיקולים רבים בין בבניית התדמית של המוצר הנידון, בין בהגדרת קהל היעד לפרסום, בין בתכנון  האינטראקציה עם הלקוח ועוד שיקולים רבים אחרים<br/>
ניקח לדוגמה עסק חדש שאנו פותחים ליצור אופניים חשמליות, לעסק אין בשלב זה קהל של מכירים ואנו מעוניינים למכור את האופניים במחיר זול במיוחד על מנת להכנס לשוק<br/>
האם פרסום האופניים במחיר 1500 ₪ בלבד יגרום לקניה מוגברת או יגרום לתדמית של אופניים זולות ובאיכות נמוכה?<br/>
מצד שני אם ניקח חברה למשקעות קלים, ונניח שמצאנו שהצרכנים העיקריים שלנו הם אנשים מהשכבות הסוציו אקונומיות החלשות, אבל הצריכה שם היא גבוהה, אם נשווק את המוצר במחיר יקר וב 50% הנחה אולי נגרום לתדמית יוקרתית למוצר מחד אבל אולי דווקא לא נבנה לנו את הקהל הרצוי לתווך הארוך מאידך אם נבנה לנו תדמית של מוצר פשוט, טעים וזול אולי הרווחנו דווקא קהל לתווך ארוך יותר?<br/>
יתכן ולאותו מוצר יהיו אופני שיווק לקהל יעד שונה, ניצור שני פסי יצור לאותו המוצר את האחד נארוז באריזה שמשדרת יוקרתיות ובריאות ונשווק בתור מוצר בריא (בהנחה שהוא באמת כזה!), ואת השני נארוז באריזה פשוטה ונשווק בתור מוצר פשוט וזול, כאשר המטרה המוגדרת היא לתת לכל קהל יעד את הערך המוסף הרלוונטי לגביו.<br/>
את מסע הפרסום למוצר הראשון נעשה במגזרים שאנו מעריכים שיהיו מוכנים לשלם יותר עבור מוצר בריא ואת השני נשווק דווקא במקומות בו ניתוח השוק שלנו מראה כי יהיו יותר צרכנים למוצרים פשוטים וזולים.<br/>
דוגמאות לשאלות שעומדות בהליך בניית התוכנית השיווקית:<br/>
 האם יש להציג את המוצר כמוצר זול או יקר? <br/>
האם להציג את המוצר כבריא או טעים?<br/>
האם לפרסם את המוצר לקהל רחב או ספציפי?<br/>
האם להציג את המוצר כמותג?<br/>
האם להציג את המוצר כמודרני, סטייליסטי או נוסטלגי?<br/>
האם לבנות פרסום חזק ומהיר או פרסום מתמשך?<br/>
האם מטרת הפרסום היא לעודד אנשים לקניה עם הצפייה בפרסום המוצר או רק להביא את המוצר לידי תודעת הציבור?<br/>
האם להזמין אנשים לקבל שרות חינמי במטרה לעודד אותם לקנות אח"כ או שבאופן זה נמשוך דווקא את האנשים הפחות רלוונטיים למוצר הספציפי.<br/>
ועוד שאלות רבות<br/>
כמובן שתוכנית שיווקית כוללת בתוכה גם את כל התחום של הפרסום.<br/>
<br/>
אם כך, כשאנו מדברים על שיווק בגידיטל או במדיה למה אנחנו מתכוונים לפרסום או לשיווק?<br/>
אם אנחנו רק מפרסמים מודעות המדיה, אז זה פרסום, אבל רוב החברות שמציגות עצמם כמפרסמות במדיה מציגות עצמם כ "שיווק דיגיטלי", למה?<br/>
בין אם אנחנו מפרסמים בגוגל,  בפייסבוק, בטאבולה או בכל רשת חברתית או מדיה אחרת המציאות היום שלא נעסוק רק בפרסום אלא גם בשיווק!<br/>
אם ניקח את גוגל אדס כדוגמה, נצטרך לעשות ניתוח מילות מפתח בשביל למקד את המודעות לקהל היעד הרלוונטי מחד ולהוזיל עלויות מאידך.<br/>
גוגל גם ימליצו לנו לעשות מעקב המרות כך שנוכל לנתח ולהתמקד באופנים ובתכנים שיובילו אותנו לקהל עם פוטנציאל הרכישה הגדול יותר.<br/>
כמובן שעצם השאלה אם לפרסם ברשת המדיה או ברשת החיפוש היא עצמה שאלה שיווקית.<br/>
כמובן שאם ננסה לנתח את הפרסום בפייסבוק נגיע למסקנות דומות, שגם כאן אנו עוסקים הרבה בשיווק, מכיוון שהשלב הראשון יהיה להכווין את הפרסום ע"פ הגדרת קהלים.<br/>
כשאנו במוטו בונים תוכנית לשיווק ברשת המדיה, כוונת הדברים היא לבנות תוכנית המביאה תנועה איכותית לאתר כאשר בהליך בניית הפרסום אנו עושים, באופן קבוע אופטימיזציה למסע הפרסום על מנת למקד את הפרסום לקהל היעד הרלוונטי, לשפר את אפקטיביות הפרסום ולהוזיל את העלויות פר קליק מחד ולמקד אותם מאידך על מנת ליצור את מירב ההמרות במסע הפרסום.<br/>
עם זה יש לציין שאין מדובר כאן בתוכנית שיווקית כוללת, אין אנו בונים את המותג, לא מחליטים על התדמית של המוצר ובד"כ לא מתערבים בשיקולים השיווקיים שיש באתר, אע"פ שברור שדבר זה משפיע מאוד על אפקטיביות מסע הפרסום!!<br/>
על מנת לבנות תוכנית שיווקית אנו ממליצים להתייעץ עם יועץ שיווק!<br/>
(נ"ב: אנחנו לא נותנים שרות זה)<br/>
<br/>
לרכישת חבילת קידום אורגני או ממומן, אנא מלא את הטופס המצורף בעמוד זה!<br/>
<br/>




        </div>
      );
    }
export default Promotion