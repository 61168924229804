import React ,{useState}from 'react';
import {withRouter} from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import Text1 from '../../../../../../../common/src/components/Text';
import Image from '../../../../../../../common/src/components/Image';
import Button from '../../../../../../../common/src/components/Button';
import Container from '../../../../../../../common/src/components/UI/Container';
import BannerWrapper, {
  BannerContent,
  BannerImage,
  CustomerWrapper,
  ImageWrapper,
} from './banner.style';
import { client } from '../../../../../../../common/src/data/AppModern';

import keysObject from '../../../keysObject'



const Banner = (props) => {
  const key=props.match.params.wordKey
let text
try{text=keysObject[key].text}catch{text=(<h1>הערך המבוקש אינו קיים במילון</h1>)}

 return (
    <BannerWrapper id="home" dir="rtl" >
      <Container >
        <div style={{color:"white"}}>
        {text}
        
        <br/><br/><br/>
        הערכים נוספים לאינדקס זה: <br/>
        {Object.keys(keysObject).map((key)=>{
  return(<div>
    <a href={"/Dictionaries/programming-en/Values/"+key} style={{color:"#A8DDFE"}}><u>{keysObject[key].name}</u></a>
</div>
  )
})}
</div>
        </Container >
        
      <CustomerWrapper>
        <Text1 content="מהגלריה שלנו:" />
        <ImageWrapper>
         {client.map(item => (
            <a href={item.link} target="blank">
            <Image
              key={`client-key${item.id}`}
              src={item.image}
              alt={item.title}
            /></a>
          ))}
           </ImageWrapper>
      </CustomerWrapper>
      
      
      
    </BannerWrapper>
  );
};

export default withRouter(Banner);
