import React ,{useState}from 'react';
import Fade from 'react-reveal/Fade';
import Text from '../../../../common/src/components/Text';
import Image from '../../../../common/src/components/Image';
import Button from '../../../../common/src/components/Button';
import Container from '../../../../common/src/components/UI/Container';
import BannerWrapper, {
  BannerContent,
  BannerImage,
  CustomerWrapper,
  ImageWrapper,
} from './banner.style';
import { client } from '../../../../common/src/data/AppModern';
import circleBorder from '../../../../common/src/assets/image/appModern/shape.svg';
import axios from 'axios'
import InvestorRecruitmentText from './Text'




const Banner = () => {

return (<div>
  <BannerWrapper>
    <Container>
<InvestorRecruitmentText />
</Container>
</BannerWrapper>

</div>)

};

export default Banner;
