import React from 'react'

const keysObject={
  toxic_links_story:{name:"כמה יקר יכול להיות קידום אתרים זול ?",
text:(<div style={{color:"white"}}>
  <div>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><strong><u><span style={{fontSize: '19px',fontFamily: '"Arial",sans-serif'}}>כמה יקר יכול להיות קידום אתרים זול ולא מקצועי.</span></u></strong></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>אז הינה הסיפור שלי כשהתחלתי את הדרך</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>אחד הפרמטרים החזקים של קידום האתרים הוא הקישוריות של האתר כלומר כמה האתר מקושר מאתרים אחרים, ההנחה היא שאתר שהרבה מפנים אליו כנראה שהוא יותר רלוונטי, יותר בעל תוכן ויותר מושך עניין.</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>לכן לצורך העניין אם יש לי שני אתרים בעל &nbsp;תוכן דומה ורמת כתיבה דומה אבל לאחר יש 2 קישורים נכנסים ולשני יש 5000 קישורים נכנסים אזי – כנראה יש הרבה יותר אתרים שנעזרים באתר השני ורואים בו כבעל תוכן מועיל ( אם לא למה הם מפנים עליו).</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>זו ההנחה שבנויה ביסוד ההחלטה של גוגל לקדם אתרים ע"פ קישורים נכנסים</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>ישנם מדידים שונים שבודקים את רמת או נפח הקישורים הנכנסים,</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>אחד מהם הוא&nbsp;</span><span dir="LTR">DR</span><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;שמשמעותו&nbsp;</span><span dir="LTR">"</span><span dir="LTR">&nbsp;</span><span dir="LTR">Domain rating"</span><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;או בעברית דירוג דומיין</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>ככל שיש לאתר יותר קישורים נכנסים – כך ה&nbsp;</span><span dir="LTR">DR</span><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;שלו יותר גבוה</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>א"כ בחשבון פשוט, נגדיל את הקישורים, נגדיל את דירוג הדומיין וקידמנו את המקום של האתר במנועי החיפוש</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>אז ניתן למישהו להכניס עבורינו &nbsp;מליון קישורים – ואנחנו למעלה,</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>או אפילו יותר קל, יש מערכות שיוצרות קישורי סרק חסרי תוכן &nbsp;שיקשרו אל האתר שלנו – לא !?</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>בדיקה פשוטה מגלה אכן ששתילת המון קישורים, גם כאלו חסרי תוכן מעלה את ה&nbsp;</span><span dir="LTR">DR&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;באופן משמעותי</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>אבל כאן עלולות להתחיל הצרות כשיש קידום לא מקצועי,</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>את הדברים האלו שאמרנו גם גוגל יודעים, וגוגל עושים מאמץ להבחין בן קישורים אמיתיים לקישורים פקטיביים כשגוגל מזהה קישורים לא איכותיים, לא רק שזה לא מקדם את האתר אלא זה יכול גם להוריד אותו</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>אז מה הקריטריונים לקישור פקטיבי?, ישנם הרבה קריטריונים אבל עכשיו נתמקד באחד מהן – קישור מאתר בעל ערך קידומי נמוך מאוד או קישור ללא תוכן למשל הפנית סרק כשמישהו מזין ערכים לא נכונים וכדומה</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>דידוג מאוד נמוך בגוגל ויעזור רק קצת לקידום האתר, במקרה הטוב, ובמקרה הפחות טוב אף יחשב כקישור רעיל שמלמדת את גוגל שהאתר המסויים מנסה להתקדם ללא תוכן איכותי ויגרום להורדת הדירוג שלו</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>זה המשמעות של <a href="https://motto.co.il/Dictionaries/SEO/Values/toxic_links" style={{color:"#A8DDFE"}}>קישור רעיל</a> – קישור שמוריד את הקידום של האתר במנועים</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>וכאן מגיע הסיפור (שכנראה כבר מובן מעליו...) בתחילת ההתעסקות שלי בקידום אתרים ראיתי פרסומת של מישהו שיכול לבנות מערך קישורים לאתר ולעלות את ה&nbsp;</span><span dir="LTR">DR&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;שלו מהמעותית, במחיר ממש מצחיק, מצויין, לא?</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>אז זהו שלא, כי לא כל המדדים מחשבים את הדירוג באותו אופן, מדד&nbsp;</span><span dir="LTR">DR</span><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;לא עושה את כל החשבון שאמרנו,&nbsp;</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>ואכן כמו שהוא התחייב הוא שתל "עבורי" המון קישורים באיכות נמוכה מאוד , ה&nbsp;</span><span dir="LTR">DR&nbsp;</span><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;אכן קפץ אבל האתר קיבל דחיפה שללית משמעותית שדרשה ממני הרבה התעסקות כדי לתקן אותה.</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>נ"ב: קישורים רעילים זה גם דרך לפגוע ולהסיג אחור אתרים שנמצאים במקומות גבוהים, אבל בזה אני לא מתעסק!</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginLeft: '0cm', textAlign: 'right', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
      </div>
</div>)},



acre_story:{name:"אבטחת מידע - מה שהאקר הסיני לימד אותי",
text:(<div style={{color:"white"}}>
<h1 dir="RTL" style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', textAlign: 'center', lineHeight: '107%', fontSize: '21px', fontFamily: '"Calibri Light",sans-serif',  fontWeight: 'normal'}}><span style={{fontFamily: '"Times New Roman",serif'}}>מה שהאקר הסיני לימד אותי</span></h1>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>שמעו סיפור.</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>סיפור אמיתי לחלוטין! באמא'שלי!! (הפרטים שמורים במערכת).</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>אחד הלקוחות שלי, מנכ''ל של ארגון חסד שמתעסק הרבה עם גיוס כספים דרך טלמרקטין, נפל עם וירוס כופר.</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>מה זה אומר?</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>וירוס כופר השתלט לו על השרת והצפין לו את כל המסדי נתונים.</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>אני רוצה שתבינו מה &nbsp;המשמעות של דבר כזה בשביל עמותה כזו.</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>העמותה הזו בנוייה על תרומות של אנשים והמסד נתונים עם כל התורמים הקיימים שלהם הלך פייפאל.</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>מי שמכיר את התחום יודע שלבנות קהל תורמים קבוע זה משהו שלוקח שנים על גבי שנים והשקעה אדירה של כסף בשביל לבנות את זה.</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>חוץ מזה כל ההיסטורייה של התרומות לא קיימת,</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>כמובן התחברתי אליו לשרת והעלתי את הפרטים של ההאקר הבן כלב שהצפין הכל,</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>החמור רוצה 5000 דולר בשביל לתת לנו את המפתח שיפתח את ההצפנה.</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>כמובן שניסיתי להכניס את המסד נתונים שלהם לכל מיני שירותים שמנסים לפענח קבצים מוצפנים של כופר, ו.... נאדה קיבלתי רק שורות של תווים מחורבשים ומסריחים.</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>חיפשנו גיבויים, והאחרון שמצאנו היה מלפני חצי שנה,</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>זה אומר אובדן של תנועות כספיות של חצי שנה</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>כל התורמים מהחצי שנה האחרונה הלכו לעזזאל</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>אם מחר מתקשר תורם ומבקש קבלה על מה שתרם, אין לו מאיפה להוציא את זה.</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>בקיצור נזק טכני ותדמיתי מטורף.</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>אז המנכ''ל נכנס לשורה של התייעצויות עד שהחליט שאין ברירה אלא לשלם להאקר את הכסף ולמזער נזקים,</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>כמובן לאחר שקיבל אישור מהעורך דין שזה חוקי וזה בסדר.</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>למדתי איך לקנות ביטקוין קניתי שלחתי לחמור ונכנסנו למתח.</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>יש האקרים שהתשלום הראשון הוא רק איתות בשבילם שהחומר מספיק חשוב לנו בשביל לבקש עוד כסף, ואין לנו באמת דרך להבטיח שיחזירו לנו את הקבצים.</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>התברר לנו שנפלנו על האקר צנוע שהסתפק ב5000 דולר ושלח לנו את המפתח פיענוח.</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>התחברתי אליו, הרצתי את הסקריפט פיענוח בהוראת האקר, והכל בא על מקומו בשלום.</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>כך חשבנו...</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>אחרי שבוע...</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>שוב פעם השרת שלהם נפרץ והוצפן.</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>כמובן אחרי שעה של קללות והאשמות של כל העולם ואשתו, המנכל נוחת אצלי במשרד.</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>מה עושים?</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>המנכל לא מבין במחשבים אבל הוא טוב בשתדלנויות.</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>אומר לי אולי נפנה ללב שלו נספר לו על כל הפעילויות של העמותה, אולי זה ירגש אותו.</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>אמרתי לו, אתה רגיל מכל התורמים הזקנים שלך שאתה מספר להם סיפור מרגש על חולים ומסכנים והם פותחים את הארנק,</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>פה מדובר בגוש טינופת שלא נראה לי שהארוחות צוהריים שאתה מחלק בשערי צדק ירגשו אותו.</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>מה אכפת לך לנסות?</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>לא אכפת לי!</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>הבחורצ'יק ניסח מכתב בשם כל האלמנות והיתומים חולי הסרטן ושות אנא רחם עלינו, כל דולר שאתה לוקח ממנו נחסר מכל המסכנים הללו, וכו' וכו'</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>צירפנו כמה קישורים ליוטיוב לכמה סרטונים באנגלית של העמותה ושלחנו.</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>אחרי 5 דקות קיבלנו מייל ממנו ששאל מה ה</span><span dir="LTR">IP</span><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;שלנו.</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>שלחתי לו, מה כבר יש לי להפסיד.</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>ואז הוא כותב לנו כך:</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>איזה חסרי אחריות אתם, למה השם משתמש שלכם זה&nbsp;</span><span dir="LTR">administrator</span><span style={{fontFamily: '"Arial",sans-serif'}}>&nbsp;ולמה הסיסמה שלכם כל כך קלה.</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>איזה מנכל חסר אחריות אתה.</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>ואז הוא מבקש ממנו מזהה וסיסמה מהטים ויואר (</span><span dir="LTR">team viewer</span><span style={{fontFamily: '"Arial",sans-serif'}}>).</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>בחיים לא האמנתי שאני אתן להאקר עם תעודות את הפרטי התחברות לטים ויואר שלי.</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>אבל כבר מה יש לי להפסיד.</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>נתתי לו.</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>הוא מתחבר, מריץ מה שמריץ אחרי חמש דקות הכל חוזר למקומו בשלום ומתנתק.</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>בלי לקחת סנט.</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>ועכשיו ברצינות!</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>מה צריך ללמוד מזה.</span></p>
<ul style={{listStyleType: 'disc'}}>
  <li><span style={{fontFamily: '"Arial",sans-serif'}}>העולם לא שחור לבן, יכול להיות הוגנות ורחמים מסויימים גם אצל האקר.</span></li>
  <li><span style={{fontFamily: '"Arial",sans-serif'}}>אל תחכו לעצות של האקר ואל תזדקקו לטובות שלו, תגנו על הנתונים שלכם.</span></li>
  <li><span style={{fontFamily: '"Arial",sans-serif'}}>תבחרו שם משתמש וסיסמה מיוחדים</span></li>
  <li><span style={{fontFamily: '"Arial",sans-serif'}}>גיבוי גיבוי גיבוי.</span></li>
</ul>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '36.0pt', marginBottom: '0cm', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>היום יש שירותים כל כך זולים ואוטומטיים שיעשו לכם גיבוי אוטומטי כל יום,</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '36.0pt', marginBottom: '0cm', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>בלי שום התעסקות מצידכם</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '36.0pt', marginBottom: '0cm', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>אתם רק צריכים להתקין ולהפעיל.</span></p>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '36.0pt', marginBottom: '8.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>זה פשע לא לעשות את זה</span></p>
</div>)},


bid_example:{name:"דוגמה להצעת מחיר",
text:(<div style={{color:"white"}}>
  <div align="right" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>
    <table dir="rtl" style={{width: '100.0%', borderCollapse: 'collapse', border: 'none'}} summary="טבלת פריסה להזנת סמל, מספר חשבונית, תאריך, תאריך תפוגה, שם החברה וסלוגן החברה, כתובת, מספרי טלפון ופקס וכתובת דואר אלקטרוני">
      <tbody>
        <tr>
          <td style={{width: '97.25pt', borderRight: 'none', borderBottom: 'none', borderLeft: 'none', borderImage: 'initial', borderTop: '1pt solid rgb(54, 95, 145)', padding: '0cm 5.75pt 2.9pt', height: '33.4pt', verticalAlign: 'top'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><br /></p>
          </td>
          <td colSpan={2} style={{width: '382.85pt', borderRight: 'none', borderBottom: 'none', borderLeft: 'none', borderImage: 'initial', borderTop: '1pt solid rgb(54, 95, 145)', padding: '7.2pt 5.75pt 2.9pt', height: '33.4pt', verticalAlign: 'top'}}>
            <h1 dir="RTL" style={{margin: '0cm', fontSize: '27px', fontFamily: 'Tahoma, sans-serif', color: 'rgb(54, 95, 145)', textAlign: 'left'}}><br /></h1>
            <h1 dir="RTL" style={{margin: '0cm', fontSize: '27px', fontFamily: 'Tahoma, sans-serif', color: 'rgb(54, 95, 145)', textAlign: 'left'}}>הצעת מחיר&nbsp;</h1>
            <h1 dir="RTL" style={{margin: '0cm', fontSize: '27px', fontFamily: 'Tahoma, sans-serif', color: 'rgb(54, 95, 145)', textAlign: 'left'}}><br /></h1>
            <h1 dir="RTL" style={{margin: '0cm', fontSize: '27px', fontFamily: 'Tahoma, sans-serif', color: 'rgb(54, 95, 145)', textAlign: 'left'}}>פיתוח אפליקציה &nbsp;לבדיקת מוכנות של<br />&nbsp;<br />ילדים לכיתה א'</h1>
          </td>
        </tr>
        <tr>
          <td colSpan={2} style={{width: '286.45pt', border: 'none', padding: '0cm 5.75pt 2.9pt', height: '27.9pt', verticalAlign: 'bottom'}}>
            <p dir="RTL" style={{margin: '0cm', fontSize: '16px', fontFamily: '"Tahoma",sans-serif', fontWeight: 'bold', textAlign: 'right'}}>מוטו</p>
          </td>
          <td style={{width: '193.65pt', border: 'none', padding: '7.2pt 5.75pt 2.9pt', height: '27.9pt', verticalAlign: 'bottom'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>תאריך:&nbsp;09.04.19</p>
          </td>
        </tr>
        <tr>
          <td colSpan={2} style={{width: '286.45pt', border: 'none', padding: '0cm 5.75pt', height: '25.65pt', verticalAlign: 'top'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>כתובת,&nbsp;ירושלים הפסגה 47</p>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>טלפון&nbsp;0512635325</p>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>דואר אלקטרוני:&nbsp;<a href="mailto:omer@motto.co.il"><span dir="LTR">omer@motto.co.il</span></a></p>
          </td>
          <td style={{width: '193.65pt', border: 'none', padding: '7.2pt 5.75pt 0cm', height: '25.65pt', verticalAlign: 'top'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', fontWeight: 'bold'}}><span style={{fontFamily: '"Tahoma",sans-serif', fontWeight: 'bold'}}><span style={{fontWeight: 'normal'}}><strong>תאריך תפוגה</strong>:</span></span><span style={{fontFamily: '"Tahoma",sans-serif', fontWeight: 'bold'}}><span style={{fontWeight: 'normal'}}>&nbsp;</span></span>09.05.19</p>
          </td>
        </tr>
        <tr>
          <td style={{border: 'none'}}><br /></td>
          <td style={{border: 'none'}}><br /></td>
          <td style={{border: 'none'}}><br /></td>
        </tr>
      </tbody>
    </table>
  </div>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><span dir="LTR">&nbsp;</span></p>
  <div align="right" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>
    <table dir="rtl" style={{border: 'none', width: '100.0%', borderCollapse: 'collapse'}} summary="הזן פרטי 'אל': שם איש הקשר, שם החברה, כתובת, מספר טלפון ומספר מזהה הלקוח">
      <tbody>
        <tr>
          <td style={{width: '22.5pt', padding: '2.9pt 5.75pt', height: '59.2pt', verticalAlign: 'top'}}>
            <h2 dir="RTL" style={{marginTop: '1.0pt', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', fontSize: '10px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>אל</h2>
          </td>
          <td style={{width: '278.45pt', padding: '2.9pt 5.75pt', height: '59.2pt', verticalAlign: 'top'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>מר יעקוב מנדלביץ</p>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>טלפון: 0559881914</p>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><span dir="LTR">&nbsp;</span></p>
          </td>
          <td style={{width: '203.05pt', padding: '2.9pt 5.75pt', height: '59.2pt', verticalAlign: 'top'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><span dir="LTR">&nbsp;</span></p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><span dir="LTR">&nbsp;</span></p>
  <div align="right" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>
    <table dir="rtl" style={{width: '75.02%', borderCollapse: 'collapse', border: 'none'}} summary="הזן את שם נציג המכירות, התפקיד, תנאי התשלום ותאריך היעד בטבלה זו">
      <tbody>
        <tr>
          <td style={{width: '120.0pt', border: 'solid #365F91 1.0pt', borderTop: 'solid #365F91 1.5pt', background: '#DBE5F1', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '10px', fontFamily: '"Tahoma",sans-serif', fontWeight: 'bold'}}><span style={{color: 'black'}}>נציג מכירות</span></p>
          </td>
          <td style={{width: '119.95pt', borderTop: 'solid #365F91 1.5pt', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', background: '#DBE5F1', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '10px', fontFamily: '"Tahoma",sans-serif', fontWeight: 'bold'}}><span style={{color: 'black'}}>תנאי תשלום</span></p>
          </td>
          <td style={{width: '119.85pt', borderTop: 'solid #365F91 1.5pt', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', background: '#DBE5F1', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '10px', fontFamily: '"Tahoma",sans-serif', fontWeight: 'bold'}}><span style={{color: 'black'}}>תאריך יעד</span></p>
          </td>
        </tr>
        <tr>
          <td style={{width: '120.0pt', border: 'solid #365F91 1.0pt', borderTop: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>עומר&nbsp;</p>
          </td>
          <td style={{width: '119.95pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>מפורטים בנספח</p>
          </td>
          <td style={{width: '119.85pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>מפורטים בנספח</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><span dir="LTR">&nbsp;</span></p>
  <div align="right" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>
    <table dir="rtl" style={{width: '87.26%', marginLeft: '8.6pt', borderCollapse: 'collapse', border: 'none'}} summary="הזן כמות, תיאור, מחיר ליחידה, הנחה וסכום שורה בעמודות הטבלה. הזן סכום ביניים, מע&quot;מ וסך הכל בסוף הטבלה">
      <tbody>
        <tr>
          <td style={{width: '59.7pt', border: 'solid #365F91 1.0pt', borderTop: 'solid #365F91 1.5pt', background: '#DBE5F1', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '10px', fontFamily: '"Tahoma",sans-serif', fontWeight: 'bold'}}><span dir="LTR">&nbsp;</span></p>
          </td>
          <td style={{width: '91.25pt', borderTop: 'solid #365F91 1.5pt', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', background: '#DBE5F1', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '10px', fontFamily: '"Tahoma",sans-serif', fontWeight: 'bold'}}><span style={{color: 'black'}}>תיאור</span></p>
          </td>
          <td style={{width: '91.4pt', borderTop: 'solid #365F91 1.5pt', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', background: '#DBE5F1', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '10px', fontFamily: '"Tahoma",sans-serif', fontWeight: 'bold'}}><span style={{color: 'black'}}>הערות</span></p>
          </td>
          <td style={{width: '57.75pt', borderTop: 'solid #365F91 1.5pt', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', background: '#DBE5F1', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '10px', fontFamily: '"Tahoma",sans-serif', fontWeight: 'bold'}}><span style={{color: 'black'}}>מחיר ליחידה</span></p>
          </td>
          <td style={{width: '52.6pt', borderTop: 'solid #365F91 1.5pt', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', background: '#DBE5F1', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '10px', fontFamily: '"Tahoma",sans-serif', fontWeight: 'bold'}}><span style={{color: 'black'}}>מחיר חבילה</span></p>
          </td>
          <td style={{width: '65.8pt', borderTop: 'solid #365F91 1.5pt', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', background: '#DBE5F1', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '10px', fontFamily: '"Tahoma",sans-serif', fontWeight: 'bold'}}><span style={{color: 'black'}}>סכום שורה</span></p>
          </td>
        </tr>
        <tr>
          <td style={{width: '59.7pt', border: 'solid #365F91 1.0pt', borderTop: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>אפיון</p>
          </td>
          <td style={{width: '91.25pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>איפיון דרישות וזרימת האפליקציה, שמור במערכת</p>
          </td>
          <td style={{width: '91.4pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>בביצוע, נעשה כבר חלקית</p>
          </td>
          <td style={{width: '57.75pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>800 ₪</p>
          </td>
          <td style={{width: '52.6pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}><strong>מתנה!!</strong></p>
          </td>
          <td style={{width: '65.8pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>0 ש"ח</p>
          </td>
        </tr>
        <tr>
          <td style={{width: '59.7pt', border: 'solid #365F91 1.0pt', borderTop: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>עיצוב&nbsp;</p>
          </td>
          <td style={{width: '91.25pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>כללי לאפליקציה כולל עיצוב המסכים וכלל המסכים הנדרשים למבדקים לאפליקציה</p>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>כולל אובייקטים</p>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>כולל לוגו לאפליקציה</p>
          </td>
          <td style={{width: '91.4pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>ניתן לספק ע"י גורם חיצוני, לבחירת הלקוח ולחסוך את העלויות, במקרה כזה יש לציין זאת על הצעת המחיר ובטרם החתימה</p>
          </td>
          <td style={{width: '57.75pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>1700 ₪</p>
          </td>
          <td style={{width: '52.6pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>1700 ש"ח</p>
          </td>
          <td style={{width: '65.8pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>1700 ש"ח</p>
          </td>
        </tr>
        <tr>
          <td style={{width: '59.7pt', border: 'solid #365F91 1.0pt', borderTop: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>אנימציה- סרטון</p>
          </td>
          <td style={{width: '91.25pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>סרטון אנימציה ל מבדק&nbsp;</p>
          </td>
          <td style={{width: '91.4pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>ניתן לספק ע"י גורם חיצוני, לבחירת הלקוח ולחסוך את העלויות, במקרה כזה יש לציין זאת על הצעת המחיר ובטרם החתימה</p>
          </td>
          <td style={{width: '57.75pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>790 ₪</p>
          </td>
          <td style={{width: '52.6pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>790 ש"ח</p>
          </td>
          <td style={{width: '65.8pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>790 ש"ח</p>
          </td>
        </tr>
        <tr>
          <td style={{width: '59.7pt', border: 'solid #365F91 1.0pt', borderTop: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>אנימציה – דמות</p>
          </td>
          <td style={{width: '91.25pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>6 מופעים של דמות אנימציה למבדקים 3,4</p>
          </td>
          <td style={{width: '91.4pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>ניתן לספק ע"י גורם חיצוני, לבחירת הלקוח ולחסוך את העלויות, במקרה כזה יש לציין זאת על הצעת המחיר ובטרם החתימה</p>
          </td>
          <td style={{width: '57.75pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>300 ₪</p>
          </td>
          <td style={{width: '52.6pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>300 ש"ח</p>
          </td>
          <td style={{width: '65.8pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>300 ₪</p>
          </td>
        </tr>
        <tr>
          <td style={{width: '59.7pt', border: 'solid #365F91 1.0pt', borderTop: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>כיתוב תוכן</p>
          </td>
          <td style={{width: '91.25pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>כולל כל ההדרכות למבדקים, וכיתוב הדברים הנדרשים למבדקים</p>
          </td>
          <td style={{width: '91.4pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>ניתן לספק ע"י גורם חיצוני, לבחירת הלקוח ולחסוך את העלויות, במקרה כזה יש לציין זאת על הצעת המחיר ובטרם החתימה</p>
          </td>
          <td style={{width: '57.75pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>690 ₪</p>
          </td>
          <td style={{width: '52.6pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>690 ש"ח</p>
          </td>
          <td style={{width: '65.8pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>690 ש"ח</p>
          </td>
        </tr>
        <tr>
          <td style={{width: '59.7pt', border: 'solid #365F91 1.0pt', borderTop: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>הקלטת קולות וצללים</p>
          </td>
          <td style={{width: '91.25pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>כולל כל ההדרכות למבדקים, והקלטת הדברים הנדרשים למבדקים</p>
          </td>
          <td style={{width: '91.4pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>ניתן לספק ע"י גורם חיצוני, לבחירת הלקוח ולחסוך את העלויות, במקרה כזה יש לציין זאת על הצעת המחיר ובטרם החתימה</p>
          </td>
          <td style={{width: '57.75pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>1800 ₪</p>
          </td>
          <td style={{width: '52.6pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>1800 ש"ח</p>
          </td>
          <td style={{width: '65.8pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>1800 ש"ח</p>
          </td>
        </tr>
        <tr>
          <td style={{width: '59.7pt', border: 'solid #365F91 1.0pt', borderTop: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>פיתוח פריסות לאפליקציה</p>
          </td>
          <td style={{width: '91.25pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>הפריסות ב&nbsp;<span dir="LTR">jsx</span></p>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>&nbsp;</p>
          </td>
          <td style={{width: '91.4pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>סה"כ 93 פריסות, לא חושבו פריסות כפולות, בעלי עיצוב זהה</p>
          </td>
          <td style={{width: '57.75pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>400 ₪ לפריסה</p>
          </td>
          <td style={{width: '52.6pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>מחיר חבילה: 34,200 ₪&nbsp;</p>
          </td>
          <td style={{width: '65.8pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>&nbsp;34,200 ₪</p>
          </td>
        </tr>
        <tr>
          <td style={{width: '59.7pt', border: 'solid #365F91 1.0pt', borderTop: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>בנית מעטפת האפליקציה, כולל הזדהות ע"י דוגולי</p>
          </td>
          <td style={{width: '91.25pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>&nbsp;</p>
          </td>
          <td style={{width: '91.4pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>&nbsp;</p>
          </td>
          <td style={{width: '57.75pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: 'Tahoma, sans-serif', textAlign: 'left'}}>2500 ₪</p>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: 'Tahoma, sans-serif', textAlign: 'left'}}>&nbsp;</p>
          </td>
          <td style={{width: '52.6pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: 'Tahoma, sans-serif', textAlign: 'left'}}>2500 ₪</p>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: 'Tahoma, sans-serif', textAlign: 'left'}}>&nbsp;</p>
          </td>
          <td style={{width: '65.8pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: 'Tahoma, sans-serif', textAlign: 'left'}}>2500 ₪</p>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: 'Tahoma, sans-serif', textAlign: 'left'}}>&nbsp;</p>
          </td>
        </tr>
        <tr>
          <td style={{width: '59.7pt', border: 'solid #365F91 1.0pt', borderTop: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>פיתוח מבדק 1-<span style={{fontSize: '15px', lineHeight: '110%', fontFamily: '"Arial",sans-serif', color: 'black'}}>&nbsp;קשר עין</span></p>
          </td>
          <td style={{width: '91.25pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><span dir="LTR">&nbsp;</span></p>
          </td>
          <td style={{width: '91.4pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>מחיר הפיתוח בהתאם למורכבות הפיתוח ולשלבים השונים</p>
          </td>
          <td style={{width: '57.75pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>3000 ₪</p>
          </td>
          <td style={{width: '52.6pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>3000 ש"ח</p>
          </td>
          <td style={{width: '65.8pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>4300 ש"ח</p>
          </td>
        </tr>
        <tr>
          <td style={{width: '59.7pt', border: 'solid #365F91 1.0pt', borderTop: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>פיתוח מבדק 2-&nbsp;<span style={{fontSize: '15px', lineHeight: '110%', fontFamily: '"Arial",sans-serif', color: 'black'}}>מוטוריקה עדינה</span></p>
          </td>
          <td style={{width: '91.25pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><span dir="LTR">&nbsp;</span></p>
          </td>
          <td style={{width: '91.4pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>מחיר הפיתוח בהתאם למורכבות הפיתוח ולשלבים השונים</p>
          </td>
          <td style={{width: '57.75pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>2000 ₪</p>
          </td>
          <td style={{width: '52.6pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>2000 ש"ח</p>
          </td>
          <td style={{width: '65.8pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>3700 ש"ח</p>
          </td>
        </tr>
        <tr>
          <td style={{width: '59.7pt', border: 'solid #365F91 1.0pt', borderTop: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>פיתוח מבדק 5-&nbsp;זכרון חזותי</p>
          </td>
          <td style={{width: '91.25pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><span dir="LTR">&nbsp;</span></p>
          </td>
          <td style={{width: '91.4pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>מחיר הפיתוח בהתאם למורכבות הפיתוח ולשלבים השונים</p>
          </td>
          <td style={{width: '57.75pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>1900 ₪</p>
          </td>
          <td style={{width: '52.6pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>1900 ש"ח</p>
          </td>
          <td style={{width: '65.8pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>1900 ש"ח</p>
          </td>
        </tr>
        <tr>
          <td style={{width: '59.7pt', border: 'solid #365F91 1.0pt', borderTop: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>פיתוח מבדק 6-&nbsp;<span style={{fontSize: '15px', lineHeight: '110%', fontFamily: '"Arial",sans-serif', color: 'black'}}>הבנת שפה</span></p>
          </td>
          <td style={{width: '91.25pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><span dir="LTR">&nbsp;</span></p>
          </td>
          <td style={{width: '91.4pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>מחיר הפיתוח בהתאם למורכבות הפיתוח ולשלבים השונים</p>
          </td>
          <td style={{width: '57.75pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>2400 ₪</p>
          </td>
          <td style={{width: '52.6pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>2400 ש"ח</p>
          </td>
          <td style={{width: '65.8pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>2400 ש"ח</p>
          </td>
        </tr>
        <tr>
          <td style={{width: '59.7pt', border: 'solid #365F91 1.0pt', borderTop: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>פיתוח מבדק 7- כישורי קריאה</p>
          </td>
          <td style={{width: '91.25pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><span dir="LTR">&nbsp;</span></p>
          </td>
          <td style={{width: '91.4pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>מחיר הפיתוח בהתאם למורכבות הפיתוח ולשלבים השונים</p>
          </td>
          <td style={{width: '57.75pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>2500 ₪</p>
          </td>
          <td style={{width: '52.6pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>2500 ש"ח</p>
          </td>
          <td style={{width: '65.8pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>2500 ש"ח</p>
          </td>
        </tr>
        <tr>
          <td style={{width: '59.7pt', border: 'solid #365F91 1.0pt', borderTop: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>פיתוח מבדק 8-&nbsp;קישורים חברתיים</p>
          </td>
          <td style={{width: '91.25pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><span dir="LTR">&nbsp;</span></p>
          </td>
          <td style={{width: '91.4pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>מחיר הפיתוח בהתאם למורכבות הפיתוח ולשלבים השונים</p>
          </td>
          <td style={{width: '57.75pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>1400 ₪</p>
          </td>
          <td style={{width: '52.6pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>1400 ש"ח</p>
          </td>
          <td style={{width: '65.8pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>1400 ש"ח</p>
          </td>
        </tr>
        <tr>
          <td style={{width: '59.7pt', border: 'solid #365F91 1.0pt', borderTop: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>פיתוח מבדק 9- שליטה והתגברות</p>
          </td>
          <td style={{width: '91.25pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><span dir="LTR">&nbsp;</span></p>
          </td>
          <td style={{width: '91.4pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>מחיר הפיתוח בהתאם למורכבות הפיתוח ולשלבים השונים</p>
          </td>
          <td style={{width: '57.75pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>3200 ₪</p>
          </td>
          <td style={{width: '52.6pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>3200 ש"ח</p>
          </td>
          <td style={{width: '65.8pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>3200 ש"ח</p>
          </td>
        </tr>
        <tr>
          <td style={{width: '59.7pt', border: 'solid #365F91 1.0pt', borderTop: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>פיתוח מבדק 10-&nbsp;קשב</p>
          </td>
          <td style={{width: '91.25pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><span dir="LTR">&nbsp;</span></p>
          </td>
          <td style={{width: '91.4pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>מחיר הפיתוח בהתאם למורכבות הפיתוח ולשלבים השונים</p>
          </td>
          <td style={{width: '57.75pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>1400 ₪</p>
          </td>
          <td style={{width: '52.6pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>1400 ש"ח</p>
          </td>
          <td style={{width: '65.8pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>1400 ₪</p>
          </td>
        </tr>
        <tr>
          <td style={{width: '59.7pt', border: 'solid #365F91 1.0pt', borderTop: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>פיתוח שרת להרשאות (כולל&nbsp;<span dir="LTR">DB</span> – הקמה)</p>
          </td>
          <td style={{width: '91.25pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>לצורך זיהוי והגנה מפני העתקת האפליקציה</p>
          </td>
          <td style={{width: '91.4pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>לא ניתן לאבטח באופן מוחלט מפני פריצות והעתקות, עם זאת השימוש בשרת מקטין משמעותית את סיכויי גניבת האפליקציה</p>
          </td>
          <td style={{width: '57.75pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>7800ש"ח</p>
          </td>
          <td style={{width: '52.6pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>7800ש"ח</p>
          </td>
          <td style={{width: '65.8pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>7800ש"ח</p>
          </td>
        </tr>
        <tr>
          <td style={{width: '59.7pt', border: 'solid #365F91 1.0pt', borderTop: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>פיתוח מדור הורים – צד שרת</p>
          </td>
          <td style={{width: '91.25pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>השרת כולל&nbsp;<span dir="LTR">DB</span> ומערכת לעיבוד נתוני התקדמות הילד</p>
          </td>
          <td style={{width: '91.4pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>&nbsp;</p>
          </td>
          <td style={{width: '57.75pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: 'Tahoma, sans-serif', textAlign: 'left'}}>6900 ₪</p>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: 'Tahoma, sans-serif', textAlign: 'left'}}>&nbsp;</p>
          </td>
          <td style={{width: '52.6pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: 'Tahoma, sans-serif', textAlign: 'left'}}>6900 ₪</p>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: 'Tahoma, sans-serif', textAlign: 'left'}}>&nbsp;</p>
          </td>
          <td style={{width: '65.8pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: 'Tahoma, sans-serif', textAlign: 'left'}}>6900 ₪</p>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: 'Tahoma, sans-serif', textAlign: 'left'}}>&nbsp;</p>
          </td>
        </tr>
        <tr>
          <td style={{width: '59.7pt', border: 'solid #365F91 1.0pt', borderTop: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>פיתוח מדור הורים – צד לקוח</p>
          </td>
          <td style={{width: '91.25pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>כולל תצוגת התקדמות הילד בהתאם לתחומים השונים</p>
          </td>
          <td style={{width: '91.4pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>יופיע בצרוף לאתר השיווקי לאפליקציה</p>
          </td>
          <td style={{width: '57.75pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: 'Tahoma, sans-serif', textAlign: 'left'}}>5400 ₪</p>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: 'Tahoma, sans-serif', textAlign: 'left'}}>&nbsp;</p>
          </td>
          <td style={{width: '52.6pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: 'Tahoma, sans-serif', textAlign: 'left'}}>5400 ₪</p>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: 'Tahoma, sans-serif', textAlign: 'left'}}>&nbsp;</p>
          </td>
          <td style={{width: '65.8pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: 'Tahoma, sans-serif', textAlign: 'left'}}>5400 ₪</p>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: 'Tahoma, sans-serif', textAlign: 'left'}}>&nbsp;</p>
          </td>
        </tr>
        <tr>
          <td style={{width: '59.7pt', border: 'solid #365F91 1.0pt', borderTop: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>מערת גביה, כולל סליקה – הקמה</p>
          </td>
          <td style={{width: '91.25pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><span dir="LTR">&nbsp;</span></p>
          </td>
          <td style={{width: '91.4pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>מערכת הסליקה מסופקת ע"י גורם חיצוני</p>
          </td>
          <td style={{width: '57.75pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>700 ₪</p>
          </td>
          <td style={{width: '52.6pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>700 ש"ח</p>
          </td>
          <td style={{width: '65.8pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>700 ש"ח</p>
          </td>
        </tr>
        <tr>
          <td style={{width: '59.7pt', border: 'solid #365F91 1.0pt', borderTop: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>מערת גביה, כולל סליקה, תשלום קבוע חודשי</p>
          </td>
          <td style={{width: '91.25pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>&nbsp;</p>
          </td>
          <td style={{width: '91.4pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>מערכת הסליקה מסופקת ע"י גורם חיצוני</p>
          </td>
          <td style={{width: '57.75pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>112 ₪</p>
          </td>
          <td style={{width: '52.6pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>שנה ראשונה – <strong>מתנה</strong>!!</p>
          </td>
          <td style={{width: '65.8pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>0 ש"ח</p>
          </td>
        </tr>
        <tr>
          <td style={{width: '59.7pt', border: 'solid #365F91 1.0pt', borderTop: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>אתר שיווקי לאפליקציה</p>
          </td>
          <td style={{width: '91.25pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><span dir="LTR">&nbsp;</span></p>
          </td>
          <td style={{width: '91.4pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>מדור הורים, שיופיע בצרוף לאתר זה, חושב בנפרד</p>
          </td>
          <td style={{width: '57.75pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: 'Tahoma, sans-serif', textAlign: 'left'}}>3500 ₪</p>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: 'Tahoma, sans-serif', textAlign: 'left'}}><span dir="LTR">&nbsp;</span></p>
          </td>
          <td style={{width: '52.6pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}><strong>מתנה</strong>!!</p>
          </td>
          <td style={{width: '65.8pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>0 ש"ח</p>
          </td>
        </tr>
        <tr>
          <td style={{width: '59.7pt', border: 'solid #365F91 1.0pt', borderTop: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>עלות החזקת ה&nbsp;<span dir="LTR">DB</span></p>
          </td>
          <td style={{width: '91.25pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>תשלום משתנה בהתאם לגודל ה&nbsp;<span dir="LTR">DB</span> והתנועה שלו</p>
          </td>
          <td style={{width: '91.4pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}><span dir="LTR">&nbsp;</span></p>
          </td>
          <td style={{width: '57.75pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: 'Tahoma, sans-serif', textAlign: 'left'}}>700 ₪</p>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: 'Tahoma, sans-serif', textAlign: 'left'}}><span dir="LTR">&nbsp;</span></p>
          </td>
          <td style={{width: '52.6pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>שנה ראשונה <strong>מתנה</strong>!!, עד 200 משתמשים</p>
          </td>
          <td style={{width: '65.8pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>0 ש"ח</p>
          </td>
        </tr>
        <tr>
          <td style={{width: '59.7pt', border: 'solid #365F91 1.0pt', borderTop: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>אחסון ל 2 האתרים (לשנה)</p>
          </td>
          <td style={{width: '91.25pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>תשלום שנתי קבוע</p>
          </td>
          <td style={{width: '91.4pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>מחיר משתנה (המחיר הנ"ל נכון להיום)</p>
          </td>
          <td style={{width: '57.75pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: 'Tahoma, sans-serif', textAlign: 'left'}}>480 ₪</p>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: 'Tahoma, sans-serif', textAlign: 'left'}}>(240 ₪ לאתר)</p>
          </td>
          <td style={{width: '52.6pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>שנה ראשונה <strong>מתנה</strong>!!</p>
          </td>
          <td style={{width: '65.8pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>0 ש"ח</p>
          </td>
        </tr>
        <tr>
          <td style={{width: '59.7pt', border: 'solid #365F91 1.0pt', borderTop: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>2 דומיינים (לשנה)</p>
          </td>
          <td style={{width: '91.25pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>תשלום שנתי קבוע</p>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>אי תשלום החזקת הבעלות על הדומיין בטרם סיום התקופה, מעבירה את מלא הזכויות למוטו, ולא תמיד ניתן לרכישה מחדש)</p>
          </td>
          <td style={{width: '91.4pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>מחיר משתנה (המחיר הנ"ל נכון להיום)</p>
          </td>
          <td style={{width: '57.75pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: 'Tahoma, sans-serif', textAlign: 'left'}}>360 ₪</p>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: 'Tahoma, sans-serif', textAlign: 'left'}}>(180 ₪ לדומיין לשנה)</p>
          </td>
          <td style={{width: '52.6pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>שנה ראשונה <strong>מתנה</strong>!!</p>
          </td>
          <td style={{width: '65.8pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>0 ש"ח</p>
          </td>
        </tr>
        <tr>
          <td style={{width: '59.7pt', border: 'solid #365F91 1.0pt', borderTop: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>העלאה לחנות</p>
          </td>
          <td style={{width: '91.25pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><span dir="LTR">&nbsp;</span></p>
          </td>
          <td style={{width: '91.4pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}><span dir="LTR">&nbsp;</span></p>
          </td>
          <td style={{width: '57.75pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>300 ₪</p>
          </td>
          <td style={{width: '52.6pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}><strong>מתנה</strong>!!</p>
          </td>
          <td style={{width: '65.8pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>0 ש"ח</p>
          </td>
        </tr>
        <tr>
          <td style={{width: '59.7pt', border: 'solid #365F91 1.0pt', borderTop: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>העלאת האתרים לרשת</p>
          </td>
          <td style={{width: '91.25pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><span dir="LTR">&nbsp;</span></p>
          </td>
          <td style={{width: '91.4pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}><span dir="LTR">&nbsp;</span></p>
          </td>
          <td style={{width: '57.75pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid #365F91 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>200 ₪</p>
          </td>
          <td style={{width: '52.6pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid windowtext 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}><strong>מתנה</strong>!!</p>
          </td>
          <td style={{width: '65.8pt', borderTop: 'none', borderLeft: 'solid #365F91 1.0pt', borderBottom: 'solid windowtext 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>0 ש"ח</p>
          </td>
        </tr>
        <tr>
          <td colSpan={2} style={{width: '150.95pt', border: 'none', borderBottom: 'solid #365F91 1.0pt', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><span dir="LTR">&nbsp;</span></p>
          </td>
          <td style={{width: '91.4pt', border: 'none', borderBottom: 'solid #365F91 1.0pt', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{marginTop: '1.0pt', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', textAlign: 'left', fontSize: '10px', fontFamily: '"Tahoma",sans-serif', fontWeight: 'bold'}}>&nbsp;</p>
          </td>
          <td style={{width: '57.75pt', border: 'none', borderBottom: 'solid #365F91 1.0pt', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{marginTop: '1.0pt', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', textAlign: 'left', fontSize: '10px', fontFamily: '"Tahoma",sans-serif', fontWeight: 'bold'}}><span dir="LTR">&nbsp;</span></p>
          </td>
          <td style={{width: '52.6pt', borderTop: 'none', borderLeft: 'solid windowtext 1.0pt', borderBottom: 'solid windowtext 1.0pt', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}><strong>סכום ביניים</strong></p>
          </td>
          <td style={{width: '65.8pt', borderTop: 'none', borderLeft: 'solid windowtext 1.0pt', borderBottom: 'solid windowtext 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: 'Tahoma, sans-serif', textAlign: 'left'}}>77,440 ₪&nbsp;</p>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: 'Tahoma, sans-serif', textAlign: 'left'}}><span dir="LTR">&nbsp;</span></p>
          </td>
        </tr>
        <tr>
          <td colSpan={2} style={{width: '150.95pt', border: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><span dir="LTR">&nbsp;</span></p>
          </td>
          <td style={{width: '91.4pt', border: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{marginTop: '1.0pt', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', textAlign: 'left', fontSize: '10px', fontFamily: '"Tahoma",sans-serif', fontWeight: 'bold'}}>&nbsp;</p>
          </td>
          <td style={{width: '57.75pt', border: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{marginTop: '1.0pt', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', textAlign: 'left', fontSize: '10px', fontFamily: '"Tahoma",sans-serif', fontWeight: 'bold'}}><span dir="LTR">&nbsp;</span></p>
          </td>
          <td style={{width: '52.6pt', border: 'none', borderLeft: 'solid windowtext 1.0pt', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}><strong>מע"ם</strong></p>
          </td>
          <td style={{width: '65.8pt', borderTop: 'none', borderLeft: 'solid windowtext 1.0pt', borderBottom: 'solid windowtext 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}><span dir="LTR" style={{fontFamily: '"Arial",sans-serif', color: 'black', background: 'white'}}>13,164.80</span> ₪&nbsp;</p>
          </td>
        </tr>
        <tr>
          <td colSpan={2} style={{width: '150.95pt', border: 'none', borderTop: 'solid #365F91 1.0pt', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><span dir="LTR">&nbsp;</span></p>
          </td>
          <td style={{width: '91.4pt', border: 'none', borderTop: 'solid #365F91 1.0pt', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{marginTop: '1.0pt', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', textAlign: 'left', fontSize: '10px', fontFamily: '"Tahoma",sans-serif', fontWeight: 'bold'}}>&nbsp;</p>
          </td>
          <td style={{width: '57.75pt', border: 'none', borderTop: 'solid #365F91 1.0pt', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{marginTop: '1.0pt', marginRight: '0cm', marginBottom: '0cm', marginLeft: '0cm', textAlign: 'left', fontSize: '10px', fontFamily: '"Tahoma",sans-serif', fontWeight: 'bold'}}><span dir="LTR">&nbsp;</span></p>
          </td>
          <td style={{width: '52.6pt', borderTop: 'solid windowtext 1.0pt', borderLeft: 'solid windowtext 1.0pt', borderBottom: 'none', borderRight: 'none', padding: '2.15pt 5.75pt 2.15pt 5.75pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}><strong>סך הכל</strong></p>
          </td>
          <td style={{width: '65.8pt', borderTop: 'none', borderLeft: 'solid windowtext 1.0pt', borderBottom: 'solid windowtext 1.0pt', borderRight: 'none', padding: '2.15pt 10.8pt 2.15pt 10.8pt', height: '14.4pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif', color: 'black', border: 'none windowtext 1.0pt', padding: '0cm', background: '#F8F8F8'}}>₪</span><span dir="LTR" style={{fontFamily: '"Arial",sans-serif', color: 'black', border: 'none windowtext 1.0pt', padding: '0cm', background: '#F8F8F8'}}>90,604.80</span></p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><span dir="LTR">&nbsp;</span></p>
  <div align="right" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif'}}>
    <table dir="rtl" style={{border: 'none', width: '100.0%', borderCollapse: 'collapse'}} summary="הזן שם עבור 'הצעת המחיר הוכנה על-ידי', הצהרה, חתימה לקבלה והודעת תודה בטבלה זו">
      <tbody>
        <tr>
          <td style={{width: '504.0pt', padding: '21.6pt 5.75pt 0cm 5.75pt', height: '72.0pt'}}>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '10px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>הצעת המחיר הוכנה על-ידי: עומר שירן</p>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '10px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><span dir="LTR">&nbsp;</span></p>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>זוהי הצעת מחיר עבור המוצרים המפורטים, בכפוף לתנאים שמצוינים להלן:&nbsp;בנספח להצעת מחיר, נספח זה הוא חלק בלתי נפרד מהצעת מחיר זו</p>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '10px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><span style={{fontSize: '11px', lineHeight: '110%'}}>&nbsp;</span></p>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '10px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>הצעת מחיר זאת כוללת את הסדרי התשלום, לוחות הזמנים ואת נספח ההערות</p>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '10px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><span dir="LTR">&nbsp;</span></p>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '10px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>כדי לקבל הצעת מחיר זו, יש לחתום כאן ולהחזיר אלינו:&nbsp;__________________________________________________________________________________&nbsp;<br />&nbsp;</p>
            <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '10px', fontFamily: 'Tahoma, sans-serif', textAlign: 'right'}}>תאריך:_________________________________</p>
          </td>
        </tr>
        <tr>
          <td style={{width: '504.0pt', padding: '5.75pt 5.75pt 0cm 5.75pt', height: '23.5pt'}}>
            <p dir="RTL" style={{margin: '0cm', textAlign: 'left', lineHeight: '110%', fontSize: '13px', fontFamily: '"Tahoma",sans-serif', fontWeight: 'bold'}}>נעים לעשות איתכם עסקים!</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>&nbsp;</p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>&nbsp;</p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>&nbsp;</p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><strong><u><span style={{fontSize: '13px', lineHeight: '110%'}}>נספח להצעת מחיר</span></u></strong></p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>&nbsp;</p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><strong><u>לו"ז (שלבי הפיתוח + הערכת זמנים):</u></strong></p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>תחילת העבודה<u>&nbsp;</u>– עם החתימה</p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><u>שלב ראשון&nbsp;</u>–נספחי פיתוח (עיצובים, מלל, צללים וכו') + השלמת האפיון +בנית תוכנית האפליקציה- משך שלב זה כ3 חודשים + הזמן הנדרש לשינויים שיתבקשו ע"י הלקוח, הכלולים בחבילה.</p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><u>שלב שני</u> – בניית הפריסות, והאתרים השיווקיים- כ חודשיים + הזמן הנדרש לשינויים שיתבקשו ע"י הלקוח, הכלולים בחבילה.</p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><u>שלב שלישי</u> - &nbsp;פיתוח שרת להרשאות ולמעקב וניתוח נתוני הילדים – כ שלושה חודשים.</p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><u>שלב רביעי</u> – השלמת האפליקציה ומדור ההורים באתר- כ שלושה חודשים + הזמן הנדרש לשינויים שיתבקשו ע"י הלקוח, הכלולים בחבילה.</p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>צפי משוער לפיתוח האפליקציה – 12 חודשים</p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>הזמנים הנ"ל משוערים ויתכנו עיכובים.</p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><u><span style={{textDecoration: 'none'}}>&nbsp;</span></u></p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><strong><u>תנאי תשלום:</u></strong></p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>הסכום הכללי יחולק לתשלומים בהתאם לשלבי הפיתוח השונים:</p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>עם החתימה – תשלום ראשון – (לפני שלב ראשון) – 9,700 ₪</p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>תשלום שני, חודשיים לאחר החתימה – (לפני שלב שני) 18,650 ₪&nbsp;</p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>תשלום שלישי – (לפני שלב שלישי), 4 חודשים לאחר החתימה- 17,500 ₪&nbsp;</p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>תשלום רביעי &nbsp;– (לפני שלב רביעי),7 חודשים לאחר החתימה- 10,530 ₪</p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>תשלום חמישי – עם השלמת הפרוייקט ולא יאוחר מחודש לאחר קבלת ההודעה על השלמת ביצוע הפרוייקט- 6000 ₪</p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>תשלום שישי – לאחר תקינות הפרוייקט ע"י הלקוח ולא יאוחר מ 3 חודשים לאחר קבלת ההודעה על השלמת הפרוייקט – 4,000 ₪ &nbsp;</p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>&nbsp;</p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>זכויות בפיתוח ובנכסים הנספחים לפיתוח מותנת בהשלמת מלא התשלומים</p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>ביצוע כל שלב מותנה בהשלמת השלב הקודם ובאישור הנדרש ע"י הלקוח</p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>התחלת כל שלב מותנית בהשלמת מלא התשלום המקדים לאותו שלב</p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>אי עמידה בזמני התשלומים - אי העברת התשלומים במועדן, יפטור את מוטו מכל מחוייבות להמשך ביצוע הפרוייקט וללא כל צורך בפיצוי או בהחזר התשלומים שהועברו (כולן או חלקן), לעניין סעיף זה, איחור של עד 30 יום במועד התשלום,לא יחשב כ אי עמידה בזמנים</p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><u><span style={{textDecoration: 'none'}}>&nbsp;</span></u></p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><u>גיוס משקיעים:</u></p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>לאחר השלמת התשלום השלישי,ולפחות 50% מכלל התשלומים, ובאם יבוקש כן ע"י הלקוח במפורש, מוטו יגייס השקעה אופציונית, בהתאם לתנאים ולהסכם גיוס משקיעים שיחתם בן הלקוח למוטו</p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>הסבר כללי על התהליך ועל יחסי ההשקעה והבעלות המקובלים אצלינו ניתן לקבל בקישור-<span dir="LTR">https://motto.co.il/InvestorRecruitment</span></p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><strong><u><span style={{textDecoration: 'none'}}>&nbsp;</span></u></strong></p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><strong><u>נספח הערות:</u></strong></p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>המחירים לא כוללים את מחירי האחסון וההחזקה מעבר לשנה הראשונה.</p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>המחירים אינם כוללים החזקה שוטפת, שידרוגים ושינויי תכנים, אם ידרשו.&nbsp;</p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>אין אחריות מפני פריצות והעתקות.</p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>המחירים אינם כוללים מע"ם.</p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>הצעת מחיר זו תקפה לחודש בלבד<s>&nbsp;</s></p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}>&nbsp;</p>
  <p dir="RTL" style={{margin: '0cm', lineHeight: '110%', fontSize: '11px', fontFamily: '"Tahoma",sans-serif', textAlign: 'right'}}><span dir="LTR">&nbsp;</span></p>
</div>)
},
agent_preparations:{name:"הוראות הכנה לאיש מכירות",
text:(<div style={{color:"white"}}>
<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>דף זה מיועד אך ורק למי שאושר לעבוד כסוכן!</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><strong><u><span style={{fontSize: '19px', lineHeight: '115%', fontFamily: '"Arial","sans-serif"'}}>הכנות לתחילת העבודה כסוכן\ איש מכירות</span></u></strong></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><strong><span style={{fontFamily: '"Arial","sans-serif"'}}>&nbsp;</span></strong></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><strong><span style={{fontFamily: '"Arial","sans-serif"'}}>יש להוריד את ההסכם, לחתום עליו ולשלוח אלי</span></strong></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>בקישור:</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><a href="https://drive.google.com/file/d/1r2-5WfyPxgDliusFyXDxDSK_9ODjs0Wf/view?usp=sharing"><span dir="LTR">https://drive.google.com/file/d/1r2-5WfyPxgDliusFyXDxDSK_9ODjs0Wf/view?usp=sharing</span></a></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>&nbsp;</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>כמו כן, יש ללמוד את החומרים המצורפים</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>&nbsp;</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>אני חושב שטוב שנתחיל מהעמודים הבאים: (אותם מומלץ לקרא ממש, וכל מה שלא ברור, תשאל אותי!!!)</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><a href="https://motto.co.il/%D7%9E%D7%97%D7%99%D7%A8%D7%99%D7%9D"><span dir="LTR" style={{color: 'windowtext'}}>https://motto.co.il/%D7%9E%D7%97%D7%99%D7%A8%D7%99%D7%9D</span></a></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><a href="https://motto.co.il/Promotion-direct-order"><span dir="LTR" style={{color: 'windowtext'}}>https://motto.co.il/Promotion-direct-order</span></a></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><a href="https://motto.co.il/%D7%9E%D7%97%D7%99%D7%A8%D7%99%20%D7%A7%D7%99%D7%93%D7%95%D7%9D%20%D7%9E%D7%9E%D7%95%D7%9E%D7%9F"><span dir="LTR" style={{color: 'windowtext'}}>https://motto.co.il/%D7%9E%D7%97%D7%99%D7%A8%D7%99%20%D7%A7%D7%99%D7%93%D7%95%D7%9D%20%D7%9E%D7%9E%D7%95%D7%9E%D7%9F</span></a></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><a href="https://motto.co.il/%D7%9E%D7%97%D7%99%D7%A8%D7%99%20%D7%A4%D7%99%D7%AA%D7%95%D7%97"><span dir="LTR" style={{color: 'windowtext'}}>https://motto.co.il/%D7%9E%D7%97%D7%99%D7%A8%D7%99%20%D7%A4%D7%99%D7%AA%D7%95%D7%97</span></a></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><a href="https://motto.co.il/#DevelopmentProcess"><span dir="LTR" style={{color: 'windowtext'}}>https://motto.co.il/#DevelopmentProcess</span></a></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><a href="https://motto.co.il/Promotion"><span dir="LTR" style={{color: 'windowtext'}}>https://motto.co.il/Promotion</span></a></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>&nbsp;</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>מומלץ גם להעיף מבט ב:</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><a href="https://motto.co.il/Appendices/Values/toxic_links_story"><span dir="LTR" style={{color: 'windowtext'}}>https://motto.co.il/Appendices/Values/toxic_links_story</span></a></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><a href="https://motto.co.il/Appendices/Values/bid_example"><span dir="LTR" style={{color: 'windowtext'}}>https://motto.co.il/Appendices/Values/bid_example</span></a><span style={{fontFamily: '"Arial","sans-serif"'}}>&nbsp;(עבור אפליקציות ואתרים דינמיים)</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>&nbsp;</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>&nbsp;</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>&nbsp;</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>&nbsp;</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>&nbsp;</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>אני מצרף רשימת מושגים, חלקם בסיסיים יותר וחלקם פחות</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>מיתוך מאגר של מושגים שיש לי כבר מוכנים, באתר</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>שים לב לכל תוכן אתה יכול להגיע ישירות בחיפוש גוגל בפי הערך ובצרוף המילה מוטו , סביר להניח שתגיע להסבר הערך באתר שלנו</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>כמו כן, כל מה שכתבתי לך כאן זה קישורים, כך שאתה יכול ללחוץ עליהם ולהגיע להסבר הערך</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>&nbsp;</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>ישנם עוד הרבה מושגים אבל אפשר להתחיל מאלו</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>&nbsp;</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>פיתוח אפליקציות&nbsp;</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}><a href="https://motto.co.il/Dictionaries/programming/Values/HTML"><span dir="LTR" style={{color: 'windowtext'}}>HTML</span></a></span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}><a href="https://motto.co.il/Dictionaries/programming/Values/FRONTEND"><span style={{color: 'windowtext'}}>פרונט-אנד</span><span dir="LTR" style={{color: 'windowtext'}}>&nbsp;(FRONTEND) /&nbsp;</span><span style={{color: 'windowtext'}}>ממשק צד לקוח</span></a></span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}><a href="https://motto.co.il/Dictionaries/programming/Values/HOSTING"><span style={{color: 'windowtext'}}>אירוח אתרים</span><span dir="LTR" style={{color: 'windowtext'}}>&nbsp;(HOSTING)</span></a></span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}><a href="https://motto.co.il/Dictionaries/programming/Values/COOKIES"><span style={{color: 'windowtext'}}>עוגיות</span><span dir="LTR" style={{color: 'windowtext'}}>&nbsp;(COOKIES)</span></a></span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}><a href="https://motto.co.il/Dictionaries/programming/Values/SITEMAP"><span style={{color: 'windowtext'}}>מפת אתר</span><span dir="LTR" style={{color: 'windowtext'}}>&nbsp;, SITEMAP)</span></a></span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}><a href="https://motto.co.il/Dictionaries/programming/Values/UI_DESIGN"><span style={{color: 'windowtext'}}>עיצוב ממשק משתמש</span><span dir="LTR" style={{color: 'windowtext'}}>&nbsp;(UI DESIGN)</span></a></span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}><a href="https://motto.co.il/Dictionaries/programming/Values/WEB_APP"><span style={{color: 'windowtext'}}>אפליקציית אינטרנט / אפליקציית ווב / ישום אינטרנט</span><span dir="LTR" style={{color: 'windowtext'}}>&nbsp;(WEB APP)</span></a></span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}><a href="https://motto.co.il/Dictionaries/programming/Values/WEB_SERVER"><span style={{color: 'windowtext'}}>שרת אינטרנט</span><span dir="LTR" style={{color: 'windowtext'}}>&nbsp;(WEB SERVER)</span></a></span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}><a href="https://motto.co.il/Dictionaries/programming/Values/UX_DESIGN"><span style={{color: 'windowtext'}}>עיצוב חווית משתמש</span><span dir="LTR" style={{color: 'windowtext'}}>&nbsp;(UX DESIGN)</span></a></span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}><a href="https://motto.co.il/Dictionaries/programming/Values/DATABASE"><span style={{color: 'windowtext'}}>מסד נתונים (דטא-בייס, מאגר מידע</span><span dir="LTR" style={{color: 'windowtext'}}>, DATABASE)</span></a></span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}><a href="https://motto.co.il/Dictionaries/programming/Values/DOMAIN"><span style={{color: 'windowtext'}}>שם דומיין</span><span dir="LTR" style={{color: 'windowtext'}}>&nbsp;(DOMAIN NAME,&nbsp;</span><span style={{color: 'windowtext'}}>שם מתחם</span><span dir="LTR" style={{color: 'windowtext'}}>)</span></a></span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>&nbsp;</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>&nbsp;</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>&nbsp;</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>קידום אורגני &nbsp;וקידום ממומן-&nbsp;</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>&nbsp;</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}><a href="https://motto.co.il/Dictionaries/SEO/Values/keywords"><span dir="LTR" style={{color: 'windowtext'}}><br />&nbsp;</span><span style={{color: 'windowtext'}}>מילות מפתח</span></a></span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}><a href="https://motto.co.il/Dictionaries/SEO/Values/KeywordAnalysis"><span style={{color: 'windowtext'}}>ניתוח מילות מפתח</span></a></span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}><a href="https://motto.co.il/Dictionaries/SEO/Values/WebsiteOrWebpage"><span style={{color: 'windowtext'}}>קידום אתר או קידום עמוד</span></a></span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}><a href="https://motto.co.il/Dictionaries/SEO/Values/url"><span style={{color: 'windowtext'}}>כתובת אתר</span><span dir="LTR" style={{color: 'windowtext'}}>&nbsp;– URL</span></a></span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}><a href="https://motto.co.il/Dictionaries/SEO/Values/redirect"><span style={{color: 'windowtext'}}>הפניה מחדש</span><span dir="LTR" style={{color: 'windowtext'}}>&nbsp;(Redirect)</span></a></span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}><a href="https://motto.co.il/Dictionaries/SEO/Values/toxic_links"><span style={{color: 'windowtext'}}>קישורים רעילים</span><span dir="LTR" style={{color: 'windowtext'}}>&nbsp;(Toxic links)</span></a></span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}><a href="https://motto.co.il/Dictionaries/SEO/Values/canonical_tag"><span style={{color: 'windowtext'}}>תג קנוני</span><span dir="LTR" style={{color: 'windowtext'}}>&nbsp;(canonical tag)</span></a></span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>&nbsp;</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>&nbsp;</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>&nbsp;</span></p>
</div>
)},
Opening_call_to_agent:{name:"שיחת פתיחה לאיש מכירות",
text:(<div style={{color:"white"}}>

<p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>שיחת פתיחה עם נציג&nbsp;</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>(לאחר הכרות,</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>החלטת עבודה,</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>חתימת חוזה +ת"ז +תעוד,</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>שליחת קישורים לצפיה)</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>&nbsp;</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>לעבור ביחד על התוכניות השונות באתר</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>ועל דפי התשלום</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>לעבור על דוגמאות שבנינו ולתת קישורים (תיק עבודות)</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>לעבור על אפיקי לקוחות שונים (פרסום בגוגל,&nbsp;</span><span dir="LTR">xplace</span><span style={{fontFamily: '"Arial","sans-serif"'}}>), ואיך אתה מציג את עצמך לכל לקוח</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>על כל לקוח יש לעשות שני סיכומים:</span></p>
        <p><span style={{fontFamily: '"Arial","sans-serif"'}}>סיכום שיחה שכולל:</span></p>
        <ol style={{listStyleType: 'decimal'}}>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>טלפון</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>שם</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>אופן יצירת קשר</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>זמן יצירת קשר</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>אופני יצירת קשר נוספים</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>תוכן הפניה</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>התרשמות כללית</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>הפעולה הבאה</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>זמן הפעולה הבאה</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>מצב</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>הערות</span><span dir="LTR">&nbsp;</span><br /><span style={{fontFamily: '"Arial","sans-serif"'}}><br /></span></li>
        </ol>
        <p><span style={{fontFamily: '"Arial","sans-serif"'}}><br /></span></p>
        <p><span style={{fontFamily: '"Arial","sans-serif"'}}>סטאטוס שכולל:</span></p>
        <ul>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>טלפון</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>דרישה (מה הוא רוצה)</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>תוכנית להמשך</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>מועד חזרה</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>מצב</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>הערות</span></li>
        </ul>
        <br />
        <br />
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>איזה פרטים אני צריך שתקח מכל לקוח: (לאחר סגירת עסקה ותשלום)</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>קידום אורגני:</span></p>
        <ul style={{listStyleType: 'undefined'}}>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>כתובת האתר&nbsp;</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>דפים בעלי עדיפות לקידום</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>הסבר מפורט על האתר והעסק</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>הסבר ממוקד על האתר והעסק</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>תחומי עיסוק (למשל לשיפוצניק: אינסטלציה, שיפוצים, חשמל, צבע...)</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>מילות מפתח (למשל:תיקון נזילות, &nbsp;חידוש צנרת, פתיחת סתימות...)</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>בתוכניות המורחבות: מאמרים</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>נקודות חוזקה</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>אופציונאלי: כתובות אתרי המתחרים</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>התוכנית שבחר (בסיסית, מתקדמת וכו')</span></li>
        </ul>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>קידום ממומן:</span></p>
        <ul style={{listStyleType: 'undefined'}}>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>כתובת האתר&nbsp;</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>דפים בעלי עדיפות לקידום (לאן אתה מעוניין שהלקוחות יגיעו)</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>הסבר מפורט על האתר והעסק</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>הסבר ממוקד על האתר והעסק</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>תחומי עיסוק (למשל לשיפוצניק: אינסטלציה, שיפוצים, חשמל, צבע...)</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>מילות מפתח (למשל:תיקון נזילות, &nbsp;חידוש צנרת, פתיחת סתימות...)</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>נקודות חוזקה, מבצעים וכדומה</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>אופציונאלי: כתובות אתרי המתחרים</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>תכנים לקידום (למשל לקדם מבצע החלפת תריס ב 280 ₪ )</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>התוכנית שבחר (בסיסית, מתקדמת וכו')</span></li>
        </ul>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '36.0pt', marginBottom: '.0001pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><br /></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '36.0pt', marginBottom: '.0001pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><br /></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '36.0pt', marginBottom: '.0001pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span dir="LTR">&nbsp;</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '36.0pt', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>&nbsp;</span></p>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '0cm', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>בנית אתר סטטי רספונסיבי</span></p>
        <ul style={{listStyleType: 'undefined'}}>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>איזה עמודים אני רוצה באתר</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>כל המלל הנדרש לבניה&nbsp;</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>תמונות העסק</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>סגנון ואופי</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>דוגמה לאתר שמצא חן בעיני</span></li>
          <li><span style={{fontFamily: '"Arial","sans-serif"'}}>התוכנית שבחר (בסיסית, מתקדמת וכו')</span></li>
        </ul>
        <p dir="RTL" style={{marginTop: '0cm', marginRight: '36.0pt', marginBottom: '10.0pt', marginLeft: '0cm', textAlign: 'right', lineHeight: '115%', fontSize: '15px', fontFamily: '"Calibri","sans-serif"'}}><span style={{fontFamily: '"Arial","sans-serif"'}}>&nbsp;</span></p>
     
</div>)}
}




export default keysObject