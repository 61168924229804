import React from 'react'
import { ThemeProvider } from 'styled-components';
import { theme } from '../../../common/src/theme/appModern';
import { ResetCSS } from '../../../common/src/assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from '../Navbar';
import Banner from './Banner'
import Footer from '../Footer';
import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from '../appModern.style';
export default function() {
  return (
    <ThemeProvider theme={theme}>
      <>
       
          <title>motto</title>
          <meta name="Description" content="motto landing page" />
          <meta name="theme-color" content="#2563FF" />
          <meta
            name="keywords"
            content="motto,מערכות מחשב,קוד,react,java script,nodejs,server,java, c#, .net, תכנות,פיתוח, מיחשוב, סטארט-אפ,מחשבים,מערכות מתקדמות,אפליקציות, תוכנות,programing, program,שרת, ארוח אתרים,hosting, server, בניית אתר, בניית אתרים, בנייה, עיצוב, אחסון, דומיין, פיתוח אתרים, פיתוח אפליקציות, עיצוב אתר, אתר מוכן, תבניות אתר, אתר אינטרנט, אתרי אינטרנט, הקמת אתר, קידום אתרים, אתר מותאם לנייד,בית תוכנה,web,site,ווב,סייט,בנית אתר,בנית אתרים,מוטו"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Heebo:300,400,500,700&display=swap"
            rel="stylesheet"
          />
        
        <ResetCSS />
        <GlobalStyle />
        <AppWrapper dir="rtl">
          <Sticky top={0} innerZ={9999} activeClass="sticky-active">
            <Navbar />
          </Sticky>
          <ContentWrapper>
            <Banner />
          </ContentWrapper>
          <Footer />
        </AppWrapper>
      </>
    </ThemeProvider>
  );
}
