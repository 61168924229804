import React ,{useState}from 'react';
import ServiceOffer from '../ServiceOfferText.js'


const Banner = () => {

  return (<ServiceOffer/> );
};

export default Banner;
