import React from 'react'
import { ThemeProvider } from 'styled-components';
import { theme } from '../../common/src/theme/appModern';
import { ResetCSS } from '../../common/src/assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from './Navbar';
import BannerWrapper from './Banner/banner.style';
import Footer from './Footer';
import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from './appModern.style';


export default function() {
  return (
    <ThemeProvider theme={theme}>
      <>
      
          <title>motto</title>
          <meta name="Description" content="motto landing page" />
          <meta name="theme-color" content="#2563FF" />
          <meta
            name="keywords"
            content="motto,מערכות מחשב,קוד,react,java script,nodejs,server,java, c#, .net, תכנות,פיתוח, מיחשוב, סטארט-אפ,מחשבים,מערכות מתקדמות,אפליקציות, תוכנות,programing, program,שרת, ארוח אתרים,hosting, server, בניית אתר, בניית אתרים, בנייה, עיצוב, אחסון, דומיין, פיתוח אתרים, פיתוח אפליקציות, עיצוב אתר, אתר מוכן, תבניות אתר, אתר אינטרנט, אתרי אינטרנט, הקמת אתר, קידום אתרים, אתר מותאם לנייד,בית תוכנה,web,site,ווב,סייט,בנית אתר,בנית אתרים,מוטו"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Heebo:300,400,500,700&display=swap"
            rel="stylesheet"
          />
          
        <ResetCSS />
        <GlobalStyle />
        
        <AppWrapper dir="rtl">
          <Sticky top={0} innerZ={9999} activeClass="sticky-active">
            <Navbar />
          </Sticky>
          <ContentWrapper>
            <BannerWrapper>
              <div style={{color:"white"}}>
              <h1>
            <div>תנאי הזמנה ושרות באתר <br/>
             </div>
            </h1>
            <br/>
            הזמנת חבילת שעות <br/>
            התשלום במסגרת חבילת שעות הינו עבור שרותי פיתוח אתרים ואפליקציות, קידום אורגני וממומן בנית באנרים כתיבת תוכן, תמיכה והדרכה וכל שרות אחר שינתן על ידינו<br/>
            התשלום עבור כל שעה הינו שווה אא"כ צויין במפורש אחרת או שחבילת השעות נרכשה במבצע יחודי לתחום מסויים, במקרה זה חבילת השעות תיהיה רק לתחום שרות זה<br/>
            שלא במקרים הנ"ל ניתן לפצל את חבילת השעות לתחומי שרות שונים, גם אם לא צויין כן מראש <br/>
            חבילת השעות תקפה מרגע התשלום ועד 4 חודשים לאחר מכאן, שעות שרות שלא מומשו, לא יהיו ניתנים למימוש לאחר 4 חודשים אא"כ הוסכם מראש אחרת<br/>
            בכל מקרה בו הוגדר זמן לביצוע העבודה, שאורכו יותר מ 4 חודשים, חבילת השעות תיהיה תקפה עד הזמן שצויין בחוזה לביצוע העבודה ועוד חודשיים נוספים אא"כ צויין במפורש אחרת<br/>
            התשלום הינו עבור שעות עבודה שלנו או של ספקי משנה או גורמים מקצועיים אחרים שאנו נאעזר בהם לצורך ביצוע המשימה <br/>
            זמני למידה של חומרים הנדרשים לפיתוח , נכללים בשעות העבודה <br />
            במקרה שיש צורך בנסיעה לצורך ביצוע העבודה כגון נסיעה לבית הלקוח, יכלל זמן הנסיעה בכלל זמני השרות המשולמים בחבילה <br/>
            כל פניה למערכת תחשב כמינימום של 30 דקות ובכלל זה פניה טלפונית או פניה דרך האימייל או הווטס אפ וכדומה <br/>
            כל שרות של יותר מחצי שעה יעוגל כלפי מעלה לפי חצאי שעות <br/>
            ניצול זמן של לפחות 75% מהזמן, לכל משך זמן, יחושב כניצול מלא <br/>
            להוציא שרות הניתן בבית הלקוח או במקום עבודתו, שבמקרה זה יכללו כל זמני ההפסקות בכלל השעות המשולמות ויזקפו לחיוב הלקוח<br />
            אנו משתדלים לדייק בדיווח הזמנים, עם זה סטית דיוק של עד 20 % מוסכמת מראש <br/>
            במצבים בהם נאעזר בגורם חיצוני לצורך ביצוע המישמה או חלקה -יהיה הדיווח מגורם זה כמוחלט ומוסכם גם במקרה בו לא ניתן יהיה לאמת את הדיווח שיתקבל על ידו <br/>
            דיווח השעות שינתן על ידינו יהיה סופי ויחשב כאמין, מוחלט ומוסכם<br/>
            <br/> <br/> <br/>
            קידום אתרים אורגני<br/>
            יש 3 חבילות חודשיות לקידום אתרים אורגני וחבילה אחת חד פעמית כמפורט באתר<br/>
            לאף אחת מן החבילות אין התחייבות להגעה למקום הראשון או מקום מוגדר אחר<br/>
            כל החבילות החודשיות דורשות עבודה מתמשכת ולכן אין לצפות להשגים משמעותיים כבר בחודשים הראשונים, אע"פ שבחלק מן המקרים כן יהיו כאלו<br/>
            כל החבילות מוגבלות ל 20 שעות עבודה בחודש ויחולו על זה התנאים המפורטים בהזמנת חבילת שעות<br/>
            חבילה שנרכשה דרך האתר או בכל דרך אחר, זכותה של מוטו לבחור שלא לקדם אותה, במקרה כזה יבוטל החיוב ולא תיהיה שום תביעה אחרת כלפי מוטו<br/>
            כל ביצוע פעולה לקידום אתר שלא הוגדרה מראש, אינה מחוייבת להתבצע ותיהיה לשיקול דעת הפועלים במוטו<br/>
            זכותה של מוטו להעביר את ביצוע הקידום או חלקו או רכיבי ממנו לגורם צד שלישי ויחשב הדבר כאילו בוצע ע"י מוטובמקרה הנ"ל יחשב הדיווח שהתקבל על ידו לכל דבר שבוצע כסופי ומוסכם, לפי שיקול דעתה של מוטו<br/>
            <br/><br/><br/>

            הגדרות יחוס<br/>
            כל מקום שמוזכר זכות למוטו הכוונה לעומר שירן או כל מי שמונה לכך על ידו<br/>
            <br/> <br/> <br/>
            ישוב סיכסוכים <br/>
            הגורם המוסכם לכל דיון הוא בית הדין של כולל ארץ חמדה בלבד! <br/>


            </div>
           
            </BannerWrapper>
            
          </ContentWrapper>
          <Footer />
        </AppWrapper>
        
      </>
    </ThemeProvider>
  );
}
