import React from 'react'

const Promotion =()=>{
      return (
        <div style={{color:"white"}}>
<h1 >מעגלי חשיבה ופיתוח – סיעור מוחות</h1>  <br />
הרעיון הכללי של הפרויקט היא ריכוז כוחות יצירה של מפתחים על מנת ליצור מערך עוצמתי של פיתוחים במטרה לגבש יצירת מקוריות של יוזמות חדשות ופיתוחים <br />
ההבנה הבסיסית של הפרויקט היא כי כוחם של מספר מפתחים ביחד הן מבחינת יכולת היצירה והן מבחינת יכולת השיווק הרשתית וממילא מבחינת יכולת גיוס לקוחות היא בעין ערוך מחיבור הכוחות  ושיכלול יכולת הפיתוח של מספר מפתחים בודדים<br />
מטרתינו היא ליצור קבוצה התומכת ומסייעת לפיתוח המשותף כמו גם לגיוס לקוחות ומשאבים<br />
כיצד זה פועל<br />
אנו יוזמים מפגשי זום בן גורמים שונים בתחום הפיתוח כל פרק זמן כאשר מטרתנו היא להעלות אתגרים שעולים בעולמו הכלכלי של המפתח ולנסות, בחשיבה משותפת לתת להם מענה<br />
המפגש הראשון שלנו מיועד להכרות כולל העלאת בעיות ואתגרים בעולם הפיתוח והיצירה של מערכות קוד <br />
מצטרפים אלינו גם מפתחי אפליקציות, גורמי קידום אתרים, מפתחי סטארטפים, וגורמי מקצוע בתחומים השונים של המחשוב <br />
סיור מוחות<br />
המפגש השני  שלנו מיועד להעלאת רעיונות שמתמקדים בתחומי האתרים שהועלו במפגש הראשון<br />
המפגש השלישי מיועד לתחקור ושאול על ההצעות השונות שהועלו במפגש הראשון תוך כדי בחירת הקבוצה על אפשרות שת"פ בהצעות השונות שהועלו <br />
הרעיונות שהועלו ונבחרו לביצוע יהיו בבעלות כלל המשתתפים בביצוע ולפי החלטה משותפת של חברי הקבוצה על יחסי הבעלויות בפרויקט<br />
לא תהיה תביעה של זכויות להוגה הרעיון או לכל גורם אחר כלפי אף אחד מחברי הקבוצה כמו גם כלפי מארגניה.<br />
רעיון או תוכנית שההוגה מעוניין לשמור על חשאיותה, אנו ממליצים לא לפרסמה במעמד זה.<br />



        </div>
      );
    }
export default Promotion