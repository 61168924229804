import React from 'react';


const TITLE="מוטו ישראל";
const SUB_TITLE="מוטו ישראל - פתרונות מחשוב מתקדמים, פיתוח אפליקציות ומערכות מחשב, מחשוב מוסדות ועסקים, תוכניות בקרת עובדים ועוד";
const SUB_TITLE2  ="אנו שמחים שהגעת למוטו ישראל. נשמח לשרת אותך.  ההצלחה שלך זה הסיפוק שלנו!".split('.').map((item, i) => {return <p key={i}>{item}</p>})
const HOME_PAGE="דף הבית"
const PERSONAL_AREA  ="אזור אישי"
const ENTERING_PERSONAL_AREA__TITLE ="ברוך הבא לאזור האישי, עליך להתחבר תחילה על מנת להכנס!"
const NAME="שם"
const PHONE_NUMBER="מספר טלפון"
const EMAIL="אימייל"
const MESSAGE="ההודעה"
const CONTACT_US="צור קשר"
const CONTACT_US_MESSAGE=`
צור קשר;
בטלפון:0512-635325;
או באימייל:;
CustomerService@motto.co.il;
מחכים לשמוע ממך!
`.split(';').map((item, i) => {return <p key={i}>{item}</p>})

const SEND="שלח"
const LOGIN= "התחברות"
const INVITATION_MESSAGE_TO_FILL_THE_FORM=`
רוצה אפליקציה?
השאר את פרטיך
את כל היתר אנחנו נעשה
`
const ALL_RIGHTS_RESERVED="כל הזכויות שמורות למוטו ישראל ©"
const BACK_TO_HOMEPAGE="חזור לדף הבית"
const PRODUCTS_AND_SERVICES_TITLE="מוצרים ושרותים"
const PRODUCTS_AND_SERVICES_DETAIL=`
פיתוח אפליקציות לנייד.
פיתוח אפליקציות שולחניות.
פיתוח מערכות עסקיות.
קידום ממומן (פרסום בגוגל ועוד).
כרטיסים מגנטיים ומערכות מתקדמות.
אימיילים על שם החברה.
ועוד
`.split('.').map((item, i) => {return <p key={i}>{item}</p>})
const  DEVELOPMENT_PROCESS_TITLE ="תהליך הפיתוח"
const  DEVELOPMENT_PROCESS =`
תהליך הפיתוח
..
תהליך הפיתוח הינו תהליך ארוך ומורכב - אז אם אתם מעוניינים בפיתוח, בא נתחיל לעבוד
..
שלבים בבניית אפליקציה.
לפני שאנחנו מתחילים לפרט את השלבים בבניית האפליקציה נציין שתהליך בנית אפליקציה למובייל ולמחשב הוא שונה בדברים מסוימים ואנחנו מתייחסים במאמר זה בעיקר לבניית אפליקציה לנייד.
את בנית האפליקציה אנחנו מחלקים לשלושה שלבים ראשיים – תכנון, פיתוח ובקרה. גם שלבים אלו מחולקים לתתי שלבים.
נפרט:.
נתחיל עם שלבי הפיתוח.
שלב ראשון.
גיבוש רעיון והגדרת יעדים.
שני אלו הם שני שלבים שונים, אבל הם קריטיים בכל אפליקציה והסדר ביניהם משתנה.
רעיון- לפני שמתחילים לבנות – צריך רעיון שמוליך את האפליקציה.
אחרי כן צריך לנתח את היעדים של האפליקציה.
למי היא תועיל,במה היא תועיל וכו'.
גם מי שאין לו רעיון אבל בונה אפליקציה בשביל לקדם את העסק שלו או בשביל לשמור על קשרי לקוחות וכו' צריך רעיון לאפליקציה – אבל במקרה הזה – הוא מגדיר יעדים ובהתאם  ליעדים אלו הוא מפתח את הרעיון.
 .
שלב שני.
אחרי שגיבשנו את הרעיון הכללי – אנחנו מתחילם לפרק אותו לרסיסים בהתאם ליעדים שהגדרנו.
מה יהיה הפתיחה של האפליקציה, לאן יעבור משם המשתמש,מה האפשרויות שיפתחו לפניו וכו'.
שלב זה נקרא "הזרימה של האפליקציה".
גם הזרימה מתחלקת לשני חלקים.
שלב כללי "האפליקציה תכיל פונקציות כאלו וכאלו ותבצע וכו'".
ושלב מפורט בו אני בודקים צעד צעד מה יהיה בכל סיטואציה להיכן יוביל כל מסך ומתחילים לגבש את החזית של האפליקציה.
בשלב זה ניעזר בדף טיוטה שישמש גם ככלי עזר למעצב ונבנה דגם של האפליקציה "mockup".
בו אנו מציירים את המסכים השונים – מסכים אלו נקראים פריסות  "Layout".
הפונקציות השונות של כל שלב והקשרים ביניהם.
 .
השלבים עד כאן לא היו צריכים כלל בעל מקצוע.
בעל מקצוע טוב יוכל לכוון להמליץ אבל בעצם זה שלבים בגיבוש הרעיון המקצועי של הלקוח.
כמובן שלאחר ביצוע השלב הזה, בעל מקצוע יצטרך לבדוק את כל הפרטים האם הם בנויים נכון ורלוונטי , ללא סתירות וללא דברים שאינם ניתנים לביצוע וכו'.
 .
בשלב השלישי נערב את המעצב (יש כאלו הדוחים זאת לשלב מאוחר יותר).
מעצב טוב - משמעותי מאוד באפליקציה, השילוב בן הצבעים, ושילוב מוצלח של תמונות – זה רק חלק קטן מתפקידו של המעצב, מעצב מקצועי יודע לשלב רגשות: שמחה, צער, התלהבות..
וכולנו יודעים שאפליקציה שנראית טוב- משאירה רושם חיובי.
עכשיו אנחנו עוברים לפיתוח-  החלק המרכזי של בנית האפליקציה, וגם את שלב זה נחלק ל תכנון פיתוח ובקרה.
נתחיל בתכנון - הפיתוח, בשלב זה ישב איש מקצוע ויחלק את האפליקציה לשלבים, ינתח את הדרישות, את סוגי הנתונים הנדרשים וכו' ויבנה תוכנית לפיתוח.
בתוכנית תכלול את האובייקטים הנדרשים לפיתוח, את השיטות לכל אובייקט או הסטטיות את ה API שנדרש לפתח, את בסיסי הנתונים וכמובן את התוכנית עצמה.
לאחר שתהיה תוכנית עבודה לפיתוח נחלק מטלות בן המתכנתים, את הדברים המפורטים לעייל מפתחים בד"כ במגוון שפות ולכן משתמשים בכמה מתכנתים, כל אחד מקצועי בתחום אחר.
את האובייקטים ו השיטות והתוכנית (השיטה הראשית) נפתח ב JAVA   או ב #C את ה API נפתח עפ"ר ב PHP ואת בסיסי הנתונים ב MYSQL.
באפליקציות גדולות נצטרך לחלק את העבודה, אפילו באותה השפה, בן מתכנתים שונים.
במקביל ובסיום כל שלב נבדוק את תקינותו – נבקר – את העשייה שלנו, בקרה טובה במהלך הפיתוח תפטור את הצורך בהרבה תיקונים שידרשו בסוף ותחסוך מאיתנו הרבה באגים.
 .
שלב הפיתוח יכול לקחת חודשים ואף שנים בהתאם לגודל האפליקציה למספר המתכנתים שעובדים עליה ולאיכות התכנון.
 .
אחרי שהאפליקציה בנויה (וגם בסיום של כל שלב בפיתוח) ניצר קובץ APK – בעזרת קובץ זה נוכל לבדוק את ביצועי האפליקציה.
כאן אנחנו עברים לשלב הבקרה:.
בקר או מפתח יבדקו את האפליקציה לוודא שהכל תקין  וללא באגים וכל מה שנדרשנו לעשות –אכן מתבצע, יתכן מאוד ובשלב זה יתכנו שינויים, תיקונים ושיפורים.
ואח"כ גמרנו? - כמעט.
נתקין את האפליקציה, אצלנו, אצל קרובי משפחה וחברים ונעשה עוד בדיקה לאפליקציה ול---גוגל סטור..
מזל טוב!.
`.split('.').map((item, i) => {return <p key={i}>{item}</p>})
const CUSTOMER_SERVICE_EMAIL='CustomerService@motto.co.il'
export default {
    TITLE,
    SUB_TITLE,
    SUB_TITLE2,
    HOME_PAGE,
    PERSONAL_AREA,
    ENTERING_PERSONAL_AREA__TITLE,
    NAME,
    PHONE_NUMBER,
    EMAIL,
    MESSAGE,
    CONTACT_US,
    CONTACT_US_MESSAGE,
    SEND,
    LOGIN,
    ALL_RIGHTS_RESERVED,
    BACK_TO_HOMEPAGE,
    INVITATION_MESSAGE_TO_FILL_THE_FORM,
    PRODUCTS_AND_SERVICES_TITLE,
    PRODUCTS_AND_SERVICES_DETAIL,
    DEVELOPMENT_PROCESS_TITLE,
    DEVELOPMENT_PROCESS,
    CUSTOMER_SERVICE_EMAIL
}



// import React from 'react';

// const he={
//     TITLE:"מוטו ישראל",
//     SUB_TITLE:"מוטו ישראל - פתרונות מחשוב מתקדמים, פיתוח אפליקציות ומערכות מחשב, מחשוב מוסדות ועסקים, תוכניות בקרת עובדים ועוד",
//     SUB_TITLE2  :"אנו שמחים שהגעת למוטו ישראל. נשמח לשרת אותך.  ההצלחה שלך זה הסיפוק שלנו!".split('.').map((item, i) => {return <p key={i}>{item}</p>}),
//     HOME_PAGE:"דף הבית",
//     PERSONAL_AREA  :"אזור אישי",
//     ENTERING_PERSONAL_AREA__TITLE :"ברוך הבא לאזור האישי, עליך להתחבר תחילה על מנת להכנס!",
//     NAME:"שם",
//     PHONE_NUMBER:"מספר טלפון",
//     EMAIL:"אימייל",
//     MESSAGE:"ההודעה",
//     CONTACT_US:"צור קשר",
//     CONTACT_US_MESSAGE:`
//    צור קשר;
//    בטלפון:0512-635325;
//    או באימייל:;
//    CustomerService@motto.co.il;
//    מחכים לשמוע ממך!
//    `.split(';').map((item, i) => {return <p key={i}>{item}</p>}),
   
//     SEND:"שלח",
//     LOGIN: "התחברות",
//     INVITATION_MESSAGE_TO_FILL_THE_FORM:`
//    רוצה אפליקציה?
//    השאר את פרטיך
//    את כל היתר אנחנו נעשה
//    `,
//     ALL_RIGHTS_RESERVED:"כל הזכויות שמורות למוטו ישראל ©",
//     BACK_TO_HOMEPAGE:"חזור לדף הבית",
//     PRODUCTS_AND_SERVICES_TITLE:"מוצרים ושרותים",
//     PRODUCTS_AND_SERVICES_DETAIL:`
//    פיתוח אפליקציות לנייד.
//    פיתוח אפליקציות שולחניות.
//    פיתוח מערכות עסקיות.
//    קידום ממומן (פרסום בגוגל ועוד).
//    כרטיסים מגנטיים ומערכות מתקדמות.
//    אימיילים על שם החברה.
//    ועוד
//    `.split('.').map((item, i) => {return <p key={i}>{item}</p>}),
//      DEVELOPMENT_PROCESS_TITLE :"תהליך הפיתוח",
//      DEVELOPMENT_PROCESS :`
//    תהליך הפיתוח
//    ..
//    תהליך הפיתוח הינו תהליך ארוך ומורכב - אז אם אתם מעוניינים בפיתוח, בא נתחיל לעבוד
//    ..
//    שלבים בבניית אפליקציה.
//    לפני שאנחנו מתחילים לפרט את השלבים בבניית האפליקציה נציין שתהליך בנית אפליקציה למובייל ולמחשב הוא שונה בדברים מסוימים ואנחנו מתייחסים במאמר זה בעיקר לבניית אפליקציה לנייד.
//    את בנית האפליקציה אנחנו מחלקים לשלושה שלבים ראשיים – תכנון, פיתוח ובקרה. גם שלבים אלו מחולקים לתתי שלבים.
//    נפרט:.
//    נתחיל עם שלבי הפיתוח.
//    שלב ראשון.
//    גיבוש רעיון והגדרת יעדים.
//    שני אלו הם שני שלבים שונים, אבל הם קריטיים בכל אפליקציה והסדר ביניהם משתנה.
//    רעיון- לפני שמתחילים לבנות – צריך רעיון שמוליך את האפליקציה.
//    אחרי כן צריך לנתח את היעדים של האפליקציה.
//    למי היא תועיל,במה היא תועיל וכו'.
//    גם מי שאין לו רעיון אבל בונה אפליקציה בשביל לקדם את העסק שלו או בשביל לשמור על קשרי לקוחות וכו' צריך רעיון לאפליקציה – אבל במקרה הזה – הוא מגדיר יעדים ובהתאם  ליעדים אלו הוא מפתח את הרעיון.
//     .
//    שלב שני.
//    אחרי שגיבשנו את הרעיון הכללי – אנחנו מתחילם לפרק אותו לרסיסים בהתאם ליעדים שהגדרנו.
//    מה יהיה הפתיחה של האפליקציה, לאן יעבור משם המשתמש,מה האפשרויות שיפתחו לפניו וכו'.
//    שלב זה נקרא "הזרימה של האפליקציה".
//    גם הזרימה מתחלקת לשני חלקים.
//    שלב כללי "האפליקציה תכיל פונקציות כאלו וכאלו ותבצע וכו'".
//    ושלב מפורט בו אני בודקים צעד צעד מה יהיה בכל סיטואציה להיכן יוביל כל מסך ומתחילים לגבש את החזית של האפליקציה.
//    בשלב זה ניעזר בדף טיוטה שישמש גם ככלי עזר למעצב ונבנה דגם של האפליקציה "mockup".
//    בו אנו מציירים את המסכים השונים – מסכים אלו נקראים פריסות  "Layout".
//    הפונקציות השונות של כל שלב והקשרים ביניהם.
//     .
//    השלבים עד כאן לא היו צריכים כלל בעל מקצוע.
//    בעל מקצוע טוב יוכל לכוון להמליץ אבל בעצם זה שלבים בגיבוש הרעיון המקצועי של הלקוח.
//    כמובן שלאחר ביצוע השלב הזה, בעל מקצוע יצטרך לבדוק את כל הפרטים האם הם בנויים נכון ורלוונטי , ללא סתירות וללא דברים שאינם ניתנים לביצוע וכו'.
//     .
//    בשלב השלישי נערב את המעצב (יש כאלו הדוחים זאת לשלב מאוחר יותר).
//    מעצב טוב - משמעותי מאוד באפליקציה, השילוב בן הצבעים, ושילוב מוצלח של תמונות – זה רק חלק קטן מתפקידו של המעצב, מעצב מקצועי יודע לשלב רגשות: שמחה, צער, התלהבות..
//    וכולנו יודעים שאפליקציה שנראית טוב- משאירה רושם חיובי.
//    עכשיו אנחנו עוברים לפיתוח-  החלק המרכזי של בנית האפליקציה, וגם את שלב זה נחלק ל תכנון פיתוח ובקרה.
//    נתחיל בתכנון - הפיתוח, בשלב זה ישב איש מקצוע ויחלק את האפליקציה לשלבים, ינתח את הדרישות, את סוגי הנתונים הנדרשים וכו' ויבנה תוכנית לפיתוח.
//    בתוכנית תכלול את האובייקטים הנדרשים לפיתוח, את השיטות לכל אובייקט או הסטטיות את ה API שנדרש לפתח, את בסיסי הנתונים וכמובן את התוכנית עצמה.
//    לאחר שתהיה תוכנית עבודה לפיתוח נחלק מטלות בן המתכנתים, את הדברים המפורטים לעייל מפתחים בד"כ במגוון שפות ולכן משתמשים בכמה מתכנתים, כל אחד מקצועי בתחום אחר.
//    את האובייקטים ו השיטות והתוכנית (השיטה הראשית) נפתח ב JAVA   או ב #C את ה API נפתח עפ"ר ב PHP ואת בסיסי הנתונים ב MYSQL.
//    באפליקציות גדולות נצטרך לחלק את העבודה, אפילו באותה השפה, בן מתכנתים שונים.
//    במקביל ובסיום כל שלב נבדוק את תקינותו – נבקר – את העשייה שלנו, בקרה טובה במהלך הפיתוח תפטור את הצורך בהרבה תיקונים שידרשו בסוף ותחסוך מאיתנו הרבה באגים.
//     .
//    שלב הפיתוח יכול לקחת חודשים ואף שנים בהתאם לגודל האפליקציה למספר המתכנתים שעובדים עליה ולאיכות התכנון.
//     .
//    אחרי שהאפליקציה בנויה (וגם בסיום של כל שלב בפיתוח) ניצר קובץ APK – בעזרת קובץ זה נוכל לבדוק את ביצועי האפליקציה.
//    כאן אנחנו עברים לשלב הבקרה:.
//    בקר או מפתח יבדקו את האפליקציה לוודא שהכל תקין  וללא באגים וכל מה שנדרשנו לעשות –אכן מתבצע, יתכן מאוד ובשלב זה יתכנו שינויים, תיקונים ושיפורים.
//    ואח"כ גמרנו? - כמעט.
//    נתקין את האפליקציה, אצלנו, אצל קרובי משפחה וחברים ונעשה עוד בדיקה לאפליקציה ול---גוגל סטור..
//    מזל טוב!.
//    `.split('.').map((item, i) => {return <p key={i}>{item}</p>}),
//     CUSTOMER_SERVICE_EMAIL:'CustomerService@motto.co.il'
//    }
//    export default  he
   