import React from 'react'

const Text=()=>{
      return (
        <div style={{color:"white"}} dir="RTL">
    <h1>   קידום ממומן, מחירים </h1>
    
<b>תוכנית בתשלום קבוע –₪ 2200 עבור הקמת הקמפיין (לא כולל המחיר לגוגל)</b><br />
כולל:<br />
1.	פתיחת חשבון google ads חדש ללקוח (החשבון בבעלות הלקוח)<br />
2.	קבלת הטבה בסך 300 ₪ לפרסום בגוגל <br />
3.	הגדרת צרכים ויעדים למסע הפרסום<br />
4.	ניתוח מילות מפתח<br />
5.	ניסוח מודעה  רספונסיבית לרשת החיפוש<br />
6.	בנית מודעה רספונסיבית לרשת המדיה (אם יש צורך בבאנר בהתאמה אישית, יש להזמין בנפרד, ע"פ התמחור באתר)<br />
7.	בניית שני קמפיינים (לרשת המדיה והחיפוש) ע"פ הגדרת תקציב של הלקוח<br />
8.	אופטימיזציה ראשונית למסע הפרסום ברשת המדיה<br />
9.	הגשת מסע הפרסום ללקוח<br />
10.	הדרכת הפעלה, השבתה ושינוי תקציב יומי (עד שעת הדרכה אחת)<br />
שינוי והוספת מודעות, בתמחור ע"פ שעות
<br />
< a href='/Promoting-funded-direct-order?plan=fixed' style={{color:"#A8DDFE"}}> לרכישה לחץ כאן</a>
<br />
<br />
<b>תוכנית בתשלום יחסי  </b>  <br />
עלויות:<br />
השקעת היכרות ראשונית מינימלית בסך 790 ₪<br />
לאחר מכן -<br />
תשלום משתנה  לפי דרישת הלקוח, <br />
מתוך התשלום נגבית עמלה של עד 20% עבור ניהול הקמפיין<br />
חשבון הלקוח בgoogle ads  בבעלות מוטו<br />
< a href='/Promoting-funded-direct-order?plan=relative' style={{color:"#A8DDFE"}}> לרכישה לחץ כאן</a>
<br />
<br />

<b> תכנית לפי שעות </b> <br />
   תשלום משתנה ע"פ תמחור ותקנון חבילת השעות מינימום 10 שעות    <br />
בעלות על חשבון הלקוח בgoogle ads   בהתאם למוסכם עם הלקוח<br />

< a href='/Promoting-funded-direct-order?plan=hours' style={{color:"#A8DDFE"}}> לרכישה לחץ כאן</a>
<br />

          
           </div>
      );
    }
export default Text