import React from 'react'
import strings from '../../common/src/strings/he'

const DevelopmentProcess=()=>{

    return(<div style={{backgroundColor:"#DAF7A6"}} id="DevelopmentProcess">
        <br/>
        { strings.DEVELOPMENT_PROCESS}</div>)
}

export default DevelopmentProcess