import React ,{useState}from 'react';
import Fade from 'react-reveal/Fade';
import Button from '../../common/src/components/Button';

import axios from 'axios'


const ContactUsForm = (props) => {
  const [Name,setName]=useState("")
const [Email,setEmail]=useState("")
const [Phone, setPhone]=useState("")
const [Message, setMessage]=useState("")

const[OpenForm,setOpenForm]=useState(0)
const handleChange=(e)=>{
 

  switch(e.target.name){
case ("name"):
setName(e.target.value)  

break

case ("email"):
setEmail(e.target.value)  

break

case ("phone"):
setPhone(e.target.value)  

break

case ("message"):
setMessage(e.target.value)  

break




  }
}
 
const form=(
  <div dir="rtl" style={{
    backgroundColor:"#A993C6",
    color:"white",
   
  }}>
     {/* <form dir="rtl" style={{
  backgroundColor:"#A993C6",
  color:"white",
 
}}> */}
   {/* <fieldset>
   <legend style={{color:"white"}}>{props.message}</legend>
   <center> */}

    שם מלא: &nbsp;&nbsp;
    <input  type="text" name="name"  value={Name} onChange={handleChange}/>
&nbsp;&nbsp;
    &nbsp;&nbsp;אימייל:&nbsp;&nbsp;
    <input  type="text" name="email"  value={Email} onChange={handleChange} />
&nbsp;&nbsp;
    &nbsp;&nbsp; טלפון:&nbsp;&nbsp;
    <input  type="text" name="phone"  value={Phone} onChange={handleChange} />
    &nbsp;&nbsp; &nbsp;&nbsp;
    במה נוכל לעזור?
    <textarea 
   rows="1" cols="60"
    name="message"  value={Message} onChange={handleChange}
    />
&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
<Button  title="שלח"  onClick={()=>{
if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Email)))
alert("האימייל לא תקין- נא הכנס אימייל תקין!")
else
{
  
  axios({
method:"post",
url:" https://us-central1-sender-4330e.cloudfunctions.net/app/send_email_client",
data:{to:"omershiran@gmail.com",from:"omershiran@gmail.com",subject:"הודעה חדשה באתר מוטו בנושא  :  ",text:(Name+"  "+Email+"  "+Phone+"  "+Message)}
}).then((v)=>{
  
  if(v.data===1001){setOpenForm(1)}
else{
  
  setOpenForm(2)}
}).
  catch(e=>{ 
    setOpenForm(2)})
    setOpenForm(3)
}}}/>
{/* </center>
</fieldset> */}
{/* </form> */}
</div>
)
const AfterProperDelivery=(<h2 style={{color:"#A993C6"}}>ההודעה נשלחה בהצלחה</h2>)
const AfterUnProperDelivery=(<h2 style={{color:"#A993C6"}}>תקלה במשלוח ההודעה, ההודעה לא נשלחה,&nbsp;&nbsp; אנא נסה שנית במועד מאוחר יותר</h2>)

const DirectMessageAfterSending=(<h2 style={{color:"#A993C6"}}>ההודעה נשלחת אנא המתן...</h2>)
let formContent=(OpenForm===0)?form:(OpenForm===1)?AfterProperDelivery:(OpenForm===3)?DirectMessageAfterSending:AfterUnProperDelivery

return ( 
          // <Fade up delay={100}>
          <div>
         {formContent}
         </div>
          //  </Fade>
  );
};

export default ContactUsForm;
