import React from 'react'

const keysObject={
  
        HTML:{name:"HTML",
text:(<div style={{color:"white"}}> 
         <p style={{textAlign: 'left'}} >
        
        <h1> <strong>HTML</strong></h1><br/><br/>
        HYPER TEXT MARKUP LANGUAGE <br/><br/>
HTML is the abbreviation of Hypertext Markup Language and is a programming language used to create documents called web pages that appear on the Web. All web pages on the Internet are made using some form of HTML. This language is used for front-end development, it is the structure of the page, and it will consist of a series of different elements. Browsers that display text or load different elements are basic requirements.<br/><br/>
An example of HTML would be:<br/>
<p style={{textAlign: 'left'}}>
{`<h1> 
Welcome to Concepts Tech!
</h1>`}
</p>

 This tells the browser that this text is a title and will display it exactly as it is. It is contained in what is called the HTML tag ‘’opening tag <> and closing tag </>’’, a tag that helps distinguish HTML content from ordinary content. HTML is a free language, so developers don't need to buy any software to start using it. The language does not require users to use external plug-ins and software to access key functions. Therefore, many companies around the world rely on HTML to meet their website design requirements. You can build the entire website using just HTML.<br/><br/>
It is a programming language that has a simple learning curve and provides ease of use. The language is also platform-independent, which means that it can work on multiple different platforms. Html helps to easily add image, video, and audio content to web pages. This language can also be used to add hypertext content to parts of the text. HTML is easy to learn and use, and HTML is considered by most developers to be one of the easiest programming languages to learn. Many developers learn HTML before learning any other language, and it is an integral part of any development course. HTML is compatible with the most commonly used browsers. Therefore, if the website is written in HTML, there is no browser compatibility issue. HTML websites can be optimized for different browsers that can load them.<br/><br/>
Although we have just gone through the many great things about HTML, there are some drawbacks to using only HTML to build a website or application. For example, no dynamic pages, this means using only HTML is not enough to incorporate many of the features that modern websites offer to their users for example websites that have maps. Developers may find it difficult to add dynamic content to web pages with HTML. This is a limitation most developers are aware of, and to make the page less static, programming languages like JavaScript are adopted, this is why developers using HTML also learn other languages such as PHP, or ASP. For your webpage to have appealing displayed content, they have to rely on CSS or Cascading Style Sheets to make HTML page content aesthetic. Web designers and developers are required to create and maintain two different file sets in some cases with HTML and CSS and other languages separate or in the same integrated development environment. <br/><br/>
As a basic frontend development language, HTML should be considered as a building block when creating web pages.

      
      </p>
         </div>)
}
,
FRAMEWORK:{name:"FRAMEWORK",
text:(<div style={{color:"white"}}> 
         <p style={{textAlign: 'left'}} >
        
        <h1> <strong>FRAMEWORK</strong></h1><br/><br/>
   
We are always looking for ways to make work easier and faster for ourselves and that is essentially what a framework sets out to do, “work smarter, not harder”. Frameworks are developed so programmers do not have to start from scratch, they have cut out the need to have a mastery of a particular programming language. Sometimes programming languages and frameworks are used interchangeably which confuses people, but there is a difference.<br/><br/>
A programming framework is a software that allows additional user-written code which contains a specific functionality to an existing programming language in order to make it an application-specific software. They contain support programs, compilers, libraries and are typically built, tested, and optimized by various experienced programmers and software engineers. This means it is constantly being improved on, developing codes with fewer bugs. Sometimes, companies create frameworks for their internal use and may eventually release them to the public as is seen in the case of Ruby on Rails. <br/><br/>
The key reason why individuals go out of their way to create frameworks is to save time, coding can be very repetitive and tedious, and frameworks get rid of that by prebuilding several of these codes, they allow developers to focus on the bigger picture rather than every single minute detail. Another benefit of frameworks is that it provides more security for your data. They are in a development environment that provides robust security against cyberattacks. Frameworks efficiently connect with multiple databases by the use of Application Programming Interfaces, APIs.<br/><br/>
Types of frameworks are web application frameworks, data science frameworks, and mobile development frameworks. Here are some examples of some of the most popular frameworks: jQuery, React, Angular, VueJs, Node.js (which are all JavaScript frameworks), Bootstrap for HTML-CSS designs, Xamarin, ASP.NET, .NET, .NET Core which are C# frameworks, Laravel which is a PHP framework, Django which is a Python framework and so many others. Microsoft .NET Framework is the most extensive and mature framework, but it can only run on Microsoft Windows servers or desktops.<br/><br/>
React is a JavaScript framework developed by Facebook and a community of individual developers and corporations that allow developers to create large user interfaces with powerful dynamic functions. The web pages developed by React are fast and the change between elements is very simple. It provides an excellent user experience and leaves positive emotions when visiting such pages. React supports most of the setups on the backend of the app. Angular is the second improved version of the previous AngularJS framework and it was developed by Google. Compared with React, it is more difficult to learn, but it provides a more practical frontend solution. It enables developers to create complex and highly scalable applications with complex business logic, functions, and visually attractive designs.<br/><br/>
If you do not consider yourself an expert in a particular programming language, it will be easier to understand using the knowledge of frameworks when faced with complex challenges. Many front-end developers contribute to the open-source frameworks to support the entire software development community.<br/><br/>

       
       
      </p>
         </div>)
}
,
FRONTEND:{name:"FRONTEND",
text:(<div style={{color:"white"}}> 
         <p style={{textAlign: 'left'}} >
        
        <h1> <strong>FRONTEND</strong></h1><br/><br/>
       
One of the most popular tech jargon you might hear a developer mention is frontend. Don't let that phase you as you’ll learn what it means here. Frontend also referred to as client-side interface is simply the user interface which you see before you right now. It is the act of making websites and web applications that we see, although not everything is found on the frontend. When you go to a website, or an application and a button is clickable that is because the frontend is working properly. <br/>
<br/>
Today, almost all online and offline businesses need to provide software for customers or their own employees. On a baker’s website, the baker may have their unique recipes, a photographer who takes pictures of their desserts, but a frontend developer will bring all these things together to create a great website.<br/>
Let us relate this to UI/UX. Frontend is not to be mistaken for user interface or experience design, frontend is the CODING (implementation) done to achieve these designs that we see, to achieve the responsive designs, functionalities, aesthetics, appearances, ease of use, the user experience which has been discussed with the clients, they are responsible for bringing all these concepts to life. <br/>
<br/>
Frontend programming languages include but are not limited to HTML, CSS, JavaScript, Angular, React, Vue and TypeScript. A UI/UX designer is essentially a frontend job, although most user interface designers just stop at the design and leave the coding aspect to frontend developers. By default, JavaScript is the first choice for frontend development and is compatible with all platforms, from web browsers to mobile and desktop operating systems. <br/>
<br/>
The main principle of JavaScript is that it can handle what is known as "events". Events are the interactions between the user and the application, that is, clicks, slides, swipes, form submissions, and other actions. It provides powerful opportunities to create interactive web pages, games, and other programs. React is a JavaScript framework developed by Facebook that allows developers to create user interfaces with powerful dynamic functions. The web pages developed by React are fast and the change between elements is very simple. It provides an excellent user experience and leaves positive emotions when visiting such pages. React supports most of the setups on the backend of the app. Angular is the second improved version of the previous AngularJS framework and it was developed by Google. Compared with React, it is more difficult to learn, but it provides a more practical frontend solution. It enables developers to create complex and highly scalable applications with complex business logic, functions, and visually attractive designs.<br/>
Each programming language or framework has its own advantages and disadvantages, but in general, they can all cover similar frontend tasks. Whether it's HTML and CSS, JavaScript, React, Angular, or Vue.js, it's just a matter of your own preferences and the business purpose you want to achieve.<br/>
<br/>
When people refer to themselves as frontend developers it creates a distinction that they are able to do the frontend coding and some also have UI/UX knowledge.<br/>


       
      </p>
         </div>)
}
,
GRAPHIC_USER_INTERFACE:{name:"GRAPHIC USER INTERFACE (GUI)",
text:(<div style={{color:"white"}}> 
         <p style={{textAlign: 'left'}} >
        
        <h1> <strong>GRAPHIC USER INTERFACE (GUI)</strong></h1><br/><br/>
        A brief background about GUI. The appearance of the graphical user interface (GUI) is due to research conducted by Xerox engineers. In the early 1970s, these engineers created a small mobile box connected to a computer via a cable, the first computer mouse, and the user interface in the process.<br/><br/>
GUI is the acronym for Graphical User Interface. Have you ever wondered how we are able to make use of a computer when we are told it is made up of 1’s and 0’s? This is because of the operating system software and how are we able to understand the operating system, have our applications, icons, buttons, and perform our usual computer operations? This is because of the Graphical user Interface.<br/><br/>
Graphics refer to visual presentations, that is what we see. An interface is that bridge that allows two separate parts of a computer system to interact with one another. A Graphical User Interface is a visual representation that allows us to be able to use a computer system, be it laptop, desktop, or smartphone. We can either use a mouse or a touchpad to maneuver through the GUI. The mouse controls the cursor, a cursor or pointer shows your current position on the screen.<br/><br/>
A GUI is usually made up of windows, menus, icons, and tabs. A window is an area with a rectangular shape that shows information independently from the rest of the screen. For instance, when you click to open an application or a file, it will open up in its own window where you can view the information on it. An example is a web browser like Google Chrome. People who are not so knowledgeable about the graphic user interface are still able to easily manipulate windows. There are different types of windows like the pop-up window, which comes up as a result of an action you have performed, a nice or an annoying ad, a message window, that provides certain information or asks for input from you.<br/><br/>
Menus are graphical representations of functions that users can click on to access. It is a horizontal or vertical panel that contains all the available functions in an application, it is usually found at the top of an application screen. A type of menu is the context menu, it is a menu that is not visible until a user clicks the mouse button, after which the menu appears where the cursor is positioned. Icons are visual representations of applications, folders, files, or web browsers through images. All files that you create in the same application will have the application's icon.<br/><br/>
On opening your web browser, you have automatically created a tab in your browser window. A tab is also a rectangular-shaped box that displays the name or graphical icon associated with that specific window. When users select a tab, they will see the specific controls and information displayed in that window.<br/><br/>
The ease of use of a GUI makes it possible for almost everyone to make use of it, regardless of experience or knowledge.<br/><br/>


       
      </p>
         </div>)
}
,
FULLSTACK:{name:"FULLSTACK",
text:(<div style={{color:"white"}}> 
         <p style={{textAlign: 'left'}} >
        
        <h1> <strong>FULLSTACK</strong></h1><br/><br/>
  
        The work stack has many different meanings, but the stack here is the developer's skill set. Some full-stack developers also learn project management, visual design, web design, or user experience skills to complete their "stack." <br/><br/>
A full stack project refers to a project that generally requires both frontend developers and backend developers at the same time. Full stack developers are web developers or engineers who deal with the frontend and the backend of a website or application at the same time, which means they work on projects that involve databases, APIs, or the stage of Project planning. <br/><br/>
Most full stack developers focus on specific backend programming languages, such as PHP or Python. When developers mention their work, they are mostly very specific, such as "full stack web developer" or "full stack python developer" or "frontend developer" and similar roles. More and more developers are becoming full stack developers. This is because many employers are looking for developers who know how to work on various parts of the website, especially software solution providers, so they can provide developers with an all-round skill set for jobs and not necessarily distinguish between frontend and the backend. This is why the demand for full stack developers has increased, leading to the number of developers hoping to improve their skills has increased. <br/><br/>
Becoming an employed Full Stack developer requires years of dedication, combined with education and work experience. Typically, these developers have taken computer science and/or programming courses and have degrees in that or related topics. Having a portfolio of websites and applications you have coded, whether for backend, frontend, or full stack, is a great way to attract employers’ attention. <br/><br/>
Having a "full stack" does not always mean that the developers themselves write all the code for the application or website, and there may also be frontend developers who can help. Having someone who specializes in a particular language is always good for software development. Some full stack developers sometimes write entire websites or applications, which includes frontend and backend, but in most cases only if they are freelancers or the only developers working on the project.<br/><br/>


       
      </p>
         </div>)
}
,


dugoly:{name:"dugoly",
text:(<div style={{color:"white"}}> 
         <p style={{textAlign: 'left'}} >
       <a style={{color:"#A8DDFE"}} href="https://dugoly.com/">  Dugoly system - is a system of tools for react developers </a>
       <br/><br/>
The system was developed by motto, application and website development
<br/><br/>
The library has several components
<br/><br/>
dugoly-login - user identification system
<br/><br/>
dugoly-blog - a complete blog template connected to a database
<br/><br/>
dugoly-uploader - Internet image upload system
<br/><br/>
And more
<br/><br/>
<a style={{color:"#A8DDFE"}} href="https://dugoly.com/login3/">To enter the dugoly management interface, click here</a>
<br/><br/>
<a style={{color:"#A8DDFE"}} href="https://dugoly.com/Documentation/readme/dugoly-Libraries.html">To document the libraries, click here</a>
                              </p>
         </div>)
}
,
algorithm:{name:"algorithm",
text:(<div style={{color:"white"}}> 
         <p style={{textAlign: 'left'}}>
        <h1> <strong>algorithm</strong></h1><br/><br/>
        Algorithms are everywhere in computers. An algorithm in essence is a set of step-by-step instructions designed to perform a specific task. This can be something as simple as finding the fastest route to the park, placing dominos in a particular order, or adding two numbers, or it could be a bit more complex like which sponsored posts to come up more frequently for you on Instagram. Do you realize you acted like a computer algorithm when you decided on how to go about each of the examples above? IF there was a traffic jam on route 91, THEN take a different path to the park, this is called an IF/THEN statement. <br/><br/>
Algorithms are used for many different things. You could consider a cake recipe an algorithm for making a cake. When bakers follow a recipe to make a cake, by following the recipe to the letter, eventually your cake will taste the same anytime you bake. If by chance there is a slight difference from the recipe, it will end up having a different taste. Some of the steps you may take to compile your recipe will depend on data you gathered from different sources. <br/><br/>
Algorithms can become complex as they gather lots of data from different sources to be able to reach the desired solution to the task at hand. The steps in most algorithms must follow an order. Taking an example, a search engine like google is an algorithm that takes search questions as input and goes through its database for items relevant to the words in that query, after it shows you the results of your search. Those steps are called dependencies. Computer algorithms work via input and output. They take the input and apply each step of the algorithm to that information to generate an output. You can easily visualize algorithms as a flowchart. It contains the inputs, processes to follow in order and once each section of the flowchart is completed, the generated result is the output.<br/><br/>
In different instances, there are multiple ways to perform a specific operation within a software program, because of the different approaches available programmers usually seek to create the most optimal algorithm. By using an optimal algorithm, developers can ensure their programs run as fast as possible and use minimal system resources. Of course, not all algorithms are created perfectly the first time. Therefore, developers often improve existing algorithms and include them in future software updates. For example, when you see a new version of a software program that boasts better performance, it most likely means the new version has an improved algorithm.<br/><br/>
Some types of algorithms include search and sort algorithm, greedy algorithm, brute force algorithm, recursive algorithm, backtracking algorithm, dynamic algorithm, randomized algorithm, divide and conquer algorithm, these are just some of the many algorithms out there. Developers aim to use the one most beneficial to the task at hand.<br/><br/>
The future will include algorithms that teach computers how to better understand more complex information. This is what is called machine learning, computers learning from other computers.<br/><br/>

        </p>

          
            </div>)
}
,
HOSTING:{name:"HOSTING",
text:(<div style={{color:"white"}}> 
         <p style={{textAlign: 'left'}}>
        <h1> <strong>HOSTING</strong></h1><br/><br/>
        Ever wondered how so many people can have their sites accessible to you and everyone else on the internet? It is simply by hosting their websites on the internet. Let us get into what web hosting is and how websites are hosted.<br/><br/>
To get a functional website you need to host your content online. It makes use of your unique website name and domain name, for example, Facebook.com. Web hosting is essentially connecting websites to a server, servers store webpages and are able to upload them to the internet when they get a request from your browser, the request is you searching for the website name also known as a web address or URL Uniform Resource Locator, website owners are basically renting or buying space on a server owned by a data center, so web hosting is paid for, although it’s not only web pages that servers store. The web host (a company that has web hosting services) is then responsible for server maintenance, keeping the software and hardware up to date, troubleshooting when there are issues and others.<br/><br/>
There are so many web hosts around the world, these are some of the reasons why you need to choose the proper web host for you when creating an online presence. First is the rate at which the site loads, no one wants to be on a site that takes forever to show its content, traffic to the site will reduce, potential customers are pushed away and that is bad for the site owner. Also, it does not make it come up as one of the top searches when others are searching for keywords included in your site. The second is downtime, this is when a website is not accessible, and this can happen if the web host is unreliable, this is bad because it makes your site unavailable to people surfing the net. The third is security, we all know how important security is. If you don’t choose a proper web host, there will be increased security risks with your data. Fourth is having customer support if your website, unfortunately, does experience some downtime.<br/><br/>
Here are some of the things to consider when choosing your web hosting considering the fact that lots of web hosts exist. First, Do your research. This point cannot be emphasized enough, what do other users say about it, figure out what your website needs, technical needs, ease of use, how fast it would be to get your site up and running, and questions related to these points. Second is to consider the cost. The price of signing up with the web hosting company, consider all the plans they offer, will you be able to renew? Are there hidden fees? Make sure to read the fine print and everything is clear to you. The third is considering your growth, you will probably want more traffic to your site because you are getting more users visiting, introducing more content, you want a web host that will be able to grow with you.<br/><br/>

        </p>

          
            </div>)
}

,
COOKIES:{name:"COOKIES",
text:(<div style={{color:"white"}}> 
         <p style={{textAlign: 'left'}} >
        <h1> <strong>COOKIES</strong></h1><br/><br/>
        
        Many times, we get confused about why we go to a website while browsing the internet, and we must accept cookies to use the site further or certain functions will not be available to you if you do not accept, or just informing you that the site uses cookies. Sometimes you may be skeptical thinking it is malware or most times you just go ahead to accept because it is obstructing what you need to do, but cookies are in fact not malware. Here is what a cookie is.<br/><br/>
Cookies are formally known as HTTP cookies so as not to get them confused with the yummy goodness that we eat. Cookies are a piece of data from that website which you have visited which gets stored on your computer. Why do websites ask you to accept cookies? They use them to track your activity throughout your session on their site and once you leave their site they cannot track your activity, this may seem like a breach of privacy that is why all sites using cookies are obligated to show you that notification and won't use cookies unless you give them the go-ahead. Why do they want to track your activity? This is to be able to suggest similar things to what you have searched for and helps to know more of the things that interest you. Take for example if you play candy crush or open your Facebook and you see those little ads, you notice that it may have been something similar you shopped for on a shopping site that pops up for you to check out.<br/><br/>
Another reason sites may use cookies is to be able to remember your login details, when you go back to the site, and you want to log in again the saved username and password comes up, take for example Gmail. Website owners may also want to track the traffic to their site. Everyone wants to know if their business or blog or otherwise is doing well. They can use cookies to track because each cookie has a unique identification number and would not be repeated for different users, so if a single user views that site multiple times since the cookie is stored on their computer with their unique ID it still counts as one. This is also a way for websites to save server space as all the browsing data for a particular person they use to make suggestions for you is stored on your hard drive.<br/><br/>
You may have also seen something like third-party cookies. Third-party cookies are cookies that are from another website different from the one you are on. For example, you want to share or like a post from a blog you have visited but with a Facebook or Twitter link, the like request is sent to Facebook or Twitter and then those sites send their cookies so it tracks that you have liked it on Facebook, but it shows on the blog. The blog posts may now come up on your Facebook as suggestions for something that you like.<br/><br/>

         </p>   </div>)
}

,
TYPESCRIPT:{name:"TYPESCRIPT",
text:(<div style={{color:"white"}}> 
         <p style={{textAlign: 'left'}} >
        <h1> <strong>TYPESCRIPT</strong></h1><br/><br/>
        
        TypeScript is a programming language that has a relation to JavaScript, it contains all its features. TypeScript is maintained by Microsoft and was made to easily upgrade JavaScript code from a few hundred lines to thousands of lines. <br/>
It is somewhat an improvement of JavaScript because it includes more static typic and more classes, provides advanced design-time tooling, compile-time checking, and dynamic module loading at runtime, while still being able to make use of the vast JavaScript libraries.<br/>
It contains several built-in frameworks which include Angular, RxJs, 5Ionic, and Dojo 2. If you have experience with JavaScript, the way TypeScript appears will make it easier for you to understand, once a TypeScript program has been compiled you can consume it from any JavaScript program too.<br/>
	

         </p>   </div>)
}

,
SITEMAP:{name:"SITEMAP",
text:(<div style={{color:"white"}}> 
         <p style={{textAlign: 'left'}} >
        <h1> <strong>SITEMAP</strong></h1><br/><br/>
        
        A sitemap is a file with the list of your website pages, typically like a table of contents in a book except that the sections are links, that help search engines crawl and all your website’s content. They can also tell search engines which pages on your site are most important. The term web crawling refers to an internet robot that search engines like google use to browse the web.<br/>
Search engines mostly find web pages through links, but according to Google, you can always gain from adding a sitemap to your website because it can only help not cause harm. Let us say you have a new website with few external backlinks, then a sitemap is great for helping Google find pages on your site and displaying them in people’s searches.<br/>
Imagine it this way, you have different content all over the page on your website, it looks somewhat scattered to a user that checks it out, same thing happens when the crawler comes to crawl your site, a sitemap would make it more understandable, and it can effectively decipher the contents of your site.<br/>
You may have an amazing website, but it's failing to reach that target audience that you need, the problem is sometimes web crawlers might not reach some pages, either because your website is big, or the backlinks are not properly connected. This is one of the reasons sitemaps exist, to reduce the chances of missing web pages, spiderbots (web crawlers) occasionally consult a sitemap.<br/>
There are two main types of sitemaps the HTML and XML sitemaps, others include Video Sitemap, News Sitemap, and Image Sitemap.  An HTML sitemap is a web page that lists the links on the website. Usually, these are the links to the most important sections of the website.  XML Sitemap is the most common type of sitemap. It is usually in the form of an XML file (e.g., sitemap.xml) that links to different pages on your website, located in the website’s root folder.<br/>
An easy way to create a site map; review your page structure and arrange them in order of their hierarchy, then format your URL’S so they contain XML tags, make sure the sitemap URL is correct, add the sitemap to the root folder, and finally submit the sitemap to the search engines.<br/>


         </p>   </div>)
}
,
WIREFRAME:{name:"WIREFRAME",
text:(<div style={{color:"white"}}> 
         <p style={{textAlign: 'left'}} >
        <h1> <strong>WIREFRAME</strong></h1><br/><br/>
        
        A website wireframe is the skeleton or blueprint of a website. Let us simplify this. Say you are in a brainstorming session with some friends and an idea pops up, to clearly make those around you understand the vision you have in your head and communicate it properly, you present it in a diagrammatic format, using boxes to depict this or that and connecting the workflow. This is what a wireframe is, but rather for web pages. <br/>
When a website is being developed it will eventually have the code to make it look exactly how you have it in mind, but before the website is designed, or codes used a process will be decided, you want this to not lead to this or you want this to come back to this after a click, most things will be up for discussion and it is easy to change decisions at this stage. This is the rough sketch of the workflow, and you can refer to it as the wireframe. <br/>
It is typically the first stage that user experience designers work with to plan how the website or app turns out, following how the client will like the information process to be, knowing how the users will interact with the website, which button goes where, which part goes where. Unlike the user interface design, there is no distraction of colours, it is just black and white, plain, and simple, like a skeleton. <br/>
Wireframing could be done by hand drawings or by apps found online. Yes, we consider wireframing to be the first stage with designing the user experience, essentially the first visual aid in the whole process, but research is really the proper first thing you would do, call it a foundation, nothing can be started without research, know the kind of users that will interact with this website, finding out about similar apps and so forth.<br/>


         </p>   </div>)
}
,
UI_DESIGN:{name:"UI DESIGN",
text:(<div style={{color:"white"}}> 
         <p style={{textAlign: 'left'}} >
        <h1> <strong>UI DESIGN</strong></h1><br/><br/>
        
        Like the name, User Interface Design is easy to understand. What you currently see as you are reading this is the user interface. The process of designing the visual aspect of a website or an app is the user interface design. <br/>
This encompasses the layout, looks, the style of an application. It consists of the buttons we click on, the text we read, the images, sliders, where we can type, and all the rest of the items the users can interact with.<br/>
When a user interface is done beautifully, most often than not it goes unnoticed by you, but when it is so bad, that’s when you can’t get past it to do what you need to do. Most times UI designers will follow certain principles so the product will come out smoothly. UI designers are graphic designers, they are concerned with the aesthetics, and they need to make sure every single visual element feels united, both aesthetically, and in purpose.<br/>


         </p>   </div>)
}
,
WEB_APP:{name:"WEB APP",
text:(<div style={{color:"white"}}> 
         <p style={{textAlign: 'left'}} >
        <h1> <strong>WEB APP</strong></h1><br/><br/>
        
        Why Do You Need a Web App?<br/><br/>
A web application is a software that can be accessed from a browser, and it is stored on a server. To access it, you would need an internet connection. Web apps are like websites but have more features and functionalities.<br/>
The main purpose of a web application is to help you perform different tasks; a popular example is Gmail. These applications are usually developed in programming languages and frameworks such as HTML, CSS, React.js, Vue.js, Node.js and so forth.<br/>
A web app is different from a mobile application or a desktop application, you do not need to download the application to make use of it. Web applications are often made responsive, meaning it will adapt to the dimensions of the screen of whatever device you are viewing it from, like a smartphone, a laptop or desktop. It usually requires a web hosting service and majority of web applications are dynamic.<br/>
Dynamic web applications allow interactive sessions with a user. You can enter information into this web app to get different results.  An example is searching for something, creating a user profile, posting a comment and so on, while Static web application pages are not easily modified or updated. If there are any changes made, you would have to load the page again. They lack flexibility as they present the same information for every visitor. They are constructed using only HTML and CSS and there is no interaction or personalization with the users. They are not completely useless as companies that only need website that provides key business information and require no user input could make use of them. This type of web application development may be used for quick and economical results by implementing personal portfolios, business contact pages, and some company information pages.<br/>
Here are some of the things essential for a good web application are. First is security, a lot of people are sceptical about sharing personal information, having a safe app is very important. Another good quality is scalability, big word? It simply means as users increase more features can be added easily without affecting the app. Speed in loading is also a critical factor.<br/>



         </p>   </div>)
}
,
WEB_SERVER:{name:"WEB SERVER",
text:(<div style={{color:"white"}}> 
         <p style={{textAlign: 'left'}} >
        <h1> <strong>WEB SERVER</strong></h1><br/><br/>
        
        A web server typically refers to hardware with software working side by side. What do we mean by this? In terms of hardware, anything with a network connection can work as a server, for example, your laptop or phone. In conjunction with the hardware, a web server is a piece of software that 'serves', in other words, responds to your request and gives you web content. These requests are gotten when you are surfing the net. This is how a web server works. First, it must store all the website's files, which includes all images, videos, HTML documents, CSS stylesheets, JavaScript files, and so forth. Then all the information you get needs to be stored somewhere (physical server) for it to be accessible to you whenever you network connectivity and that’s where the web server comes in. other things like database and scripting languages are added to improve the servers’ capabilities. 
	<br/>
Although all this information and files on the website could be hosted on a computer it could be a problem if they are too large, your network could have downtime. Having a dedicated web server is essential, it will always be connected to the internet, which is why companies provide hosting services, and these companies have the data centres with servers that a website owner will need to access and edit their web content. Therefore, it is always essential to find a good hosting service when you want to have a website.
<br/>
A static web server which could be called a stack consists of computer hardware with an HTTP server (software). It is called static because the server sends the files hosted exactly the way it is to your browser, while a dynamic web server consists of a static web server, an application server, and a database. It is dynamic because the application server updates the hosted files before sending content to your browser.
<br/>
Let’s take for example Apache web server, it is an open-source web server that was developed for UNIX systems (UNIX is an operating system) now it is cross-platform, meaning it works on Linus OS, Windows OS, and others. In a web server, Apache serves as the HTTP component. Let’s break this down, Hyper Text Transfer Protocol, taking the words transfer and protocol separately its easier to understand this means transferring data or information under a given set of rules (this is how a browser speaks to the server), Apache then compiles the results from the scripting languages and databases, to generate content that we see. The web service will discover which files on the server do and do not belong to the website, and then control the options available to you.




         </p>   </div>)
}
,
UX_DESIGN:{name:"UX DESIGN",
text:(<div style={{color:"white"}}> 
         <p style={{textAlign: 'left'}} >
        <h1> <strong>UX DESIGN</strong></h1><br/><br/>
        This is understood simply by the name. User Experience Design. This is designed the way a user will interact with a product, a website, app, physical product, and so on, their overall experience. This includes the plan of obtaining and coordinating the system, including parts of design, usability, functionality, and branding, it takes into consideration all the aspects of the products with the user.<br/>
We often hear the term UI/UX design interchangeably, this is because they go hand in hand. One cannot properly design how a user will find the system without working with what the user sees, and that is the User Interface Design.<br/>
When you get a product or you go to a website and you like how easy it is to use or how attractive it seems, this is all the work of the user experience design, the main goal is to improve customer satisfaction with the product, the pleasure of using it which instigates a sense of loyalty to the product from the user’s side, enhancing their experience, and making sure they get what they came looking for and incorporating their feedback.<br/>


         </p>   </div>)
},
FIREWALL:{name:"FIREWALL",
text:(<div style={{color:"white"}}> 
         <p style={{textAlign: 'left'}} >
        <h1> <strong>FIREWALL</strong></h1><br/><br/>
        
        A firewall is a security architecture that serves as a gate that protects a private internal network from the very public internet. It is a network security device that blocks dangerous traffic from your network, malware, suspicious activity, application layer attacks and hackers all based on a set of security rules. The fundamental use of a firewall is of course security, but it has other uses like content filtering. The different type of firewalls includes packet filtering, proxy-based service, next generation, and stateful inspection firewalls. Fortinet FortiGate, Check Point NGFW, pfSense, Cisco ASA Firewall, Sophos XG, Palo Alto Networks NG are some of the most highly rated firewall tools.<br/><br/>
There is also a term referred to as Firewall-as-a-service FWaas. As companies started moving most of their operations to the cloud, they discovered they no longer had a clearly defined network perimeter which is what brought about FWaas. FWaas is the new way of delivering firewall and other network security capabilities through the cloud.<br/><br/>
The firewall protects or filters content of a private network by guarding the traffic at a computer’s entry point known as ports, which is the point information is exchanged with external devices. Take port numbers as rooms which only trusted guests are allowed to enter within your house, then there are rooms which no one can enter without your permission. This is just a simplified way of explaining how a firewall works.<br/><br/>


         </p>   </div>)
},
DATA_STRUCTURE:{name:"DATA STRUCTURE",
text:(<div style={{color:"white"}}> 
         <p style={{textAlign: 'left'}} >
        <h1> <strong>DATA STRUCTURE</strong></h1><br/><br/>
       
Data structure is a way by which data is put in place so it can be used effectively by the computer, it’s processing, storage, and organization. Data structures typically go hand in hand with algorithms. Algorithms are a step-by-step process taken to solve a task. There are different types of data structures. First are the linear data structures which include array, queue, linked list and stack, next is Tree data structure, we have Binary tree, Binary search tree, Binary heap, Binary Hash, then graph, segment tree, and suffix tree <br/><br/>
An array is used to store similar data at connecting locations in an order in a computer’s memory. For example, the marks of students can be accessed just by going to a single location because they have been placed in an array. A queue data structure is exactly like the name, data is arranged in a queue sometimes it may take certain order like the FIFO, First In First Out. Linked lists work with nodes. Each of the nodes are randomly stored and contain its own data and the address of the next node. Together each of the nodes represent a sequence. In data structures a stack follows a LIFO Last In First Out or FILO First In Last Out.<br/><br/>
Binary tree is a structure where each node has not more than two children (sub-divisions), typically known as the left child and right child. Binary search tree is a data structure in which the nodes follow the rule of the key to the left sub-tree being less than the value of its parent or root node key and the value of the key to the right sub-tree is greater than or equal to the value of its parent or root node's key. A Binary Heap is a Tree-based data structure where the tree is a complete binary tree. Binary heaps are classified into two, the max Heap and Min Heap. Hashing as a data structure is the process of mapping keys and values into a hash table by using a hash function.<br/><br/>
Graph data structure is made up of two components, nodes, and edges. A finite set ordered in the form (u, v) also called edge, where v is Number of Vertices. Graph data structures are applied in real world problems like representing telephone or circuit networks. Graphs are also used in social networks like Facebook, each person can be represented as a node. The segment tree data structure is generally implemented when a large number of queries are performed on a set of values. These queries involve the minimum, maximum, and sum of the input range for a given set. The suffix tree data structure is a tool commonly used to parse text strings. It is a digital tree that uses algorithmic methods to reveal the structure of the chain and its subsets.<br/><br/>
Data structure has a wide and diverse range of use in the fields of computer science and software engineering and is used in almost every software program or system that has been developed.<br/><br/>

         </p>   </div>)
},
DATABASE:{name:"DATABASE",
text:(<div style={{color:"white"}}> 
         <p style={{textAlign: 'left'}} >
        <h1> <strong>DATABASE</strong></h1><br/><br/>
       
        A database is one of the most used words in all aspects of information technology. It is a collection of data and information stored within a database software. Databases are made up of these important components. The data, database schema, indexes, database tables (Fields, columns, records, and rows), Keys, database relationships, hardware, software, and the database management system DBMS. <br/><br/>
Let’s explain a few of these components, database software is software that systematically collects, stores, and records data, in essence, manages data. A database schema is a visual representation of how the data in your database will be stored, the two major categories of a database schema are the logical and the physical schema. The database table is the main physical unit of data storage in the database. When users access the database, they often refer to a table to obtain the necessary data. These tables need to be connected to each other and that is where relationships come in. One of the important uses of tables and relationships is to eliminate data redundancy, relationships are created by connecting to keys. The keys, either primary or foreign keys ensure the integrity of data and provide a unique identifier for a row or column in a table.<br/><br/>
The DBMS is a software that acts as a middle ground between users and the database, an interface in which the user can manage, create, read, update, and delete information from a database. It is the platform where the database software can be used. How does a Database Management System work? For example, a user can request for contents in a location field that has the keyword “Africa” and then it provides the source materials in which those characters appear in. A DBMS is managed by a DBA Database Administrator who does performance tuning, monitoring, backup, and recovery. Some of the most popular Database Management Systems include MySQL, Oracle Database, Microsoft SQL Server, PostgreSQL, dBASE, and Microsoft Access<br/><br/>
Now that we have that out of the way, let us move to the types of databases you can find. They include Relational databases, Hierarchical databases, Network databases, Object-Oriented databases, Distributed Databases, Centralized databases, NoSQL databases, Cloud databases, Graph databases, Operational databases, End-User databases, and Personal database. The first four are the major types used with the Relational database being the most widely and commonly used, it has been around since the 1970s.<br/><br/>
A relational database is a type of database where data has a table-oriented arrangement. Why it is the most widely used is because it is simple to compute, other reasons are the ease in accessing data and flexibility to expand. The hierarchical database has a hierarchy orientation for its data arrangement, meaning a parent-child relationship, a one-to-many relationship, for example, a company structure has from CEO to other top management personnel and other supervisors and so on. A Network database is similar to hierarchical but instead, it adopts a many-to-many relationship with data. Object-oriented databases are usually used in applications that require high performance, faster calculations, and results.<br/><br/>

         </p>   </div>)
},
FTP:{name:"FILE TRANSFER PROTOCOL - FTP",
text:(<div style={{color:"white"}}> 
         <p style={{textAlign: 'left'}} >
        <h1> <strong>FILE TRANSFER PROTOCOL - FTP</strong></h1><br/><br/>
       
        
        A system of rules that is governing a communication process is called a Protocol. File transfer protocol FTP is a networking term, it is a network protocol that is used to transfer files between computers. FTP is used as a way for you to communicate and exchange information between two physical computers otherwise known as a client and a server. It is one of the easiest forms of sharing, downloading, or uploading files, although you will require a network connection. Many large companies with large networks use FTP servers to store different versions of software for their network infrastructure devices. It is possible to transfer any file using FTP, which may even be faster than the HTTP you are currently using to view this website.<br/><br/>
Most web browsers already have FTP clients in-built, you have probably used it without realizing, when you download a file from a webpage you have used FTP. When you are executing an FTP process your computer is referred to as the local host, the other computer involved will be the server which is the remote host. The most common way of using FTP is through a web browser, a Graphical User Interface (GUI) FTP client or a command line FTP. FTP has two connection modes, the active and passive modes. In the active mode, the client starts to listen to the random port of the incoming data connection from the server, while in the passive mode, the server uses the command channel to send the client the information needed to open the data channel. Also, active provides security to the FTP server while passive does not. FTP types include anonymous FTP, Secure FTP (SFTP), FTP Secure (FTPS), password protected FTP.<br/><br/>
To set up an FTP you will need a profile name, Host name then the user ID and password. How does an FTP work? You need to log in to the FTP server using the user ID and password credentials (some public FTP servers do not need credentials to access files), then you start your FTP client which is your local host and enter the FTP profile name and the login credentials, the host name which is your website URL and then click on the connect button. You now have access to move files from the server to your host computer.<br/><br/>
Some of the benefits of a File Transfer Protocol include security, backup/disaster recovery, improved workflow, data loading and control. FTP can be used to backup data from one location to a secured server running FTP services, you may never know when there is an issue of compromised or lost data. It is also commonly used to access shared web hosting and cloud services. The improved workflow of having a uniform platform to share information in an organization is a great benefit. SFTP which is Secure FTP provides an additional layer of security to the File Transfer Protocol which helps to encrypt sensitive data.<br/><br/>
The best FTP clients include FileZilla, Cyberduck and WinSCP.<br/><br/>



         </p>   </div>)
},
DOMAIN:{name:"DOMAIN NAME",
text:(<div style={{color:"white"}}> 
         <p style={{textAlign: 'left'}} >
        <h1> <strong>DOMAIN NAME</strong></h1><br/><br/>
      
There are many essentials you need when you are ready to get your website up and running, asides from the content and hosting, one of them being your Domain Name, combined with some information like the protocol, this is your websites physical address or “web address” or ‘’ÚRL”. The computer has an actual Internet Protocol IP address that they use but it is too complicated to be used by a regular user, which is where the DNS comes in, the Domain Name System. A domain name is more user friendly, typically you would have to type a website’s full IP address. Let us not confuse a domain name with a URL, ‘’linkedin.com’’ – this is a domain name while ‘’https://www.linkedin.com/’’ - this is a URL.<br/><br/>
What you type into a browser to lead you to a particular website is called the domain name, it is the unique identity of a website online, these names are all managed by domain registries and two websites cannot have the same name. ICANN (Internet Corporation for Assigned Names and Numbers) specifies which domain name extensions are available and keep a centralized database for them. A domain name can consist of either letters or numbers or a combination of both. The “.” Symbol is what is used to separate the different subdivisions of a domain name.<br/><br/>
A Subdomain is a subdivision of a domain that can be created by the owner of a domain name, for example, ‘’linkedin.blog.com’’ .blog here is the subdomain. They are useful because they do not require you to purchase an additional name for a division within your site, especially for web content you want separate from the main site.<br/><br/>
We also have what is called a Top-Level Domain. It refers to the last part of a domain name (domain suffix), which includes<br/><br/>
.com – for commercial business, which is the most common<br/>
.org – for organizations <br/>
.gov – government agencies<br/>
.edu – for educational institutions<br/>
.net – for network organizations<br/>
.mil – for military<br/><br/>
Top Level Domains are majorly classified into two, the generic top-level domains and country-code top-level domains. Generic Top-Level Domain identifies with the domain class that is associated with .com, .org, .edu and the likes. The country code Top-Level Domain is made up of just two letters and is used for regions, countries, or geographic location such as .ng or .us, assigned to a country, or territory.<br/><br/>
The price of a domain name is usually around $15 per annum, but it varies.<br/>




         </p>   </div>)
}
,
ANGULAR:{name:"ANGULAR",
text:(<div style={{color:"white"}}> 
         <p style={{textAlign: 'left'}} >
        <h1> <strong>ANGULAR</strong></h1><br/><br/>

        What is Angular? It is a JavaScript framework that is Typescript based (a programming language highly coined from JavaScript), Angular is an open source platform for web applications. In case you are not too familiar with the word “open source”, it means, any software that is under a license that permits users with the right to edit or distribute it. <br/>
Let’s go into understanding what a framework is, it is simple, frameworks are developed so programmers do not have to start from scratch, they have cut out the need to have a mastery of a particular programming language. A programming framework is a software that allows users to include additional code, which contains a specific function, to an existing programming language in order to make it an application-specific software, thereby creating what may seem like a new programming language but in reality, is an easier version for a specific task you would like to accomplish.<br/>
Angular is managed by a team at Google and a community of individuals and corporations, they do a good job of keeping it regularly updated. It is a development platform used to create efficient single-page web applications. What is Single page application? SPA’s work in the sense that once the web page is loaded it does not need to reload the whole application when you navigate through the site and go to a different page, it only just changes data that is transmitted to and from the server, a common example is Gmail or Netflix. Single Page Applications are neat, maintainable, and easily testable.  Angular is not the same with AngularJS, which is based on JavaScript and not TypeScript programming language, AngularJS is not really supported anymore and basically Angular is an improvement on it.<br/>
Asides from the fact that it provides a platform for efficient single page applications, a key benefit of Angular is that a user can create desktop-installed apps across Mac, Windows, and Linux using the same Angular methods. Also, it has a feature called Angular Materials which follows the Material Design guidelines created by Google. Angular Materials has built in components such as navigation elements, layout, buttons, indicators, and data tables which allows for a very appealing user interface design. Angular has a structure that allows you make changes in the model and it shows in the view layer almost immediately, which is why developers favor it when creating large scale projects.<br/>




         </p>   </div>)
}


,
BUG:{name:"BUG",
text:(<div style={{color:"white"}}> 
         <p style={{textAlign: 'left'}} >
        <h1> <strong>BUG</strong></h1><br/><br/>

       
Just like the buzzing bugs that we see in real life, bugs in coding are equally as annoying. In programming bugs are also known as errors. There are different kinds of errors in coding. They are majorly divided into logical errors, semantic errors and syntax errors.<br/>
Logical errors are errors in the logic of the code. Let’s say you have a code to add two numbers, it complies and runs accordingly, but when you put in the two numbers to add the result is not what it is supposed to be, it could be subtracting them or otherwise. It is not exactly a wrong code but it is not right either. Because the code is running without showing any errors, logical errors are usually the hardest to track, you would usually need to go to the beginning and go through what you want the code to actually do.<br/>
Syntax errors are grammatical errors in the syntax of the programming language, which means the way a language is supposed to be written, the compiler cannot ignore these once you try to run a program. For example, printf (“Hello world” – this will give an error because it is supposed to contain a closing parenthesis “)”. As a programmer the regularity at which you code will make it possible to have very few syntax errors or none at all. Also, many IDE’s Integrated Development Environments or text editors come with features that warn you when you have these kinds of errors. <br/>
Semantic errors are the errors you get when the programming statement is not understood by the computer. Although it may seem somewhat similar, logic errors produce wrong data while semantic errors produce nothing meaningful at all.<br/>
Software development is not exactly a walk in the park and errors are somewhat inevitable, but in the current age we are in it is easier to locate these errors and find a quick solution to them.



         </p>   </div>)
},
AGILE:{name:"AGILE",
text:(<div style={{color:"white"}}> 
         <p style={{textAlign: 'left'}} >
        <h1> <strong>AGILE</strong></h1><br/><br/>

        The term Agile Development may often be heard together with other phrases like Waterfall Model, V-Shaped Model, Prototyping, Spiral Development Method (SDM), and Iterative Method<br/>
Agile is a software development method chosen by most software developers and project managers in the course of developing software. It is one of the processes which can be adopted in a software development life cycle. It is used more often than not because it is also based on the Iterative and incremental model. Other key advantages are the shorter time required to develop some system features that the clients have requested available, it provides the opportunity to assess the direction of a project. There is also lots of interaction with the clients, face-to-face communication, and continuous inputs from clients which leaves no space for guesswork and results in high-quality software in a short time duration.<br/>
Agile methodology is a way of managing a project by dividing it into several phases, it is actually a cycle. It involves constant collaboration with stakeholders and continuous improvement at every stage. Once work begins, teams go through a process of planning, execution, and evaluation. The phases involved in agile include Planning - this is the stage where the stakeholders or clients specify what they want the software to be, its requirements, functionality design and so on, next is the Design – when the requirements collated are put into a pictorial format this is the user experience/design stage, next is Development – when the user interface is coded, putting life to the design, next is Testing – where what has been developed is tested for possible errors, next is Deployment – the software is ready for use in this stage and finally the last stage which is feedback – here as the name implies feedback on the software is provided by stakeholders. This process continues till the client is satisfied like was mentioned earlier it is an iterative process. <br/>
Some of the Agile Software development methodologies (frameworks) include eXtreme Programming, Scrum, Agile Modeling, Adaptive Software Development (ASD), Adaptive Project Framework (APF).<br/>



         </p>   </div>)
},
APPLICATION:{name:"APPLICATION",
text:(<div style={{color:"white"}}> 
         <p style={{textAlign: 'left'}} >
        <h1> <strong>APPLICATION</strong></h1><br/><br/>

        The application definition is not limited to application software ", application programming interfaces (API), application server, application virtualization, application management, application life cycle management and portable applications, they are concepts that apply to all computer programs, not just application software. <br/>
Application software or more popularly known as application or app is a computer program that is designed to perform a specific task or a series of activities, such as document generation, worksheets, online search, database, sending of e-mails, publications and, of course, games. We use applications every day, the browser you are using to see this is, in fact, an application, it works on all modern devices such as phones, tabs and others like this. A popular application is Microsoft Office Suites or commonly office, which provides software and client and server services developed by Microsoft, such as spreadsheet tools, etc. <br/>
Making the right choice for appropriate application software for business or personal use can improve function and efficiency. If you don't understand your options, you could end up with something that doesn't benefit your business at all. Relying on mobile applications to perform more critical business tasks are more rampant than ever. For the business to thrive you need to make sure you get a clear understanding of what apps are available in the marketplace, do your research! assess how they are relevant to your daily business activities and finally get feedback once it’s up and running.<br/>
Most people will prefer web applications to desktop applications, here is why. Web applications are platform independent or cross-platform; this means they can run on different types of operating systems as long as the device has a web browser and an internet connection. The name desktop application is a simple as you see it, the app needs to be on the desktop. These applications need to be physically installed on the device unlike a web app that is hosted online, which leads to the point of ease of access. Of course, we cannot compare carrying a laptop or desktop about to having just our phones. Phones are portable, more convenient and easily accessible. As much as web applications may be preferred it also has its drawbacks which include but are not limited to security, reliance on the internet (downtime, speed and sometimes fees or hosting costs), and of course a very important point is privacy. <br/>
Web applications are important and widely used but of course they may never fully replace the need for some desktop applications.<br/>



         </p>   </div>)
},
LIBRARIES:{name:"LIBRARIES",
text:(<div style={{color:"white"}}> 
         <p style={{textAlign: 'left'}} >
        <h1> <strong>LIBRARIES</strong></h1><br/><br/>

        Want to be able to speak amongst your tech friends and hold your ground? A library is definitely a term you need to be familiar with.<br/>
         This isn’t the place we go to to find millions of books and resources but it definitely serves the purpose of a traditional library in terms of providing you the information you need.<br/>
What is a programming library and why are they important?<br/> A “standard library” is a collection of pre written (reusable) code, which is usually very useful, the code could be a simple function or a collection of functions, variables, classes, message templates or data structures.<br/>
 The format a library would take usually depends on the programming language and also the operation system used (windows, Linux etc).<br/>
  libraries prevent you from having to re do something that is common across platforms.<br/>
   It is essentially like, well someone has done it why not use that and build on it, it saves a whole lot of time and removes stress from coding.<br/>
    When you use a library, the program gains the behavior put inside that library. <br/>
Some library examples in programming languages are, NumPy and Keras which are used in Python, p5 which is used in JavaScript, libC in C programming on so many others including in the languages that have been mentioned.<br/>


         </p>   </div>)
}
}

export default keysObject