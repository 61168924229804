import React from 'react';
import Fade from 'react-reveal/Fade';
import Text from '../../../../common/src/components/Text';
import Image from '../../../../common/src/components/Image';
import Button from '../../../../common/src/components/Button';
import Container from '../../../../common/src/components/UI/Container';
import BannerWrapper, {
  BannerContent,
  BannerImage,
  CustomerWrapper,
  ImageWrapper,
} from './banner.style';
import { client } from '../../../../common/src/data/AppModern';
import circleBorder from '../../../../common/src/assets/image/appModern/shape.svg';

import Promotion from './Promotion'





const Banner = () => {



const googleAds=()=>{
  window.gtag('event', 'purchase', {
    send_to: 'AW-1053360236/Nd2-CIid17wCEOyApPYD',
    value: 0,
    currency: 'BRL',
  });
}



 const DescriptionCourse=(<div> <Fade up delay={100}>
  <h2 style={{color:"white"}}>בית תוכנה:</h2>
  <h1>מוטו ישראל</h1>
  
  <div style={{clear: "both", dir:"ltr"}}>
</div>
<br/>

</Fade>
<Fade up delay={200}>
  <Promotion/>

</Fade></div>
)



  return (
    <BannerWrapper id="home" dir="rtl" >
      <Container >

      <BannerImage>
        <div style={{color:"white"}}>
       <h1> לחץ על הכפתור המבוקש:</h1><br /><br />
      <Button title="הזמנת שרותי קידום אורגני, רכישה ישירה"  onClick={()=>{window.location.href="https://motto.co.il/seo-payment" }} style={{width:"100%"}}/><br /><br />
      <Button title="הזמנת שרותי קידום ממומן, רכישה ישירה"  onClick={()=>{window.location.href="https://motto.co.il/מחירי%20קידום%20ממומן" }} style={{width:"100%"}}/><br /><br />
      <Button title="הזמנת שרותי פיתוח עבור אתר סטטי רספונסיבי, רכישה ישירה"  onClick={()=>{window.location.href="https://motto.co.il//development-payment" }} style={{width:"100%"}}/><br /><br />
      <Button title="הזמנת חבילת שעות לפיתוח וקידום, רכישה ישירה"  onClick={()=>{window.location.href="https://motto.co.il/Hours-package-payment" }} style={{width:"100%"}}/><br /><br />
      <Button title="הזמנת באנר לצורך קידום ממומן, רכישה ישירה"  onClick={()=>{window.location.href="https://motto.co.il/banner-payment" }} style={{width:"100%"}}/><br /><br />
      <Button title="הזמנת שרותי קידום ופיתוח, תשלום כללי"  onClick={()=>{window.location.href="https://motto.co.il/General-payment" }} style={{width:"100%"}}/><br /><br />
        </div>
        </BannerImage>

        <BannerContent >
         {DescriptionCourse}
        </BannerContent>
       
      </Container>


      <CustomerWrapper>
        <Text content="מהגלריה שלנו:" />
        <ImageWrapper>
         {client.map(item => (
            <a href={item.link} target="blank">
            <Image
              key={`client-key${item.id}`}
              src={item.image}
              alt={item.title}
            /></a>
          ))}
           </ImageWrapper>
      </CustomerWrapper>
      
      
      <img
        className="bannerBottomShape"
        src={circleBorder}
        alt="Bottom Circle"
      />
    </BannerWrapper>
  );
};

export default Banner;
