import React ,{useState}from 'react';
import Fade from 'react-reveal/Fade';
import Text from '../../../common/src/components/Text';
import Image from '../../../common/src/components/Image';
import Container from '../../../common/src/components/UI/Container';
import BannerWrapper, {
  BannerContent,
  BannerImage,
  CustomerWrapper,
  ImageWrapper,
} from './banner.style';
import ContactUsForm from '../ContactUsForm'
import { client } from '../../../common/src/data/AppModern';
import bannerImg from '../../../common/src/assets/image/appModern/banner2.png';
import videoBanner1 from '../../../common/src/assets/image/appModern/video-1.mp4';
import circleBorder from '../../../common/src/assets/image/appModern/shape.svg';
import ButtonStyle from '../../../common/src/components/Button/button.style';





const Banner = () => {
  const [Video,setVideo]=useState(true)
  const [ShowForm1,setShowForm1]=useState(false)
  

  return (
    <BannerWrapper id="home" dir="rtl" >
      <Container >
        <BannerContent >
         
          <Fade up delay={100}>
            <h2 style={{color:"white"}}>בית תוכנה:</h2>
            <h1>מוטו ישראל</h1>
            
            <div style={{clear: "both", dir:"ltr"}}>
   
</div>
<br/>

          </Fade>
          <Fade up delay={200}>
            <Text dir='rtl'
              content="פתרונות מתקדמים במחשבים, פיתוח מערכות, אפליקציות לניידים ואתרים."
            />
          </Fade>
          <Fade up delay={300}>
              <center>
              {!ShowForm1&&<ButtonStyle className="trail" onClick={()=>{setShowForm1(!ShowForm1)}} >

{ShowForm1?"סגור טופס":"לקביעת פגישת יעוץ ללא תשלום - לחץ כאן"}
    </ButtonStyle >}
              {ShowForm1&&<ContactUsForm message="לקביעת פגישת יעוץ ללא תשלום - אנא מלא את הפרטים" position="bottom center" setVideo={setVideo}/>}
              </center>
              
            <br/><br/>
          </Fade>
          <center>
          {Video?<video id="myVideo1" width="320" height="176" controls>
  <source src={videoBanner1} type="video/mp4" />
 
  Your browser does not support HTML5 video.
</video>:<div><br/><br/><br/><br/><br/><br/><br/><br/><br/></div>}
</center>
           
          
        </BannerContent>
        {Video&& <BannerImage>
          <Fade up delay={100}>
            <Image src={bannerImg} alt="Banner" />
          </Fade>
        </BannerImage>}
      </Container>


      <CustomerWrapper>
        <Text content="מהגלריה שלנו:" />
        <ImageWrapper>
         {client.map(item => (
            <a href={item.link} target="blank">
            <Image
              key={`client-key${item.id}`}
              src={item.image}
              alt={item.title}
            /></a>
          ))}
           </ImageWrapper>
      </CustomerWrapper>
      
      
      <img
        className="bannerBottomShape"
        src={circleBorder}
        alt="Bottom Circle"
      />
    </BannerWrapper>
  );
};

export default Banner;
