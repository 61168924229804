import React from 'react'

const Promotion =()=>{
      return (
        <div style={{color:"white"}}>
בכל צורה שתסתכל, זה יצא לך יותר זול מכל דרך אחרת...<br />
<br />
<h1>מנהל מיקור חוץ  (Outsourcing Manager)</h1><br />
הרעיון הכללי הוא להוציא את הליך פיתוח פרויקטים בתחומי המחשבים לביצוע ופיתוח ע"י גורמי חוץ (הודים, אוקראינים, סיניים וכו') על מנת להוזיל את עלויות הפיתוח.<br />
בדברינו אלו אנו מתכוונים לפיתוח סטארטאפ, אתר, אפליקציה או כל מערכת קוד <br />
מזה מיקור חוץ?<br />
<a href="https://he.wikipedia.org/wiki/%D7%9E%D7%99%D7%A7%D7%95%D7%A8_%D7%97%D7%95%D7%A5" target="blank" >ההגדרה בוויקיפדיה:</a> <br />
"מִיקּוּר חוּץ (תרגום מילולי מאנגלית: Outsourcing, נקרא גם: אָאוּטְסוֹרְסִינְג) הוא שיטת ניהול מודרנית, שבבסיסה עומד הרעיון להוציא מהארגון את תפעול הפעילויות שאינן נמצאות בבסיסו ולהעבירן לחברות אחרות או לקבלן, ולהותיר בתפעול ובניהול ישיר רק את פעילויות הליבה. שיטה זו מאפשרת לארגון להתמקד בתחום התמחותו, להשקיע את מרב המשאבים בכך, ולקיים מוטת שליטה יעילה על האגפים היצרניים בו."<br /><br />
מזה מנהל מיקור חוץ?<br />
מנהל מיקור חוץ היא המערכת או האדם שמנהל את תהליך המיקור כלומר מנהל את תהליך הפיתוח של המערכת <br />
למה זה משתלם?<br />
עלויות העובדים בארצות המתפתחות היא נמוכה ביותר ביחס ל עלויות המפתחים בארץ ולכן גם עם עלויות הניהול יוצא שעלות הפיתוח, בצורה זו, היא זולה ביותר<br />
אם למשל מפתח ממוצע בארץ לוקח 120 ₪ לשעה ומפתח ממוצע במדינות המתפתחות מקבל 40 ₪ לשעה נוסיף לזה עוד 1000 ₪ עבור מנהל הפרוייקט – פיתוח אפליקציה שדורשת 100 שעות עבודה יעלה בארץ 12000 ובשיטת מיקור חוץ – 5000 ₪ פחות מחצי!!<br />
באיזה אופן שנסתכל על זה, יצא לנו יותר זול בשיטה זו!<br />
למי זה מיועד?<br />
כל מי שיש לו רעיון לביצוע בפרוייקט שאינו דורש נוכחות פיזית בארץ של הפועל (לא פועלי ביניין וכדומה), עם זה יש לציין שאנו במוטו מתעסקים עם פיתוח בתחום הקוד בלבד כלומר פיתוח אפליקציות ואתרים<br />
למה דווקא אנחנו, בישראל נשתמש בשיטה זו של מיקור חוץ?<br />
ראשית יש לציין שלא רק בישראל משתמשים בשיטה זו, גם בארה"ב ועוד משתמשים בשיטה זו בהיקף נרחב<br />
עם זה יש לציין שמספר הסטארטפים המותחלים בארץ הינו גבוה בהרבה ביחס לממוצע הכללי<br />
באופי הישראלי של יוזמה ויצירתיות מוביל אותנו לפיתוחים חדשים ומתאים יותר לצורת הניהול והיזמות של הפיתוח לעומת הבניה ממעשית של הקוד שמתאימה יותר למאפיני אופי אחרים<br />
<br />
מי אני?<br />
<br />
עומר שירן, מפתח ויזם במקצועי, בעל נסיון רב בפיתוח וניהול פרויקטים בתחום האתרים והאפליקציות<br />
ניהלתי כבר כמה פרויקטים, חלקם עם מפתחים ישראליים וחלקם עם בשיטה זו של מיקור חוץ<br />
בהליכי הפיתוח של הפרויקטים השונים פיתחתי הן את כישרון הפיתוח והניהול של הפרויקטים והן את הקשרים עם מתכנתים איכותיים ברחבי העולם על מנת להציע לכם את המיקור חוץ בצורה מקצועית ולהוביל לתוצאות מקצועיות ובאיכות הטובה ביותר<br />
מה אני מציע?<br />
אני מציע לכם לנהל עבורכם את הליך המיקור על מנת להוזיל את העלויות משלב האפיון והפיתוח ועד שלב העלאה לאינטרנט או לחניות השונות<br />
<br />


        </div>
      );
    }
export default Promotion