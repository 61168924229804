import React ,{useState}from 'react';
import Fade from 'react-reveal/Fade';
import Text from '../../../../../common/src/components/Text';
import Image from '../../../../../common/src/components/Image';
import Button from '../../../../../common/src/components/Button';
import Container from '../../../../../common/src/components/UI/Container';
import BannerWrapper, {
  BannerContent,
  BannerImage,
  CustomerWrapper,
  ImageWrapper,
} from './banner.style';
import { client } from '../../../../../common/src/data/AppModern';
import videoBanner1 from '../../../../../common/src/assets/image/appModern/video-course200.mp4';
import circleBorder from '../../../../../common/src/assets/image/appModern/shape.svg';
import axios from 'axios'




const Banner = () => {
  const [Name,setName]=useState("")
const [Email,setEmail]=useState("")
const [Phone, setPhone]=useState("")
const [Message, setMessage]=useState("")

const[OpenForm,setOpenForm]=useState(0)
// 0- מצב בסיסי טופס פרטים פתוח
// 1- הטופס נשלח בהצלחה
// 2- בעיה במשלוח הטופס
// 3- טופס רישום 
// 4- השארת פרטי תשלום

const creditUrl="https://meshulam.co.il/s/8a6f5a53-c224-553c-14f7-5f099166b331" 

const handleChange=(e)=>{

  switch(e.target.name){
case ("name"):
setName(e.target.value)  

break

case ("email"):
setEmail(e.target.value)  

break

case ("phone"):
setPhone(e.target.value)  

break

case ("message"):
setMessage(e.target.value)  

break




  }
}
let bgcolor,
 formTitleText="מעוניין להיות מפתח תוכנה? - טופס התעניינות", 
 formTitleMesage=<div>מעוניין בפרטים נוספים? <br/>רק תשאיר פרטים!</div>, 
 paymentNotice,
 formButtonText="שלח",
 subjectForEmail="הודעה חדשה באתר מוטו בנושא טופס התעניינות בקורס פיתוח:  "
if(OpenForm===3)
{
  bgcolor="orange";
  formTitleMesage=<div>הזדרז להרשם!<br/> מספר המקומות מוגבל</div>;
  formTitleText="מעוניין להיות מפתח תוכנה? - טופס רישום";
  paymentNotice="עם השלמת הרישום יגבו 200 ש\"ח עבור החודש הראשון"
  formButtonText="מעבר לתשלום מאובטח"
  subjectForEmail="הודעה חדשה באתר מוטו בנושא טופס רישום לקורס פיתוח:  "
}
const form=( <center><form dir="rtl" style={{
  color:"white",
  backgroundColor: bgcolor,
  maxWidth: '500px',
}}>
   <fieldset>
   <legend style={{color:"white"}}>{formTitleText}</legend>
 
 
    השם שלך:<br/>
    <input  type="text" name="name"  value={Name} onChange={handleChange}/>
<br/>
    <br/>האימייל שלך:<br/>
    <input  type="text" name="email"  value={Email} onChange={handleChange} />
<br/>
    <br/> הטלפון שלך:<br/>
    <input  type="text" name="phone"  value={Phone} onChange={handleChange} />

    <br/>
    {paymentNotice}
<br/>
<center>
<Button  title={formButtonText}  onClick={()=>{
if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Email)))
alert("האימייל לא תקין- נא הכנס אימייל תקין!")
else
{
  
  axios({
method:"post",
url:" https://us-central1-sender-4330e.cloudfunctions.net/app/send_email_client",
data:{to:"omershiran@gmail.com",from:"omershiran@gmail.com",subject:subjectForEmail, text:(Name+"  "+Email+"  "+Phone+"  "+Message)}
}).then((v)=>{
  if(v.data===1001){setOpenForm(OpenForm+1)}
else{
  
  setOpenForm(2)}
}).
  catch(e=>{ 
    setOpenForm(2)})
    setOpenForm(5)
}}}/></center>
</fieldset>
</form>
<h1 style={{color:"white"}}>מהרו להרשם! <br/>הקורס הקרוב מתחיל ב <br/>א' בשבט תשפ"א, 14 לינואר 2021<br/>
מספר המקומות מוגבל!</h1>
</center>)

const courstText=(<div><h2>קורס כניסה לעולם ההייטק / פיתוח ותכנות</h2>
<h3>קורס מקוון</h3><br/>
תחום הפיתוח הינו תחום עשיר בשפות תכנות ומושגים.<br/>
עשיר במקומות עבודה ובאפשרויות עבודה ובאפיקי פריצה והתקדמות<br/>
אבל גם תחום דורש: דורש מאמץ, דורש השקעה, דורש יכולות.<br/>
<br/>
לא לכל אחד מתאים להיות מפתח תוכנה הן מצד היכולות הגבוהות הנדרשות לפיתוח הן מצד לחץ העבודה הנדרש הן מצד משמעת העבודה והלמידה העצמית הנדרשת והן מצד נטית הלב לתחום זה.<br/>
פעמים רבות אנשים מתחילים ללמוד פיתוח תוכנה ומפסיקים באמצע מאחת הסיבות שהזכרנו או שמגלים שאכן תחום הפיתוח הוא התחום שלהם אבל לא בחרו בשפת התכנות הנכונה או בכלי הפיתוח הנכונים.<br/>
לכן אנו, בבית התוכנה מוטו ישראל מציעים קורס היכרות עם עולם מרתק זה <br/>
מה נלמד בקורס?<br/>
נתחיל עם html אבל נתמקד בעיקר בשפת התכנות JavaScript  (גאווה סקריפט) שהיא, היום, שפת התכנות הפופולרית ביותר<br/>
נלמד את יסודות התכנות <br/>
נכיר מושגי יסוד: משתנים, פונקציות, פרמטרים ועוד<br/>
והמון מושגים כללים בתכנות כגון Full Stack, html, Frontend, Backend, קוד פתוח, Data base, Android, Server, Server less<br/>
<br/>
נכיר פורטלים, עזרים ושרותים לפיתוח תוכנה כגון: Git, Github, Firebase, aws ועוד המון כלים<br/>
ונעשה המון תרגולים<br/>
משך הקורס הינו 3 חודשים עם 2 מפגשים שבועיים של שני שעורים כל אחד (לימודי בוקר)<br/>
<br/>
מה נקבל מקורס זה? – את ההכרות עם מושגי היסוד<br/>
הקורס הינו קורס מבוא לתכנות ואנו ממליצים להמשיך אחר כך להתמקצע במסלול הייחודי המתאים לכל אחד<br/>
מסלולי ההמשך שמוצעים על ידינו יפורסמו בהמשך בקשר המשתתפים<br/>
<br/>
הרשמה מחייבת? – כן, אבל ניתן להפסיק בכל שלב והסכום שיגבה הינו רק התשלום של החודש ששולם<br/>
כלומר – אין החזר על החודש ששולם או שהתחיל <br/>
אבל ניתן להודיע על הפסקת הלימודים בכל שלב ולהפסיק את התשלום<br/>
<br/>
האם אחרי הקורס ניתן למצא עבודה בתכנות?<br/>
חלק יוכלו למצא עבודה בתכנות כבר אחרי קורס זה או תוך-כדי הקורס אבל אנחנו ממליצים להמשיך להתמקצע באחד המסלולים של Frontend או  Backend המוצעים על ידינו<br/>
<br/>
מהו אופן הלמידה והיכן מתקיים הקורס?<br/>
הקורס הוא קורס מקוון - הלמידה ב zoom  מכל מקום שאתה רוצה<br/>
רק תוודא שיש לך תקשורת טובה ומחשב זמין <br/>
<br/>
האם זה מסלול של Fullstack? לא, זהו מסלול הכרות, במסלולי ההמשך יש מסגרות של Fullstack<br/>
מתי מתחיל הקורס הקרוב? - הקורס הקרוב מתחיל ב כט' בטבת תשפ"א 13 לינואר 2021
הזדרז להרשם, מספר המקומות מוגבל
<br/>
</div>
)
const AfterProperDelivery=(<h2 style={{color:"white"}}>ההודעה נשלחה בהצלחה</h2>)
// const AfterProperRegistration=(<h2 style={{color:"white"}}>הבקשה לרישום התקבלה, היא תושלם עם התשלום,<br/> אנו ניצור עימך קשר בהקדם </h2>)
const AfterUnProperDelivery=(<h2 style={{color:"white"}}>תקלה במשלוח ההודעה, ההודעה לא נשלחה,<br/> אנא נסה שנית במועד מאוחר יותר</h2>)
const DirectMessageAfterSending=(<h2 style={{color:"white"}}>ההודעה נשלחת אנא המתן...</h2>)

let formContent
switch(OpenForm){
  case (0):
    formContent=form
  break;
  case (1):
    formContent=AfterProperDelivery
  break;
  case (2):
    formContent=AfterUnProperDelivery
  break;
  case (3):
    formContent=form
  break;
  case (4):
    window.location.href =creditUrl
  break;
  case (5):
    formContent=DirectMessageAfterSending
  break;
}

const formCourse=(<Fade up delay={100}>
  <h2 style={{color:"white"}}>מעוניין להיות מפתח תוכנה? &nbsp; &nbsp; &nbsp;
  <Button  title= "לרישום ישיר לחץ כאן" onClick={()=>{setOpenForm(3)}} />
  </h2>
  <h1 style={{color:"white"}}>{formTitleMesage}</h1>

{formContent}
<center>
<video id="myVideo1" width="320" height="176" controls>
  <source src={videoBanner1} type="video/mp4" />
 
  Your browser does not support HTML5 video.
</video></center>
 </Fade>)
 const DescriptionCourse=(<div> <Fade up delay={100}>
  <h2 style={{color:"white"}}>בית תוכנה:</h2>
  <h1>מוטו ישראל</h1>
  
  <div style={{clear: "both", dir:"ltr"}}>
</div>
<br/>

</Fade>
<Fade up delay={200}>
  <Text dir='rtl'
    content={courstText}
  />

</Fade></div>
)


  return (
    <BannerWrapper id="home" dir="rtl" >
      <Container >

      <BannerImage>
          {formCourse}
        </BannerImage>

        <BannerContent >
         {DescriptionCourse}
        </BannerContent>
       
      </Container>


      <CustomerWrapper>
        <Text content="מהגלריה שלנו:" />
        <ImageWrapper>
         {client.map(item => (
            <a href={item.link} target="blank">
            <Image
              key={`client-key${item.id}`}
              src={item.image}
              alt={item.title}
            /></a>
          ))}
           </ImageWrapper>
      </CustomerWrapper>
      
      
      <img
        className="bannerBottomShape"
        src={circleBorder}
        alt="Bottom Circle"
      />
    </BannerWrapper>
  );
};

export default Banner;
