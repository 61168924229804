import React from 'react';
import Text1 from '../../../../common/src/components/Text';
import Image from '../../../../common/src/components/Image';
import Container from '../../../../common/src/components/UI/Container';
import BannerWrapper, {
   CustomerWrapper,
  ImageWrapper,
} from './banner.style';
import { client } from '../../../../common/src/data/AppModern';
import Text from './text'
import Login from 'dugoly-login'




const Banner = () => {

 return (
    <BannerWrapper id="home" dir="rtl" >
      <Login />
    <Text />
       
      <CustomerWrapper>
        <Text1 content="מהגלריה שלנו:" />
        <ImageWrapper>
         {client.map(item => (
            <a href={item.link} target="blank">
            <Image
              key={`client-key${item.id}`}
              src={item.image}
              alt={item.title}
            /></a>
          ))}
           </ImageWrapper>
      </CustomerWrapper>
      
      
      
    </BannerWrapper>
  );
};

export default Banner;
