import React, { useContext } from 'react';
// import userContext from '../context/user-context'
import { BrowserRouter, Route, Switch, Link, NavLink, Redirect } from 'react-router-dom';
// import Header from './containers/AppModern/Navbar'
import App from './App'
import OfferCourses from './containers/AppModern/OfferCourses'
import course200 from './containers/AppModern/OfferCourses/course200'
import thanks from './containers/AppModern/OfferCourses/course200/Thanks'
import SiteDevelopmentOrderForm from './containers/AppModern/SiteDevelopmentOrderForm'
import ApplicationDevelopmentOrderForm from './containers/AppModern/ApplicationDevelopmentOrderForm'
import CardPopup from './containers/AppModern/OfferCourses/course200/cards/CardPopup'
import ServiceOffer from './containers/AppModern/ServiceOffer'
import Promotion from './containers/AppModern/Promotion'
import Promotion_direct_order from './containers/AppModern/Promotion_direct_order'
import Promoting_funded_direct_order from './containers/AppModern/Promoting_funded_direct_order'
import Advertising from './containers/AppModern/Promotion_advertising'

import ContactUs from './containers/AppModern/ContactUsPage'
import ConfidentialityAgreement from './containers/AppModern/ConfidentialityAgreement'
import InvestorRecruitment from './containers/AppModern/InvestorRecruitment'
import Drushim from './containers/AppModern/Drushim'
// import Shukater from './containers/AppModern/shukatar'
import NotFoundPage from './containers/AppModern/NotFoundPage/index'
import Shukatar from './containers/AppModern/shukatar';
import this_is_it from './containers/AppModern/this-is-it';
import Tikatar from './containers/AppModern/tikatar';
import TextToHtml from './containers/AppModern/text_to_html';
import SEOLetter from './containers/AppModern/SEOLetter'
// import AcreStory from './containers/AppModern/Appendices/acre'
import SeoDictionary from './containers/AppModern/Dictionaries/SEO/Keys'
import SeoGeneral from './containers/AppModern/Dictionaries/SEO/Values/general'
import ProgrammingDictionary from './containers/AppModern/Dictionaries/programming/Keys'
import Programming_enDictionary from './containers/AppModern/Dictionaries/programming_en/Keys'
import ProgrammingGeneral from './containers/AppModern/Dictionaries/programming/Values/general'
import Programming_enGeneral from './containers/AppModern/Dictionaries/programming_en/Values/general'

import ProgrammingQA from './containers/AppModern/Dictionaries/QAprogramming/Keys'
import ProgrammingQAGeneral from './containers/AppModern/Dictionaries/QAprogramming/Values/general'


import Appendices from './containers/AppModern/Appendices/Appendices/Keys'
import AppendicesGeneral from './containers/AppModern/Appendices/Appendices/Values/general'
import WebsiteWithoutObligation from './containers/AppModern/website-without-obligation'
import OperationTerms from './containers/AppModern/website-without-obligation/Banner/operation-terms'
import Blog from './containers/AppModern/blog'
import Promotion_helpUs from './containers/AppModern/Promotion-helpUs'
import Thanks from './containers/AppModern/Thanks'
import Terms_of_Use from './containers/AppModern/Terms-of-Use'
import Direct_payments from './containers/AppModern/Direct_payments'
import Outsourcing_Manager from './containers/AppModern/Outsourcing_Manager'
import Brainstorming from './containers/AppModern/Brainstorming'
import programmers_registration from './containers/AppModern/programmers_registration'
import Chrome_extensions from './containers/AppModern/Chrome_extensions'

import prices_general from './containers/AppModern/prices/general'
import prices_promoting_funded from './containers/AppModern/prices/promoting_funded'
import prices_development from './containers/AppModern/prices/development'
import salesperson_fee from './containers/AppModern/prices/salesperson_fee'
import agent_contract from './containers/AppModern/agent_contract'
import DcpPayment from './containers/AppModern/DcpPayment'


const AppRouter = () => {
    
    
  return(
  <BrowserRouter>
    <div>
    {/* <Switch>
    
    <Route component={Header} />
      </Switch> */}


      <Switch>
        <Route path="/" component={App} exact={true} />
        <Route path="/SiteDevelopmentOrderForm" component={SiteDevelopmentOrderForm} />
        <Route path="/ApplicationDevelopmentOrderForm" component={ApplicationDevelopmentOrderForm} />
        <Route path="/course200" component={course200} exact={true} />
        <Route  path="/course200/thanks" component={thanks} />
        <Route path="/CreditCard" component={CardPopup} />
        <Route path="/OfferCourses" component={OfferCourses}/>
        <Route path="/DcpPayment" component={DcpPayment}/>
      
        <Route path="/Promotion" component={Promotion}/>
        <Route path="/Promotion-direct-order" component={Promotion_direct_order}/>
        <Route path="/Promoting-funded-direct-order" component={Promoting_funded_direct_order}/>
        <Route path="/ContactUs" component={ContactUs}/>
        <Route path="/Blog" component={Blog}/>
        <Route path="/Thanks" component={Thanks}/>
       


{/* קישור עליון */}
{/* קישור תחתון */}


        <Route path="/ConfidentialityAgreement" component={ConfidentialityAgreement}/>
        <Route path="/InvestorRecruitment" component={InvestorRecruitment}/>
        <Route path="/Shukatar" component={Shukatar}/>
        <Route path="/Tikatar" component={Tikatar}/>
        <Route path="/text-to-html" component={TextToHtml}/>
        <Route path="/Direct_payments" component={Direct_payments}/>
        <Route path="/Outsourcing-Manager" component={Outsourcing_Manager}/>
        <Route path="/סיעור מוחות" component={Brainstorming}/>
        <Route path="/תוסף לגוגל כרום" component={Chrome_extensions}/>
        <Route path="/מחירים" component={prices_general}/>
        <Route path="/מחירי קידום ממומן" component={prices_promoting_funded}/>
        <Route path="/מחירי פיתוח" component={prices_development}/>
        <Route path="/salesperson-fee" component={salesperson_fee}/>

{/* עם קישור חלש באתר */}

<Route path="/SEOLetter" component={SEOLetter}/>
<Route path="/Terms-of-Use" component={Terms_of_Use}/>

{/* ללא קישור באתר */}

<Route path="/Drushim" component={Drushim}/>
<Route path="/ServiceOffer" component={ServiceOffer}/>
<Route path="/website-without-obligation" component={WebsiteWithoutObligation} exact/>
<Route path="/website-without-obligation/operation-terms" component={OperationTerms} />
<Route path="/Promotion-helpUs" component={Promotion_helpUs}/>

<Route path="/this-is-it" component={this_is_it}/>

<Route path="/agent-contract" component={agent_contract}/>

<Route path="/advertising" component={Advertising}/>
<Route path="/programmers-registration" component={programmers_registration}/>


<Redirect from="/acre-story" to="/Appendices/Values/acre_story" />
<Route path='/General-payment' component={() => { 
     window.location.href = 'https://meshulam.co.il/quick_payment?b=0beb776f94ec9f41ef04b3a10c2ac1b9'; 
     return null;
}}/>
<Route path='/Hours-package-payment' component={() => { 
     window.location.href = 'https://meshulam.co.il/purchase?b=f514dbc71f4922fd7a6505ed6766c202'; 
     return null;
}}/>
<Route path='/SEO-payment' component={() => { 
     window.location.href = 'https://meshulam.co.il/s/5c72b0d2-b9c6-6445-7143-69b2e43b35ab'; 
     return null;
}}/>

<Route path='/banner-payment' component={() => { 
     window.location.href = 'https://meshulam.co.il/s/27a0a123-b30b-88d8-d845-900a243cf65b'; 
     return null;
}}/>
<Route path='/development-payment' component={() => { 
     window.location.href = 'https://meshulam.co.il/quick_payment?b=59102b00d1adf1711519131a93f2ed43'; 
     return null;
}}/>
<Route path='/brainstormingt-payment' component={() => { 
     window.location.href = 'https://meshulam.co.il/quick_payment?b=aa4e464f595ab7b6b297b20dffbaa9c1'; 
     return null;
}}/>
{/* <Route path="/acre-story" component={AcreStory}/> */}

{/* מילונים */}
{/* מפתחות */}
<Route path="/Dictionaries/SEO/Keys" component={SeoDictionary}/>
<Route path="/Dictionaries/programming/Keys" component={ProgrammingDictionary}/>
<Route path="/Dictionaries/programming-en/Keys" component={ Programming_enDictionary}/>
<Route path="/Dictionaries/q&a/programming/Keys" component={ProgrammingQA}/>


{/* נספחים */}
<Route path="/Appendices/Keys" component={Appendices}/>

{/* ערכים */}
<Route path="/Dictionaries/SEO/Values/:wordKey" component={SeoGeneral}/>
<Route path="/Dictionaries/programming/Values/:wordKey" component={ProgrammingGeneral}/>
<Route path="/Dictionaries/programming-en/Values/:wordKey" component={Programming_enGeneral}/>
<Route path="/Dictionaries/q&a/programming/Values/:wordKey" component={ProgrammingQAGeneral}/>


<Route path="/Appendices/Values/:wordKey" component={AppendicesGeneral}/>

{/* קיצורי דרך */}
<Redirect from="/sc/bs" to="/סיעור מוחות" />


{/* 404 */}
         <Route component={NotFoundPage} />
      </Switch>
    </div>
  </BrowserRouter>
);
}
export default AppRouter;
