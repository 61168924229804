import React from 'react'
import Fade from 'react-reveal/Fade';
import Text from '../../../../common/src/components/Text';

const text=()=>{

    return(<div>
         
         <Fade up delay={100}>
            <h2 style={{color:"white"}}>בית תוכנה:</h2>
            <h1>מוטו ישראל</h1>
            
            <div style={{clear: "both", dir:"ltr"}}>
</div>
<br/>

          </Fade>
          <Fade up delay={200}>
            <Text dir='rtl'
              content="פתרונות מתקדמים במחשבים, פיתוח מערכות, אפליקציות לניידים ואתרים."
            />

          </Fade>
          
    </div>

    )
}
export default text