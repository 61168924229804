import React from 'react'

const Promotion =()=>{
      return (
        <div dir="ltr" style={{color:"white"}}>
<h1 >motto, Software house</h1><br />
<h1 >Programmers are needed</h1><br />
<b>Registration of a database of programmers</b><br />
About Us<br /><br />
We provide code development services for website development and applications with advanced technologies as well as website promotion and digital advertising<br />
We use a database of programmers who are all over the world, in addition to our database of programmers we work with many freelancers from all over the world<br />
Most of the projects we have carried out are public service and have been done by us and we own as start-ups and services with this we also provide development services for individuals, businesses and companies<br />
Among the many projects we have carried out:<br />
<a href="https://dugoly.com/" style={{color:"#A8DDFE"}}>dugoly</a><br />
<a href="https://blogy.click/" style={{color:"#A8DDFE"}}>blogy click</a><br />
<a href="http://openchat.click/" style={{color:"#A8DDFE"}}>openchat</a><br />
<a href="https://directdone.com/" style={{color:"#A8DDFE"}}>directDone</a><br />

& more

        </div>
      );
    }
export default Promotion